import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './NewsPage.module.scss';
const NewsPage = memo(() => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Новости</h2>
      <div className={styles.main}>
        <Outlet />
      </div>
    </div>
  );
});

export default NewsPage;
