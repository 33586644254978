import { memo, useCallback, useMemo, useState } from 'react';
import { IconSended } from '@app/icons';
import { createReview } from 'api/reviews';
import { useForm } from '@app/forms';
import { object, string } from 'yup';
import { Review } from '@app/models';
import { Button, Modal, ModalProps, TextField } from '@app/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { useNotification, useUserContext } from '@app/providers';

interface Props extends Partial<ModalProps> {
  userId?: string;
  companyId?: string;
  vehicleId?: string;
  title?: string;
  text?: string;
  getData?: () => void;
}

interface FormData {
  message: string;
}

const StyledSuccess = styled.div`
  text-align: center;
`;

const StyledSuccessText = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -1px;
  color: ${theme('color.dark')};
`;

const StyledRating = styled.div`
  margin: 0 auto 24px;
  display: flex;
  gap: 16px;
  justify-content: center;
`;

const StyledStarButton = styled.button`
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
`;

const StyledStart = styled.span`
  color: ${theme('color.primary')};
  font-size: 48px;
`;

const StyledText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${theme('color.dark')};
  margin: 0 0 24px;
`;

const StyledFooter = styled.div`
  text-align: right;
  margin-top: 24px;
`;

function ModalReview(props: Props) {
  const {
    title,
    text,
    visible,
    onClose,
    userId,
    companyId,
    vehicleId,
    getData,
  } = props;
  const { company } = useUserContext();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [succeeded, setSucceeded] = useState(false);
  const [pending, setPending] = useState(false);

  const { errors, onChange, values, validate, setErrors } = useForm<FormData>({
    message: '',
  });

  const modalTitle = useMemo(() => {
    if (succeeded) {
      return ' ';
    }

    return title || 'Оставить отзыв';
  }, [succeeded, title]);

  const onClickStar = useCallback(
    (index: number) => () => {
      setRating(index);
    },
    []
  );

  const onMouseEnter = useCallback(
    (index: number) => () => {
      setHover(index);
    },
    []
  );

  const onMouseLeave = useCallback(() => {
    setHover(rating);
  }, [rating]);

  const onSubmitForm = useCallback(async () => {
    try {
      const hasErrors = await validate(
        object().shape({
          message: string().required(t('required', { ns: 'validation' })!),
        })
      );

      if (hasErrors) {
        return;
      }

      if (rating < 1) {
        setErrors({
          message: 'Укажите рейтинг',
        });

        return;
      } else {
        setErrors({});
      }

      setPending(true);

      const data: Partial<Review> = {
        description: values.message,
        rating,
      };

      if (!!companyId) {
        data.companyId = companyId;
      }

      if (!!userId) {
        data.userId = userId;
      }

      if (!!vehicleId) {
        data.vehicleId = vehicleId;
      }

      if (!!company) {
        data.authorCompanyId = company.id;
      }

      await createReview(data);
      getData && getData();

      setSucceeded(true);
      setPending(false);
    } catch (e) {
      setSucceeded(false);
      setPending(false);

      showNotification({
        variant: 'error',
        message: 'Не удалось добавить опыт отзыв',
      });
    }
  }, [
    company,
    companyId,
    getData,
    rating,
    setErrors,
    showNotification,
    t,
    userId,
    validate,
    values.message,
    vehicleId,
  ]);

  const onCloseModal = useCallback(() => {
    setSucceeded(false);
    onChange('', 'message');
    setRating(0);

    if (!!onClose) {
      onClose();
    }
  }, [onChange, onClose]);

  if (!visible) {
    return null;
  }

  return (
    <Modal visible={visible} onClose={onCloseModal} title={modalTitle}>
      {succeeded ? (
        <StyledSuccess>
          <IconSended />
          <StyledSuccessText>Отзыв отправлен!</StyledSuccessText>
        </StyledSuccess>
      ) : (
        <>
          {!!text && <StyledText>{text}</StyledText>}
          <StyledRating>
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <StyledStarButton
                  key={index}
                  onClick={onClickStar(index)}
                  onMouseEnter={onMouseEnter(index)}
                  onMouseLeave={onMouseLeave}
                >
                  <StyledStart>
                    {index <= (hover || rating) ? '★' : '☆'}
                  </StyledStart>
                </StyledStarButton>
              );
            })}
          </StyledRating>
          <TextField
            label="комментарий"
            value={values.message}
            placeholder="Введите комментарий"
            name="message"
            onChange={onChange}
            error={!!errors.message}
            helperText={errors.message}
            editable={!pending}
          />
          <StyledFooter>
            <Button
              text="Отправить"
              onClick={onSubmitForm}
              disabled={pending}
              loading={pending}
            />
          </StyledFooter>
        </>
      )}
    </Modal>
  );
}

export default memo(ModalReview);
