import React, { memo } from 'react';
import { Info } from '@app/components';
import { Company } from '@app/models';
import { useTranslation } from 'react-i18next';

interface Props {
  company: Company;
}

function ProfileCompanyAdditionalInfo(props: Props) {
  const { company } = props;
  const { t } = useTranslation();

  return (
    <>
      <Info
        label="ОКЭД"
        value={company.oked ? company.oked.nameRu : t('notSpecified')}
      />
      <Info
        label="Дополнительная информация"
        value={company.additionalInformation || t('notSpecified')}
      />
    </>
  );
}

export default memo(ProfileCompanyAdditionalInfo);
