import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';
import { switchProp, theme } from 'styled-tools';

type LoaderSizes = 'small' | 'default';

type LoaderColors = 'default' | 'primary';

interface Props {
  size?: LoaderSizes;
  color?: LoaderColors;
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div<Props>`
  width: ${switchProp('size', {
    default: '48px',
    small: '24px',
  })};
  height: ${switchProp('size', {
    default: '48px',
    small: '24px',
  })};
  border-width: ${switchProp('size', {
    default: '5px',
    small: '2px',
  })};
  border-style: solid;
  border-color: ${switchProp('color', {
    default: theme('color.white'),
    primary: theme('color.primary'),
  })};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotate} 1s linear infinite;
`;

function Loader(props: Props) {
  const { size = 'default', color = 'primary' } = props;

  return <StyledLoader size={size} color={color} />;
}

export default memo(Loader);
