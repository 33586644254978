import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconBookmark16 } from '@app/icons';
import Button from '../../../Button';

interface Props {
  onClickFavorite: (isFavorite: boolean) => void;
  isFavorite?: boolean;
}

function CardFavoriteButton(props: Props) {
  const { onClickFavorite, isFavorite: propsIsFavorite = false } = props;
  const [isFavorite, setIsFavorite] = useState<boolean>(propsIsFavorite);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setIsFavorite(propsIsFavorite);
  }, [propsIsFavorite]);

  const onClickButton = useCallback(() => {
    setIsFavorite(!isFavorite);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      onClickFavorite(!isFavorite);
    }, 1000);
  }, [onClickFavorite, isFavorite]);

  return (
    <Button
      text=""
      icon={IconBookmark16}
      onClick={onClickButton}
      variant={isFavorite ? 'contained' : 'outlined'}
    />
  );
}

export default CardFavoriteButton;
