import React, { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Reply, Tender, TenderCategory, UserType } from '@app/models';
import { getReplies } from '../../../../api/reply';
import { useUserContext } from '@app/providers';
import { IconChevronLeft, IconEmpty } from '@app/icons';
import { SearchTemplate } from '@app/templates';
import { PageableParams } from '@app/api';
import { ModalReplyDetail } from '@app/modals';
import { Reply as ReplyComponent } from '@app/common';
import styled from 'styled-components';
import { EventEmitter } from '../../../../EventEmitter';

const StyledBackButton = styled.button`
  width: 48px;
  height: 48px;
  background: #ededed;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: #3a57e8;
  margin-right: 16px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const StyledBlock = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {}

function PageProfileReplies(props: Props) {
  const { tenderCategory } = useParams();
  const { user, company } = useUserContext();
  const [reply, setReply] = useState<Reply | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const onCloseModal = useCallback(() => {
    setReply(null);
    setModalVisible(false);
  }, []);

  const onClickReply = useCallback((item: Reply) => {
    setReply(item);
    setModalVisible(true);
  }, []);

  const onClickBack = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      navigate(-1);
    },
    [navigate]
  );

  const onChangeReply = useCallback(
    (replyId: string, isChangeReply: boolean) => {
      if (!isChangeReply) {
        EventEmitter.emit('updateRepliesSearch');
      }
    },
    []
  );

  const getData = useCallback(
    (params: PageableParams) => {
      const defaultParams: Partial<Tender> = {
        category: Number(tenderCategory),
      };

      if (user.type === UserType.INDIVIDUAL) {
        defaultParams.ownerId = user.id;
      } else {
        defaultParams.companyId = company!.id;
      }

      return getReplies({
        ...params,
        ...defaultParams,
      });
    },
    [company, tenderCategory, user]
  );

  const renderItem = useCallback(
    (item: Reply) => {
      return <ReplyComponent reply={item} onClick={onClickReply} />;
    },
    [onClickReply]
  );

  const categoryName = useMemo(() => {
    switch (Number(tenderCategory)) {
      case TenderCategory.VEHICLE:
        return 'Спецтехника';
      case TenderCategory.CONTRACTOR:
        return 'СМР';
      case TenderCategory.SUPPLIER:
        return 'Материалы';
      case TenderCategory.WORKER:
        return 'Специалисты';
      default:
        return '';
    }
  }, [tenderCategory]);

  const titleReply = useMemo(() => {
    return (
      <StyledBlock>
        <StyledBackButton onClick={onClickBack}>
          <IconChevronLeft />
        </StyledBackButton>
        Отклики ({categoryName})
      </StyledBlock>
    );
  }, [categoryName, onClickBack]);

  return (
    <>
      <SearchTemplate<Reply>
        columns={1}
        renderItem={renderItem}
        getData={getData}
        title={titleReply}
        emptyIcon={<IconEmpty />}
        viewControl={false}
        variant="paper"
        refreshEventName="updateRepliesSearch"
        emptyTitle={`Вы еще не откликались на предложения в категории «${categoryName}»`}
      />
      <ModalReplyDetail
        visible={modalVisible}
        onClose={onCloseModal}
        reply={reply}
        onChange={onChangeReply}
      />
    </>
  );
}

export default memo(PageProfileReplies);
