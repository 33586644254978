import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Vehicle } from '@app/models';
import { useParams } from 'react-router-dom';
import { Button, Loader } from '@app/components';
import { VehicleInfo } from '@app/common';
import { deleteVehicle, getVehicleById } from '@app/api';
import styled from 'styled-components';
import { ModalConfirm } from '@app/modals';
import { IconBin } from '@app/icons';

interface Props {}

const StyledLoaderCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function PageProfileVehicle(props: Props) {
  const { id } = useParams<'id'>();
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const getData = useCallback(async () => {
    try {
      const vehicleResponse = await getVehicleById(id as string);

      setVehicle(vehicleResponse);
      setError(false);
      setPending(false);
    } catch (e) {
      setError(true);
      setPending(false);
    }
  }, [id]);

  const onClickDelete = useCallback(async () => {
    try {
      if (vehicle?.id) {
        await deleteVehicle(vehicle.id);
        setIsDeleted((prevState) => !prevState);
      }
    } catch (e) {}
  }, [vehicle?.id]);

  const onClickDeleteModal = useCallback(() => {
    setDeleteModalVisible(true);
  }, []);

  const onCloseDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const actionButtons = useMemo<ReactNode[]>(() => {
    if (!vehicle) {
      return [];
    }

    const returnActionButtons = [];
    returnActionButtons.push(
      <Button
        text=""
        icon={IconBin}
        onClick={onClickDeleteModal}
        variant={isDeleted ? 'contained' : 'outlined'}
      />
    );

    return [...returnActionButtons];
  }, [isDeleted, onClickDeleteModal, vehicle]);

  if (pending) {
    return (
      <StyledLoaderCenter>
        <Loader />
      </StyledLoaderCenter>
    );
  }

  if (!vehicle || error) {
    return null;
  }

  return (
    <>
      <VehicleInfo vehicle={vehicle} actions={actionButtons} editable={false} />
      <ModalConfirm
        textSuccess={'Успешно удален!'}
        onAccept={onClickDelete}
        title={'Вы уверены, что хотите удалить?'}
        visible={deleteModalVisible}
        onClose={onCloseDeleteModal}
      />
    </>
  );
}

export default memo(PageProfileVehicle);
