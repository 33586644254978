import { Company } from './Company';
import { WithCreatedUpdated } from './Default';
import { User } from './User';
import { Tender } from './Tender';

export enum DocTypes {
  CONTRACT = 10,
  ACT = 20,
  INVOICE = 30,
  ADDITIONAL_AGREEMENT = 40,
  OTHER = 50,
}

export const DocTypeName: Record<DocTypes, string> = {
  [DocTypes.CONTRACT]: 'Контракт',
  [DocTypes.ACT]: 'Акт',
  [DocTypes.INVOICE]: 'Счет',
  [DocTypes.ADDITIONAL_AGREEMENT]: 'Доп.соглашение',
  [DocTypes.OTHER]: 'Прочее',
};

export enum DocStatus {
  DEFAULT = 10,
  OWNER_SIGNED = 20,
  RECIPIENT_SIGNED = 30,
  SIGNED = 40,
  DECLINED = 50,
  FINISHED = 60,
}

export const DocStatusName: Record<DocStatus, string> = {
  [DocStatus.DEFAULT]: 'На подписании',
  [DocStatus.OWNER_SIGNED]: 'Подписан инициатором',
  [DocStatus.RECIPIENT_SIGNED]: 'Подписан патнером',
  [DocStatus.SIGNED]: 'Подписан',
  [DocStatus.DECLINED]: 'Расторгнут',
  [DocStatus.FINISHED]: 'Завершен',
};

export interface Doc extends WithCreatedUpdated {
  id: string;
  ownerId: string;
  companyId: string | null;
  recipientId: string | null;
  recipientCompanyId: string | null;
  name: string;
  description: string;
  price: string;
  contractId: string | null;
  type: DocTypes;
  recipient: User;
  owner: User;
  recipientCompany: Company;
  company: Company;
  fileId: string;
  status: DocStatus;
  docs: Doc[];
  endDate: string;
  tenderId: string | null;
  rating: number;
  ownerSign: string | null;
  recipientSign: string | null;
  tender: Tender;
}
