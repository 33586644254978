import React, { memo, PropsWithChildren } from 'react';
import { Paper, Switch } from '@app/components';
import styled from 'styled-components';
import { theme } from 'styled-tools';

const StyledExpandablePaperHeader = styled.div`
  display: flex;
`;

const StyledExpandablePaperTitle = styled.p`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 24px 0 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${theme('color.dark')};
`;

const StyledRight = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
`;

const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  margin: 0 16px 0 0;
  color: ${theme('color.gray')};
`;

const StyledExpandableSwitch = styled.div`
  margin-left: auto;
  flex-shrink: 0;
  flex-grow: 0;
  margin-top: 2px;
`;

const StyledExpandablePaperContent = styled.p`
  padding: 16px 0 0;
  box-sizing: border-box;
`;

interface Props extends PropsWithChildren {
  onChange: (expanded: boolean) => void;
  expanded: boolean;
  title: string;
  description?: string;
}

function ExpandablePaper(props: Props) {
  const { title, expanded, onChange, description, children } = props;

  return (
    <Paper>
      <StyledExpandablePaperHeader>
        <StyledExpandablePaperTitle title={title}>
          {title.length > 35 ? title.slice(0, 35 + 1) + '...' : title}
        </StyledExpandablePaperTitle>
        <StyledRight>
          <StyledDescription>{description}</StyledDescription>
          <StyledExpandableSwitch>
            <Switch checked={expanded} onChange={onChange} />
          </StyledExpandableSwitch>
        </StyledRight>
      </StyledExpandablePaperHeader>
      {expanded && (
        <StyledExpandablePaperContent>{children}</StyledExpandablePaperContent>
      )}
    </Paper>
  );
}

export default memo(ExpandablePaper);
