import React from 'react';
import { IconCheckCircle16 } from '@app/icons';
import Button from '../../../Button';
import './styles.scss';

interface Props {
  count?: number;
}

function CardReplyCount(props: Props) {
  const { count = 0 } = props;

  return (
    <Button
      className="smr-card--reply-count-disabled"
      text={count + ''}
      disabled={true}
      icon={IconCheckCircle16}
      variant={'outlined'}
    />
  );
}

export default CardReplyCount;
