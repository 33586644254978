import { WithCreatedUpdated } from './Default';
import { Tender, TenderRequirement } from './Tender';
import { User } from './User';
import { Company } from './Company';

export enum ReplyStatus {
  NEW = 10,
  ACCEPT = 20,
  DECLINE = 30,
}

export const ReplyStatusStatusName: Record<ReplyStatus, string> = {
  [ReplyStatus.NEW]: 'Новый',
  [ReplyStatus.ACCEPT]: 'Принят',
  [ReplyStatus.DECLINE]: 'Отклонен',
};

export interface ReplyRequirement extends WithCreatedUpdated {
  id: string;
  name: string;
  comment: string;
  requirement: TenderRequirement;
  checked: boolean;
}

export interface Reply extends WithCreatedUpdated {
  id: string;
  adsId: string;
  price: string;
  description: string;
  tenderId: string | null;
  tender: Tender;
  ownerId: string;
  owner: User;
  status: ReplyStatus;
  requirements: ReplyRequirement[];
  fileIds: string;
  pinned: boolean;
  companyId: string | null;
  company: Company | null;
}
