import React, { Component, memo, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { switchProp } from 'styled-tools';
import { IconClose28 } from '@app/icons';

type ModalPaperSize = 'small' | 'medium' | 'large' | 'xlarge' | 'signin';

export interface ModalProps {
  visible?: boolean;
  className?: string;
  contentClassName?: string;
  onClose?: () => void;
  title?: string;
  size?: ModalPaperSize;
  contentStyles?: React.CSSProperties;
}

const ModalWrapper = styled.div<Pick<ModalProps, 'visible'>>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 30px;
  box-sizing: border-box;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #000;
  opacity: 0.7;
`;

const ModalHeader = styled.div`
  display: flex;
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ModalCloseButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 2px;
  box-sizing: border-box;
  margin: 0;
  display: inline-block;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const ModalPaper = styled.div<Pick<ModalProps, 'size'>>`
  position: relative;
  z-index: 1001;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: ${switchProp('size', {
    small: '360px',
    medium: '600px',
    large: '1120px',
    xlarge: 'calc(100% - 48px)',
    signin: '360px',
  })};
`;

const ModalContent = styled.div<Pick<ModalProps, 'size'>>`
  padding: ${(props) => (props.size === 'signin' ? '32px' : '16px 16px 32px')};
  box-sizing: border-box;
  background: #f5f5f5;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
`;

const ModalTitle = styled.p`
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.5px;
  color: #0a0a0a;
  margin: 0 16px 0 0;
`;

const modalRoot = document.getElementById('modal-root') as HTMLElement;

class Modal extends Component<PropsWithChildren<ModalProps>> {
  el = document.createElement('div');

  componentDidMount() {
    modalRoot?.appendChild(this.el);

    document.body.style.overflowY = 'hidden';
  }

  componentWillReceiveProps(
    nextProps: Readonly<React.PropsWithChildren<ModalProps>>
  ) {
    if (nextProps.visible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'initial';
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'initial';
    modalRoot?.removeChild(this.el);
  }

  onClickBackground = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onClose } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!!onClose) {
      onClose();
    }
  };

  onClickClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { onClose } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!!onClose) {
      onClose();
    }
  };

  render() {
    const {
      visible = false,
      size = 'medium',
      className = '',
      contentClassName = '',
      title,
      children,
      contentStyles,
    } = this.props;

    return createPortal(
      <ModalWrapper visible={visible} className={className}>
        <ModalPaper size={size}>
          {!!title && (
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              <ModalCloseButton onClick={this.onClickClose}>
                <IconClose28 />
              </ModalCloseButton>
            </ModalHeader>
          )}
          <ModalContent
            className={contentClassName}
            size={size}
            style={contentStyles}
          >
            {children}
          </ModalContent>
        </ModalPaper>
        <ModalBackground onClick={this.onClickBackground} />
      </ModalWrapper>,
      this.el
    );
  }
}

export default memo(Modal);
