import { Invite } from '@app/models';
import instance from './instance';

export function postInvite(data: Invite): Promise<Invite> {
  return instance()
    .post('/invites/new', data)
    .then((response) => response.data);
}

export function getInviteDto(inviteId: string): Promise<Invite> {
  return instance()
    .get(`/invites/getByInviteId/${inviteId}`)
    .then((response) => response.data);
}
