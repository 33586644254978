import useToggle from 'pages/Privacy/hooks/useToggle';
import { useMemo } from 'react';
import styled from 'styled-components';
import Item from '../Item';

const StylesItemContainer = styled.article`
  margin: 0 auto;
`;
interface ISubtitleLists {
  subtitle: string;
  text: string;
  paragraph: {
    subtitle: string;
    items: string[];
  };
}

export interface IPrivacy {
  title: string;
  subtitleLists: ISubtitleLists[];
  opened: boolean;
}

const ListItems = () => {
  const privacy = useMemo(() => {
    return [
      {
        title: 'Термины и определения',
        subtitleLists: [
          {
            subtitle: 'Персональные ',
            text: ' - любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных);',
          },
          {
            subtitle: 'Оператор ',
            text: ' - Товарищество с ограниченной ответственностью «Бугор.қаз», самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных, а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;',
          },
          {
            subtitle: 'Обработка персональных данных',
            text: ' - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;',
          },
          {
            subtitle: 'Автоматизированная обработка персональных данных',
            text: ' - обработка персональных данных с помощью средств вычислительной техники;',
          },
          {
            subtitle: 'Распространение персональных данных',
            text: ' - действия, направленные на раскрытие персональных данных неопределенному кругу лиц;',
          },
          {
            subtitle: 'Предоставление персональных данных',
            text: ' - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;',
          },
          {
            subtitle: 'Блокирование персональных данных ',
            text: ' - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);',
          },
          {
            subtitle: 'Уничтожение персональных данных',
            text: ' - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;',
          },
          {
            subtitle: 'Обезличивание персональных данных',
            text: ' - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;',
          },
          {
            subtitle: 'Информационная система персональных данных',
            text: ' - совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств;',
          },
          {
            subtitle: 'Трансграничная передача персональных данных',
            text: ' - передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.',
          },
        ],
        opened: false,
      },
      {
        title: 'Информационная система персональных данных',
        subtitleLists: [
          {
            subtitle: '2.1.',
            text: 'Обработка персональных данных должна осуществляться на законной и справедливой основе.',
          },
          {
            subtitle: '2.2.',
            text: 'Обработка персональных данных должна ограничиваться достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.',
          },
          {
            subtitle: '2.3.',
            text: 'Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.',
          },
          {
            subtitle: '2.4.',
            text: 'Обработке подлежат только персональные данные, которые отвечают целям их обработки.',
          },
          {
            subtitle: '2.5.',
            text: 'Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки.',
          },
          {
            subtitle: '2.6.',
            text: 'При обработке персональных данных должны быть обеспечены точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор должен принимать необходимые меры либо обеспечивать их принятие по удалению или уточнению неполных, или неточных данных.',
          },
          {
            subtitle: '2.7.',
            text: ' Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен законодательством, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено законодательством.',
          },
        ],
        opened: false,
      },
      {
        title: 'Принципы обработки персональных данных',
        subtitleLists: [
          {
            subtitle: '2.1.',
            text: 'Обработка персональных данных должна осуществляться на законной и справедливой основе.',
          },
          {
            subtitle: '2.2.',
            text: 'Обработка персональных данных должна ограничиваться достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.',
          },
          {
            subtitle: '2.3.',
            text: 'Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.',
          },
          {
            subtitle: '2.4.',
            text: 'Обработке подлежат только персональные данные, которые отвечают целям их обработки.',
          },
          {
            subtitle: '2.5.',
            text: 'Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки.',
          },
          {
            subtitle: '2.6.',
            text: 'При обработке персональных данных должны быть обеспечены точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор должен принимать необходимые меры либо обеспечивать их принятие по удалению или уточнению неполных, или неточных данных.',
          },
          {
            subtitle: '2.7.',
            text: ' Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен законодательством, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено законодательством.',
          },
        ],
        opened: false,
      },
      {
        title: 'Условия обработки персональных данных',
        subtitleLists: [
          {
            subtitle: '3.1.',
            text: 'Обработка персональных данных должна осуществляться с соблюдением принципов и правил, предусмотренных Законодательством Республики Казахстан.',
          },
          {
            subtitle: '3.2.',
            text: 'Обработка персональных данных допускается в следующих случаях:',
          },
          {
            subtitle: '3.2.1.',
            text: ' Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;',
          },
          {
            subtitle: '3.2.2.',
            text: ' Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Республики Казахстан или законом, для осуществления и выполнения возложенных законодательством Республики Казахстан на Оператора функций, полномочий и обязанностей;',
          },
          {
            subtitle: '3.2.3.',
            text: ' Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Республики Казахстан об исполнительном производстве;',
          },
          {
            subtitle: '3.2.4.',
            text: ' Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем, по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;',
          },
          {
            subtitle: '3.2.5.',
            text: 'Обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно;',
          },
          {
            subtitle: '3.2.6.',
            text: 'Обработка персональных данных необходима для осуществления прав и законных интересов Оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;',
          },
          {
            subtitle: '3.2.7.',
            text: 'Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе;',
          },
          {
            subtitle: '3.2.8.',
            text: 'Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с законодательством Республики Казахстан.',
          },
          {
            subtitle: '3.3.',
            text: 'Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено законодательством, на основании заключаемого с этим лицом договора, в том числе государственного или муниципального контракта, либо путем принятия государственным или муниципальным органом соответствующего акта. Лицо, осуществляющее обработку персональных данных по поручению Оператора, обязано соблюдать принципы и правила обработки персональных данных, предусмотренные Законом Республики Казахстан «О персональных данных и их защите».',
          },
        ],
        opened: false,
      },
      {
        title:
          'Меры по надлежащей организации обработки и обеспечению безопасности персональных данных',
        subtitleLists: [
          {
            subtitle: '4.1.',
            text: ' Обеспечение безопасности персональных данных Оператором достигается, в частности, следующими способами:',
          },
          {
            subtitle: '4.1.1',
            text: ' Назначением ответственного лица за организацию обработки персональных данных, права и обязанности которого определяются локальными актами Оператора;',
          },
          {
            subtitle: '4.1.2.',
            text: ' Осуществлением внутреннего контроля и/или аудита соответствия обработки персональных данных Закон Республики Казахстан от 21.05.2013 №94-V «О персональных данных и их защите» и принятыми в соответствии с ним нормативными правовыми актами, требованиями к защите персональных данных, локальными актами Оператора;',
          },
          {
            subtitle: '4.1.3.',
            text: ' Ознакомлением сотрудников Оператора, непосредственно осуществляющих обработку персональных данных, с положениями законодательства Республики Казахстан о персональных данных, в том числе с требованиями к защите персональных данных, локальными актами в отношении обработки персональных данных и/или обучением указанных сотрудников;',
          },
          {
            subtitle: '4.1.4.',
            text: ' Определением угроз безопасности персональных данных при их обработке в информационных системах персональных данных;',
          },
          {
            subtitle: '4.1.5.',
            text: ' Применением организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных, необходимых для выполнения требований к защите персональных данных;',
          },
          {
            subtitle: '4.1.6.',
            text: ' Оценкой эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы персональных данных;',
          },
          {
            subtitle: '4.1.7.',
            text: ' Учетом машинных (материальных) носителей персональных данных;',
          },
          {
            subtitle: '4.1.8',
            text: ' Выявлением фактов несанкционированного доступа к персональным данным и принятием соответствующих мер; восстановлением персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;',
          },
          {
            subtitle: '4.1.9.',
            text: ' Установлением правил доступа к персональным данным, обрабатываемым в информационной системе персональных данных, а также обеспечением регистрации и учета всех действий, совершаемых с персональными данными в информационной системе персональных данных;',
          },
          {
            subtitle: '4.1.10.',
            text: ' Контролем над соблюдением требований в сфере обеспечения безопасности персональных данных и к уровням защищенности информационных систем персональных данных.',
          },
          {
            subtitle: '4.2',
            text: ' Обязанности сотрудников Оператора, непосредственно осуществляющих обработку персональных данных, а также их ответственность определяются в локальных актах Оператора. Сотрудники Оператора, виновные в нарушении норм, регулирующих обработку и защиту персональных данных, несут материальную, дисциплинарную, административную, гражданско-правовую или уголовную ответственность в порядке, законодательством.',
          },
        ],

        opened: false,
      },
      {
        title: 'Ограничения на действие настоящего Положения',
        subtitleLists: [
          {
            subtitle: '5.1.',
            text: 'Обработке персональных данных физическими лицами исключительно для личных и семейных нужд, если при этом не нарушаются права субъектов персональных данных;',
          },
          {
            subtitle: '5.2.',
            text: ' Обработке персональных данных, отнесенных в установленном порядке к сведениям, составляющим государственную тайну;',
          },
        ],
        opened: false,
      },
      {
        title: 'Нормативно-правовые акты',
        subtitleLists: [
          {
            subtitle: '',
            text: '',
            paragraph: {
              subtitle:
                'Настоящее Положение разработано в соответствии с положениями следующих нормативных правовых актов:',
              items: [
                'Кодекс Республики Казахстан об административных правонарушениях от 05.07.2014 № 235-V;',
                'Закон Республики Казахстан от 24.11.2015 № 418-V «Об информатизации»;',
                'Закон Республики Казахстан от 21.05.2013 № 94-V «О персональных данных и их защите»;',
              ],
            },
          },
        ],
        opened: false,
      },
      {
        title: 'Заключительные положения',
        subtitleLists: [
          {
            subtitle: '7.1.',
            text: ' Настоящее Положение утверждается единоличным исполнительным органом Оператора.',
          },
          {
            subtitle: '7.2.',
            text: ' Оператор имеет право вносить изменения в настоящее Положение.',
          },
          {
            subtitle: '7.3.',
            text: ' При внесении изменений в заголовке Положения указывается дата последнего обновления редакции. Новая редакция Положения вступает в силу с момента его утверждения и размещения на сайте Оператора https://smetmen.kz/, если иное не предусмотрено новой редакцией Положения.',
          },
          {
            subtitle: '7.4.',
            text: ' Настоящее Положение обязательно для соблюдения и ознакомления всех сотрудников Оператора.',
          },
          {
            subtitle: '7.5.',
            text: ' Иные права и обязанности ТОО «Байжан KZ», как оператора персональных данных, определяются законодательством Республики Казахстан в области персональных данных.',
          },
        ],
        opened: false,
      },
    ];
  }, []);

  const { newArray, onToggleHandler } = useToggle<IPrivacy>(
    privacy as IPrivacy[]
  );

  return (
    <StylesItemContainer>
      {newArray.map((privacyDate: IPrivacy, i: number) => (
        <Item
          privacyDate={privacyDate}
          index={i}
          key={i}
          opened={privacyDate.opened}
          onToggleHandler={onToggleHandler}
        />
      ))}
    </StylesItemContainer>
  );
};

export default ListItems;
