import React, { memo } from 'react';
import { Info } from '@app/components';
import { useTranslation } from 'react-i18next';
import { Company } from '@app/models';
import { fullName } from '@app/helpers';

interface Props {
  company: Company;
}

function ProfileCompanyInfo(props: Props) {
  const { company } = props;
  const { t } = useTranslation();

  return (
    <>
      <Info label="Наименование организации" value={company.name} />
      <Info label="БИН партнера" value={company.bin} />
      <Info label="Директор" value={fullName(company.owner)} />
      <Info
        label="Регион"
        value={company.kato ? company.kato.nameRu : t('notSpecified')}
      />
      <Info
        label="Юридический адрес"
        value={company.legalAddress || t('notSpecified')}
      />
      <Info
        label="Фактический адрес"
        value={company.address || t('notSpecified')}
      />
    </>
  );
}

export default memo(ProfileCompanyInfo);
