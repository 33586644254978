import React, { memo, useCallback, useState } from 'react';
import { Doc } from '@app/models';
import { IconEmpty } from '@app/icons';
import { SearchTemplate } from '@app/templates';
import { PageableParams, PageableResponse } from '@app/api';
import DocComponent from '../Doc';
import { ModalContract } from '@app/modals';
import { EventEmitter } from '../../EventEmitter';

interface Props {
  getData: (params: PageableParams) => Promise<PageableResponse<Doc>>;
  refreshEventName?: string;
}

function DocsSearch(props: Props) {
  const { getData, refreshEventName } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [contract, setContract] = useState<Doc | null>(null);

  const onCloseModal = useCallback(() => {
    setContract(null);
    setModalVisible(false);
  }, []);

  const onClickDoc = useCallback((item: Doc) => {
    setContract(item);
    setModalVisible(true);
  }, []);

  const updateContract = useCallback(
    (data: Partial<Doc>) => {
      setContract((prevContract) => ({
        ...(prevContract as Doc),
        ...(data as Doc),
      }));

      EventEmitter.emit(refreshEventName as string);
    },
    [refreshEventName]
  );

  const renderItem = useCallback(
    (item: Doc) => {
      return <DocComponent doc={item} onClick={onClickDoc} />;
    },
    [onClickDoc]
  );

  return (
    <>
      <SearchTemplate<Doc>
        columns={1}
        renderItem={renderItem}
        getData={getData}
        emptyIcon={<IconEmpty />}
        viewControl={false}
        refreshEventName={refreshEventName}
      />
      <ModalContract
        visible={modalVisible}
        contract={contract}
        onClose={onCloseModal}
        updateContract={updateContract}
      />
    </>
  );
}

export default memo(DocsSearch);
