import { WithCreatedUpdated } from './Default';
import { Reply } from './Reply';
import { User } from './User';
import { Oked } from './Oked';
import { Kato } from './Kato';
import { Company } from './Company';

export enum TenderCategory {
  CONTRACTOR = 10,
  SUPPLIER = 20,
  VEHICLE = 30,
  WORKER = 40,
}

export const TenderCategoryNames: Record<TenderCategory, string> = {
  [TenderCategory.CONTRACTOR]: 'СМР',
  [TenderCategory.SUPPLIER]: 'Материалы',
  [TenderCategory.VEHICLE]: 'Спецтехника',
  [TenderCategory.WORKER]: 'Специалист',
};

export const TenderCategorySlug: Record<TenderCategory, string> = {
  [TenderCategory.CONTRACTOR]: 'contractor',
  [TenderCategory.SUPPLIER]: 'supplier',
  [TenderCategory.VEHICLE]: 'vehicle',
  [TenderCategory.WORKER]: 'specialist',
};

export enum TenderTypes {
  OFFER = 10,
  REQUEST = 20,
}

export const TenderTypeNames: Record<string, string> = {
  [TenderTypes.REQUEST]: 'Заявка',
  [TenderTypes.OFFER]: 'Предложение',
};

export enum TenderStatus {
  NEW = 10,
  HAS_CONTRACT = 20,
  FINISHED = 30,
  FINISHED_SUCCESS = 31,
  FINISHED_FAIL = 32,
  DISABLED = 40,
  CLOSED = 50,
  ACTIVE = 70,
}

export const TenderStatusNames: Record<TenderStatus, string> = {
  [TenderStatus.NEW]: 'Новое',
  [TenderStatus.ACTIVE]: 'Активно',
  [TenderStatus.HAS_CONTRACT]: 'Есть договор',
  [TenderStatus.FINISHED]: 'Завершено',
  [TenderStatus.FINISHED_SUCCESS]: 'Завершено успешно',
  [TenderStatus.FINISHED_FAIL]: 'Завершено безуспешно',
  [TenderStatus.DISABLED]: 'Не доступно',
  [TenderStatus.CLOSED]: 'Закрыто',
};

export enum TenderAdStatus {
  ACTIVE = 10,
  INACTIVE = 20,
}

export const TenderAdStatusNames: Record<TenderAdStatus, string> = {
  [TenderAdStatus.ACTIVE]: 'Активная',
  [TenderAdStatus.INACTIVE]: 'Неактивная',
};

export interface TenderRequirement {
  id: string;
  name: string;
}

export interface Tender extends WithCreatedUpdated {
  id: string;
  number?: string;
  name: string;
  description: string;
  price: string;
  okedCode: string;
  oked: Oked;
  katoCode: string;
  kato: Kato;
  ownerId: string;
  owner: User;
  rating: number;
  imagesIds: string | null;
  filesIds: string | null;
  type: TenderTypes;
  status: TenderStatus;
  category: TenderCategory;
  replyId: string | null;
  reply?: Reply;
  companyId: string | null;
  company?: Company;
  replies: Reply[];
  requirements: TenderRequirement[];
  deliveryDate: string;
  endDate: string;
  isFavorite?: boolean;
  active: boolean;
  contractId: string | null;
}
