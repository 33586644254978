import React, { memo, MouseEventHandler, useState } from 'react';
import './styles.scss';
import { Company, User, UserType } from '@app/models';
import { Button } from '@app/components';
import { useUserContext } from '@app/providers';
import { IconEmail16, IconPhone16, IconWhatsApp16 } from '@app/icons';
import { ModalProfile } from '@app/modals';
import { EntityUserInfo, IndividualUserInfo } from './components';
import { ProfileAvatar } from '../../pages/Profile/components';

interface Props {
  author: User;
  company?: Company | null;
  className?: string;
  showContacts?: boolean;
}

function AuthorSection(props: Props) {
  const { author, company, className = '', showContacts = true } = props;
  const { user } = useUserContext();
  const [showModal, setShowModal] = useState<boolean>(false);
  const onShowAboutUser: MouseEventHandler<HTMLButtonElement> = () => {
    setShowModal(true);
  };
  return (
    <section className={`section-author ${className}`.trim()}>
      <div className="section-author__panel">
        <div className="section-author__grid">
          <div className="section-author__thumb">
            {company && author.type === UserType.ENTITY ? (
              <ProfileAvatar
                size={100}
                avatarFiles={
                  !!company.avatarFilesIds
                    ? company.avatarFilesIds.split(',')[0]
                    : ''
                }
              />
            ) : (
              <ProfileAvatar
                size={100}
                avatarFiles={
                  !!author.avatarFilesIds
                    ? author.avatarFilesIds.split(',')[0]
                    : ''
                }
              />
            )}
            <ModalProfile
              user={author}
              company={company}
              onClose={() => setShowModal(false)}
              visible={showModal}
              title={`${author.firstName} ${author.lastName} ${author.middleName}`}
            />
            {/*<img src="" alt="" className="section-author__img" />*/}
          </div>
          {company && author.type === UserType.ENTITY ? (
            <EntityUserInfo author={author} company={company} />
          ) : (
            <IndividualUserInfo author={author} />
          )}
        </div>
        {showContacts && user.authenticated && (
          <div className="section-author__footer">
            <Button onClick={onShowAboutUser} text={'Подробнее'} />
            <a
              className="section-author__footer-whatsapp"
              href={`mailto:+${author.email}`}
            >
              <IconEmail16 />
            </a>
            {!!user.whatsAppNumber && (
              <a
                className="section-author__footer-whatsapp"
                href={`https://api.whatsapp.com/send?phone=${user.whatsAppNumber}`}
              >
                <IconWhatsApp16 />
              </a>
            )}
            <a
              className="section-author__footer-whatsapp"
              href={`tel:+${author.phone}`}
            >
              <IconPhone16 />
            </a>
          </div>
        )}
      </div>
    </section>
  );
}

export default memo(AuthorSection);
