import React, { memo, useCallback, useEffect, useState } from 'react';
import { useUserContext } from '@app/providers';
import { ProfileUser } from '@app/common';
import { Loader } from '@app/components';
import { getUserById } from '@app/api';
import { User } from '@app/models';

interface Props {}

function PageProfileUser(props: Props) {
  const { user: propsUser, updateUser } = useUserContext();
  const [pending, setPending] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const onUpdateUser = useCallback(
    (updatedData: Partial<User>) => {
      updateUser(updatedData);

      setUser((prevUser) => ({
        ...(prevUser ? prevUser : {}),
        ...(updatedData as User),
      }));
    },
    [updateUser]
  );

  const getData = useCallback(async () => {
    try {
      setPending(true);

      const response = await getUserById(propsUser.id);

      setUser(response);
      updateUser(response);
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [propsUser.id, updateUser]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (pending) {
    return <Loader />;
  }

  if (!user) {
    return null;
  }

  return <ProfileUser user={user} updateUser={onUpdateUser} editable={true} />;
}

export default memo(PageProfileUser);
