import React, { memo } from 'react';
import { LoadableImage } from '@app/components';
import placeholderImage from './placeholder.png';
// import './styles.scss';
import styled from 'styled-components';

const StyledThumb = styled.div`
  width: 100%;
  height: 100%;
`;

interface Props {
  images: string[];
  placeholder?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function CardThumb(props: Props) {
  const { images, placeholder, onClick = () => {} } = props;

  return (
    <StyledThumb onClick={onClick} className="smr-card__thumb">
      <LoadableImage
        className="smr-card__image"
        imageIds={images}
        placeholder={placeholder ?? placeholderImage}
      />
    </StyledThumb>
  );
}

export default memo(CardThumb);
