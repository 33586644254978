import { Doc } from '../models';
import instance from './instance';
import { PageableParams } from './params';
import { PageableResponse } from './responses';

export interface CreateDocData
  extends Omit<
    Doc,
    | 'id'
    | 'createdAt'
    | 'recipient'
    | 'updatedAt'
    | 'owner'
    | 'filesIds'
    | 'status'
  > {}

export function createDoc(data: Partial<Doc>, files: File[]): Promise<Doc> {
  const formData = new FormData();

  Object.keys(data).forEach((dataKey) => {
    formData.append(
      dataKey,
      data[dataKey as keyof CreateDocData] as string | Blob
    );
  });

  files.forEach((uploadFile) => {
    formData.append('file', uploadFile);
  });

  return instance()
    .post('/docs/create', formData, {
      headers: { 'Content-Type': 'multipart/form-data;' },
    })
    .then((response) => response.data);
}

export function getDocs(
  params: Partial<Doc> & PageableParams
): Promise<PageableResponse<Doc>> {
  return instance()
    .get('/docs/getAll', {
      params,
    })
    .then((response) => response.data);
}

export function getContractDocs(contractId: string): Promise<Doc[]> {
  return instance()
    .get(`/docs/getContractDocs/${contractId}`)
    .then((response) => response.data);
}

export function getPartners(
  params: Partial<Doc> & PageableParams
): Promise<PageableResponse<Doc>> {
  return instance()
    .get('/docs/getPartners', {
      params,
    })
    .then((response) => response.data);
}

export function getDocById(docId: string): Promise<Doc> {
  return instance()
    .get(`/docs/getById/${docId}`)
    .then((response) => response.data);
}

export function updateDoc(doc: Partial<Doc>): Promise<Doc> {
  return instance()
    .post(`/docs/update`, doc)
    .then((response) => response.data);
}

export function getAllContracts(params: Partial<Doc>): Promise<Doc[]> {
  return instance()
    .get('/docs/getAllContracts', {
      params,
    })
    .then((response) => response.data);
}

export function getContracts(
  params: PageableParams & Partial<Doc>
): Promise<PageableResponse<Doc>> {
  return instance()
    .get('/docs/getContracts', {
      params,
    })
    .then((response) => response.data);
}
