import React, { Fragment, memo, ReactNode } from 'react';
import styled from 'styled-components';
import { IconChevronLeft } from '@app/icons';

export interface Props {
  onClickBack?: () => void;
  rightControls?: ReactNode[];
  title: string;
}

const StyledToolbar = styled.div`
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  height: 72px;
`;

const StyledBackButton = styled.button`
  width: 48px;
  height: 48px;
  background: #ededed;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: #3a57e8;
  margin-right: 16px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const StyledTitle = styled.p`
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.5px;
  color: #0a0a0a;
  margin: 0 16px 0 0;
`;

const StyledControls = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

function Toolbar(props: Props) {
  const { title, onClickBack, rightControls } = props;

  return (
    <StyledToolbar>
      {!!onClickBack && (
        <StyledBackButton onClick={onClickBack}>
          <IconChevronLeft />
        </StyledBackButton>
      )}
      <StyledTitle>{title}</StyledTitle>
      {!!rightControls && (
        <StyledControls>
          {rightControls.map((rightControl, i) => (
            <Fragment key={i}>{rightControl}</Fragment>
          ))}
        </StyledControls>
      )}
    </StyledToolbar>
  );
}

export default memo(Toolbar);
