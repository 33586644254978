import React, { Fragment, memo, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

interface Props extends PropsWithChildren {
  title?: string;
  shadow?: boolean;
  controls?: ReactNode[];
  dense?: boolean;
}

export const StyledPaper = styled.div<Pick<Props, 'shadow' | 'dense'>>`
  background: ${theme('color.white')};
  border-radius: 8px;
  padding: ${(props) => (props.dense ? '0' : '24px')};
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.shadow ? '0 4px 16px rgba(0,0,0,.12)' : 'none'};
`;

const StyledPaperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  + * {
    margin-top: 16px;
  }
`;

const TitleStyled = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${theme('color.dark')};
  margin: 0;
`;

const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

function Paper(props: Props) {
  const { title, shadow = false, controls, dense = false, children } = props;

  return (
    <StyledPaper shadow={shadow} dense={dense}>
      {!!title || !!controls ? (
        <StyledPaperHeader>
          {!!title && <TitleStyled>{title}</TitleStyled>}
          {!!controls && (
            <StyledControls>
              {controls.map((control, controlIndex) => (
                <Fragment key={controlIndex.toString()}>{control}</Fragment>
              ))}
            </StyledControls>
          )}
        </StyledPaperHeader>
      ) : null}
      {children}
    </StyledPaper>
  );
}

export default memo(Paper);
