import React, { memo, useCallback } from 'react';
import { Button } from '@app/components';
import { getFileById, getFileInfo } from '@app/api';

interface Props {
  text: string;
  fileId: string | null;
  variant?: 'contained' | 'outlined' | 'text';
}

function DownloadButton(props: Props) {
  const { text, fileId, variant } = props;

  const onClick = useCallback(async () => {
    if (!fileId) {
      return;
    }

    const fileInfo = await getFileInfo(fileId);

    const downloadLink = document.createElement('a');

    const fileData = await getFileById(fileInfo.id);
    const blobFile = new Blob([fileData], { type: 'application/octet-stream' });

    downloadLink.href = URL.createObjectURL(blobFile);
    downloadLink.setAttribute('download', fileInfo.fileName);

    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  }, [fileId]);

  return <Button text={text} onClick={onClick} variant={variant} />;
}

export default memo(DownloadButton);
