import React, { memo, PropsWithChildren } from 'react';
import { Header } from '@app/components';

interface LayoutCabinetProps {
  className?: string;
}

function LayoutCabinet(props: PropsWithChildren<LayoutCabinetProps>) {
  const { className = '', children } = props;

  return (
    <div className={`b-wrapper ${className}`.trim()}>
      <Header hideNav={true} wide={true} />
      <div className={`b-content ${className}`.trim()}>{children}</div>
    </div>
  );
}

export default memo(LayoutCabinet);
