import React, { memo, useCallback } from 'react';
import { Favorite } from '@app/models';
import { SearchTemplate } from '@app/templates';
import { getFavorites, PageableParams } from '@app/api';
import { TenderCard, VehicleCard } from '@app/common';
import { useTranslation } from 'react-i18next';
import { EventEmitter } from '../../EventEmitter';

interface Props {
  params?: Partial<Favorite>;
  horizontal?: boolean;
}

function FavoriteSearch(props: Props) {
  const { params: defaultParams, horizontal: propsHorizontal } = props;
  const { t } = useTranslation('favorite');

  const onChangeFavorite = useCallback(
    (tenderId: string, isFavorite: boolean) => {
      if (!isFavorite) {
        EventEmitter.emit('updateFavoritesSearch');
      }
    },
    []
  );

  const renderItem = useCallback(
    (favorite: Favorite, horizontal?: boolean) => {
      const { tenderId, vehicleId, tender, vehicle } = favorite;

      if (!!tenderId) {
        return (
          <TenderCard
            tender={{ ...tender, isFavorite: true }}
            horizontal={horizontal}
            onChangeFavorite={onChangeFavorite}
          />
        );
      }

      if (!!vehicleId) {
        return (
          <VehicleCard
            vehicle={{ ...vehicle, isFavorite: true }}
            horizontal={horizontal}
            onChangeFavorite={onChangeFavorite}
          />
        );
      }

      return null;
    },
    [onChangeFavorite]
  );

  const getData = useCallback(
    (params: PageableParams) => {
      return getFavorites({
        ...params,
        ...defaultParams,
      });
    },
    [defaultParams]
  );

  return (
    <SearchTemplate<Favorite>
      renderItem={renderItem}
      getData={getData}
      horizontal={propsHorizontal}
      variant={'default'}
      emptyTitle={t('emptyTitle', { ns: 'favorite' }) ?? ''}
      refreshEventName="updateFavoritesSearch"
      columns={4}
    />
  );
}

export default memo(FavoriteSearch);
