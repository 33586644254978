import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNotification, useUserContext } from '@app/providers';
import {
  Button,
  OkedSelectTrigger,
  Paper,
  SelectOption,
  Tabs,
  TextField,
} from '@app/components';
import { ProfileAvatar } from '../../components';
import { IconCheckCircle16 } from '@app/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Company, Oked } from '@app/models';
import * as yup from 'yup';
import {
  getCompanyByBin,
  updateCompany,
  uploadCompanyAvatarFiles,
} from '@app/api';
import { CompanyInfoData, FormCompanyInfo, useForm } from '@app/forms';
import { LayoutProfile } from '@app/layouts';
import { useTranslation } from 'react-i18next';

interface Props {}

interface CompanyData extends CompanyInfoData {
  oked: Oked | null;
  additionalInformation: string;
}

const StyledUserEdit = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledContent = styled(Paper)`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 380px 1fr;

  > div {
    display: grid;
    grid-gap: 16px;
  }
`;

const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;

  > * + * {
    margin-left: 16px;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
`;

const StyledInfoGrid = styled.div`
  display: grid;
  grid-gap: 16px;
`;

// const schema = yup.object().shape({
//   firstName: yup.string().required(),
//   lastName: yup.string().required(),
//   gender: yup.object().nullable().required(),
// });

function PageProfileCompanyEdit(props: Props) {
  const { bin } = useParams();
  const { setCompany: setCompanyStore } = useUserContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(false);
  const [company, setCompany] = useState<Company | null>(null);

  const { onChange, values, errors, validate, setValues } =
    useForm<CompanyData>({
      legalAddress: '',
      address: '',
      phones: [],
      instagram: '',
      telegram: '',
      webSite: '',
      whatsAppNumber: '',
      additionalInformation: '',
      kato: null,
      oked: null,
      email: '',
      filesIds: null,
      avatarFilesIds: null,
    });

  const getData = useCallback(async () => {
    try {
      const response = await getCompanyByBin(bin as string);

      setValues({
        legalAddress: response.legalAddress || '',
        address: response.address || '',
        phones: !!response.phones ? JSON.parse(response.phones) : [],
        instagram: response.instagram || '',
        telegram: response.telegram || '',
        webSite: response.webSite || '',
        whatsAppNumber: response.whatsAppNumber || '',
        additionalInformation: response.additionalInformation || '',
        kato: response.kato || null,
        oked: response.oked || null,
        email: response.email || '',
        filesIds: response.filesIds || null,
        avatarFilesIds: response.avatarFilesIds || null,
      });
      setCompany(response);
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [bin, setValues]);

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onChangeOked = useCallback(
    (data: SelectOption<Oked>) => {
      onChange(data.value, 'oked');
    },
    [onChange]
  );

  const onChangeAvatar = useCallback(
    async (files: File) => {
      try {
        const response = await uploadCompanyAvatarFiles(company!.id, [files]);
        setValues({
          filesIds: response.filesIds,
          avatarFilesIds: response.avatarFilesIds,
        });
      } catch (e) {}
    },
    [company, setValues]
  );

  const onClickSave = useCallback(async () => {
    try {
      const hasErrors = await validate(
        yup.object().shape({
          legalAddress: yup
            .string()
            .required(t('required', { ns: 'validation' })!),
          address: yup.string().required(t('required', { ns: 'validation' })!),
          kato: yup
            .object()
            .nullable()
            .required(t('required', { ns: 'validation' })!),
          oked: yup
            .object()
            .nullable()
            .required(t('required', { ns: 'validation' })!),
          email: yup.string().required(t('required', { ns: 'validation' })!),
        })
      );

      if (hasErrors) {
        return;
      }

      setPending(true);

      const toUpdateData: Partial<Company> = {
        legalAddress: values.legalAddress,
        address: values.address,
        katoCode: values.kato!.code,
        okedCode: values.oked!.code,
        email: values.email,
      };

      if (!!values.instagram) {
        toUpdateData.instagram = values.instagram;
      }

      if (!!values.telegram) {
        toUpdateData.telegram = values.telegram;
      }

      if (!!values.webSite) {
        toUpdateData.webSite = values.webSite;
      }

      if (!!values.whatsAppNumber) {
        toUpdateData.whatsAppNumber = values.whatsAppNumber;
      }

      if (!!values.additionalInformation) {
        toUpdateData.additionalInformation = values.additionalInformation;
      }

      if (values.phones.length > 0) {
        toUpdateData.phones = JSON.stringify(values.phones);
      }

      const response = await updateCompany(company!.id, toUpdateData);

      setCompanyStore({
        ...company,
        ...response,
      });

      showNotification({
        variant: 'success',
        message: 'Данные компании успешно обновлены',
      });

      setPending(false);

      navigate(`/profile/company/${company!.bin}`);
    } catch (e) {
      showNotification({
        variant: 'error',
        message: 'Не удалось обновить данные компании',
      });

      setPending(false);
    }
  }, [
    company,
    navigate,
    setCompanyStore,
    showNotification,
    validate,
    values,
    t,
  ]);

  const leftContent = useMemo(
    () => (
      <ProfileAvatar
        avatarFiles={
          values?.avatarFilesIds ? values.avatarFilesIds.split(',')[0] : ''
        }
        onSubmit={onChangeAvatar}
        editable={true}
      />
    ),
    [onChangeAvatar, values.avatarFilesIds]
  );

  const rightContent = useMemo(() => {
    if (!company) {
      return null;
    }

    return (
      <FormCompanyInfo
        company={company}
        values={values}
        onChange={onChange}
        errors={errors}
      />
    );
  }, [company, errors, onChange, values]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <StyledUserEdit>
      <LayoutProfile
        pending={pending}
        toolbarProps={{
          title: 'Данные организации',
        }}
        leftContent={leftContent}
        rightContent={rightContent}
      >
        <Paper>
          <Tabs onChange={() => {}} labels={['Профессиональные данные']}>
            <StyledInfoGrid>
              <OkedSelectTrigger
                label="Специализация"
                placeholder="Выберите cпециализацию"
                value={values.oked ? values.oked.nameRu : ''}
                onChange={onChangeOked}
                helperText={errors.oked}
                error={!!errors.oked}
                required={true}
              />
              <TextField
                label="Дополнительная информация"
                onChange={onChange}
                name="additionalInformation"
                value={values.additionalInformation}
                placeholder="Дополнительная информация"
                helperText={errors.additionalInformation}
                error={!!errors.additionalInformation}
              />
            </StyledInfoGrid>
            <div />
          </Tabs>
        </Paper>
        <StyledControls>
          <Button text="Отмена" color={'default'} onClick={onClickCancel} />
          <Button
            text="Сохранить"
            icon={IconCheckCircle16}
            onClick={onClickSave}
            loading={pending}
            disabled={pending}
          />
        </StyledControls>
      </LayoutProfile>
    </StyledUserEdit>
  );
}

export default memo(PageProfileCompanyEdit);
