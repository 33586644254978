import React, { Fragment, memo, useCallback } from 'react';
import {
  Checkbox,
  KatoSelectTrigger,
  SelectOption,
  TextField,
} from '@app/components';
import { IconPlusCircle24 } from '@app/icons';
import { Company, Kato, UserPhone } from '@app/models';
import './styles.scss';
import { fullName } from '@app/helpers';

export interface CompanyInfoData {
  legalAddress: string;
  address: string;
  instagram: string;
  telegram: string;
  webSite: string;
  whatsAppNumber: string;
  phones: UserPhone[];
  kato: Kato | null;
  email: string;
  filesIds: string | null;
  avatarFilesIds: string | null;
}

interface Props {
  values: CompanyInfoData;
  errors: any;
  onChange: (value: any, name?: string) => void;
  company: Company;
}

function CompanyInfoForm(props: Props) {
  const { company } = props;
  const { values, onChange, errors } = props;

  const onChangeKato = useCallback(
    (data: SelectOption<Kato>) => {
      onChange(data.value, 'kato');
    },
    [onChange]
  );

  const onChangePhones = useCallback(
    (index: number) => (value: string) => {
      onChange(
        values.phones.map((prevPhone, phoneIndex) => ({
          ...prevPhone,
          phone: phoneIndex === index ? value : prevPhone.phone,
        })),
        'phones'
      );
    },
    [onChange, values.phones]
  );

  const onClickAddPhone = useCallback(() => {
    onChange(
      [
        ...values.phones,
        {
          phone: '',
          isPrefer: false,
          isWhatsApp: false,
        },
      ],
      'phones'
    );
  }, [values.phones, onChange]);

  const onCheckPreferred = useCallback(
    (index: number) => () => {
      onChange(
        values.phones.map((prevPhone, phoneIndex) => ({
          ...prevPhone,
          isPrefer: phoneIndex === index,
        })),
        'phones'
      );
    },
    [onChange, values.phones]
  );

  return (
    <div className="f-company-info">
      <div className="f-company-info__main">
        <TextField
          label="наименование"
          value={company!.name}
          editable={false}
        />
        <TextField label="Бин" value={company!.bin} editable={false} />
        <TextField
          label="директор"
          value={fullName(company!.owner)}
          editable={false}
        />
        <KatoSelectTrigger
          label="Регион"
          placeholder="Выберите регион"
          value={values.kato ? values.kato.nameRu : ''}
          onChange={onChangeKato}
          helperText={errors.kato}
          error={!!errors.kato}
          required={true}
        />
        <TextField
          label="Юридический адрес"
          value={values.legalAddress || ''}
          placeholder="Введите юридический адрес"
          onChange={onChange}
          name="legalAddress"
          error={!!errors.legalAddress}
          helperText={errors.legalAddress}
          required={true}
        />
        <TextField
          label="фактический адрес"
          placeholder="Введите фактический адрес"
          value={values.address || ''}
          onChange={onChange}
          name="address"
          error={!!errors.address}
          helperText={errors.address}
          required={true}
        />
      </div>
      <div className="f-company-info__main-container">
        {values.phones.map((phone, phoneIndex) => (
          <Fragment key={phoneIndex.toString()}>
            <div className="f-company-info__main-phone">
              <TextField
                label={`Дополнительный номер телефона ${phoneIndex + 1}`}
                onChange={onChangePhones(phoneIndex)}
                value={phone.phone}
                mask="(###) ### ## ##"
                placeholder="(777) 777 77 77"
              />
              <Checkbox
                label={'Предпочитаемый способ связи'}
                checked={phone.isPrefer}
                onChange={onCheckPreferred(phoneIndex)}
              />
            </div>
          </Fragment>
        ))}
        <button
          onClick={onClickAddPhone}
          type="button"
          className="f-company-info__main-phone-btn"
        >
          <IconPlusCircle24 /> Добавить дополнительный номер
        </button>
      </div>
      <div className="f-company-info__main-bottom">
        <TextField
          label="E-mail"
          onChange={onChange}
          name="email"
          value={values.email}
          placeholder="Введите e-mail"
          error={!!errors.email}
          helperText={errors.email}
          required={true}
        />
        <TextField
          label="Адрес сайта"
          onChange={onChange}
          name="webSite"
          value={values.webSite}
          placeholder="Введите сайт"
        />
        <TextField
          label="Номер WhatsApp"
          onChange={onChange}
          name="whatsAppNumber"
          value={values.whatsAppNumber}
          mask="(###) ### ## ##"
          placeholder="(777) 777 77 77"
        />
        <TextField
          label="Instagram"
          onChange={onChange}
          name="instagram"
          value={values.instagram}
          placeholder="Введите instagram"
        />
        <TextField
          label="Telegram"
          onChange={onChange}
          name="telegram"
          value={values.telegram}
          placeholder="Введите telegram"
        />
      </div>
    </div>
  );
}

export default memo(CompanyInfoForm);
