import React, { memo } from 'react';
import { TenderCategory } from '@app/models';
import { PageProfileTenderSearch } from '../index';

interface Props {}

function PageProfileContractors(props: Props) {
  return (
    <PageProfileTenderSearch
      tenderCategory={TenderCategory.CONTRACTOR}
      title="СМР"
    />
  );
}

export default memo(PageProfileContractors);
