import instance from './instance';
import { Reply, Tender } from '@app/models';
import { PageableParams } from './params';
import { PageableResponse } from './responses';

export function createReply(data: any, files: File[]): Promise<Reply> {
  const formData = new FormData();

  Object.keys(data).forEach((dataKey) => {
    if (dataKey === 'requirements') {
      data[dataKey].forEach((requirement: any) => {
        formData.append(`${dataKey}[]`, requirement as string);
      });
    } else {
      formData.append(dataKey, data[dataKey] as string);
    }
  });

  files.forEach((uploadFile) => {
    formData.append('files', uploadFile);
  });

  return instance()
    .post('/replies/create', formData, {
      headers: { 'Content-Type': 'multipart/form-data;' },
    })
    .then((response) => response.data);
}

export function acceptReply(replyId: string) {
  return instance()
    .put(`/replies/accept/${replyId}`)
    .then((response) => response.data);
}

export function declineReply(replyId: string) {
  return instance()
    .put(`/replies/decline/${replyId}`)
    .then((response) => response.data);
}

export function pinnedReply(replyId: string) {
  return instance()
    .put(`/replies/pinned/${replyId}`)
    .then((response) => response.data);
}

export function unpinnedReply(replyId: string) {
  return instance()
    .put(`/replies/unpinned/${replyId}`)
    .then((response) => response.data);
}

export function getReplies(
  params: PageableParams & Partial<Tender | Reply>
): Promise<PageableResponse<Reply>> {
  return instance()
    .get(`/replies/getReplies/`, {
      params,
    })
    .then((response) => response.data);
}
