import React, { memo, useMemo } from 'react';
import { LayoutDefault } from '@app/layouts';
import { WelcomeBanner } from '@app/common';
import { Container, FilterTab, FilterTabs } from '@app/components';
import styled from 'styled-components';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routeConfigHome } from '../../config/routeConfig/home';

const StylesTitle = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 1px;
`;

interface Props {}

function HomePage(props: Props) {
  const data = useMemo<FilterTab[]>(
    () => [
      {
        title: 'CMP',
        path: '/',
      },
      { title: 'Материалы', path: '/suppliers' },
      { title: 'Спецтехника', path: '/vehicles' },
      { title: 'Специалисты', path: '/specialists' },
    ],
    []
  );

  return (
    <LayoutDefault>
      <WelcomeBanner />
      <Container>
        <StylesTitle>Найти</StylesTitle>
        <FilterTabs data={data}>
          <Routes>
            {Object.values(routeConfigHome).map(({ element, path }) => (
              <Route key={path} path={path} element={element} />
            ))}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </FilterTabs>
      </Container>
    </LayoutDefault>
  );
}

export default memo(HomePage);
