import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Doc as DocComponent, DownloadButton, Signer } from '@app/common';
import { Button, Grid, Switch } from '@app/components';
import { Doc, DocStatus, DocStatusName, UserType } from '@app/models';
import styled from 'styled-components';
import { blobToBase64, fullName } from '@app/helpers';
import { theme } from 'styled-tools';
import { useNotification, useUserContext } from '@app/providers';
import { getFileById, updateDoc } from '@app/api';
// @ts-ignore
import { NCALayerClient } from 'ncalayer-js-client';

interface Props {
  doc: Doc;
  defaultChecked: boolean;
  updateDoc: (doc: Partial<Doc>) => void;
  checkIin?: () => void;
}

const StyledDoc = styled.div`
  background-color: #f5f5f5;
  border-radius: 10px;
  display: grid;
  grid-gap: 24px;
  padding: 24px 16px;
  box-sizing: border-box;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  color: ${theme('color.dark')};
`;

function ContractDoc(props: Props) {
  const { doc, defaultChecked, updateDoc: updateDocProps, checkIin } = props;
  const [checked, setChecked] = useState<boolean>(defaultChecked);

  const { user, company } = useUserContext();
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);

  const ncaLayerClient = useMemo(() => new NCALayerClient(), []);

  const ownerSigned = useMemo(
    () => [DocStatus.OWNER_SIGNED, DocStatus.SIGNED].indexOf(doc.status) > -1,
    [doc]
  );

  const recipientSigned = useMemo(
    () =>
      [DocStatus.RECIPIENT_SIGNED, DocStatus.SIGNED].indexOf(doc.status) > -1,
    [doc]
  );

  const fileId = useMemo<string | null>(() => {
    try {
      return doc?.fileId as string;
    } catch (e) {
      return null;
    }
  }, [doc]);

  const onChangeSwitcher = useCallback((value: boolean) => {
    setChecked(value);
  }, []);

  const onClickSign = useCallback(async () => {
    try {
      if (checkIin && user.type === UserType.INDIVIDUAL && !user.iin) {
        checkIin();
        return;
      }
      if (!ncaLayerClient.wsConnection) {
        await ncaLayerClient.connect();
      }
    } catch (error) {
      showNotification({
        variant: 'error',
        message: 'NCALayer не подключен! Подключите и попробуйте снова',
      });

      return;
    }

    try {
      if (ncaLayerClient.wsConnection.readyState !== 1) {
        ncaLayerClient.wsConnection.close();

        showNotification({
          variant: 'error',
          message: 'NCALayer не подключен! Подключите и попробуйте снова',
        });

        return;
      }

      setPending(true);

      const blobContractFile = await getFileById(doc.fileId);
      const base64ContractFile = await blobToBase64(blobContractFile);

      const signResponse = await ncaLayerClient.createCAdESFromBase64(
        NCALayerClient.fileStorageType,
        base64ContractFile,
        'SIGNATURE',
        false
      );

      const docData: Partial<Doc> = {
        id: doc.id,
      };

      if (user.type === UserType.INDIVIDUAL) {
        if (user.id === doc.recipientId) {
          docData.recipientSign = signResponse;
          docData.status = DocStatus.RECIPIENT_SIGNED;
        } else {
          docData.ownerSign = signResponse;
          docData.status = DocStatus.OWNER_SIGNED;
        }
      } else {
        if (company!.id === doc.recipientCompanyId) {
          docData.recipientSign = signResponse;
          docData.status = DocStatus.RECIPIENT_SIGNED;
        } else {
          docData.ownerSign = signResponse;
          docData.status = DocStatus.OWNER_SIGNED;
        }
      }

      const docResponse = await updateDoc(docData);

      if (!!updateDocProps) {
        updateDocProps({
          id: docResponse.id,
          status: docResponse.status,
        });
      }

      setPending(false);
    } catch (e) {
      console.log(e);
      setPending(false);
    }
  }, [
    checkIin,
    ncaLayerClient,
    updateDocProps,
    showNotification,
    doc,
    user,
    company,
  ]);

  const control = useMemo(() => {
    if (doc.status === DocStatus.SIGNED) {
      return [
        <DownloadButton
          text="Скачать договор"
          fileId={fileId}
          variant="outlined"
        />,
      ];
    }

    if (user.type === UserType.INDIVIDUAL) {
      if (user.id === doc.recipientId && recipientSigned) {
        return null;
      }

      if (user.id === doc.ownerId && ownerSigned) {
        return null;
      }
    } else {
      if (company!.id === doc.recipientCompanyId && recipientSigned) {
        return null;
      }

      if (company!.id === doc.companyId && ownerSigned) {
        return null;
      }
    }

    return [
      <Button
        text="Подписать"
        onClick={onClickSign}
        disabled={pending}
        loading={pending}
      />,
    ];
  }, [
    fileId,
    user,
    onClickSign,
    pending,
    doc,
    recipientSigned,
    ownerSigned,
    company,
  ]);

  useEffect(() => {
    return () => {
      if (ncaLayerClient.wsConnection) {
        ncaLayerClient.wsConnection.close();
      }
    };
  }, [ncaLayerClient]);

  return (
    <StyledDoc>
      <StyledHeader>
        <StyledTitle>{`${doc.name}, ${
          !!doc.companyId ? doc.company!.name : fullName(doc.owner)
        }`}</StyledTitle>
        <Switch checked={checked} onChange={onChangeSwitcher} />
      </StyledHeader>
      {checked && (
        <>
          <Grid gap={48}>
            <DocComponent
              doc={doc}
              variant="secondary"
              hideStatus={true}
              hideName={true}
            />
            <Grid columns={2} gap={48}>
              <Signer
                signedText={DocStatusName[DocStatus.OWNER_SIGNED]}
                signed={ownerSigned}
                user={!doc.companyId ? doc.owner : null}
                company={!!doc.companyId ? doc.company : null}
              />
              <Signer
                signedText={DocStatusName[DocStatus.RECIPIENT_SIGNED]}
                signed={recipientSigned}
                user={!doc.recipientCompanyId ? doc.recipient : null}
                company={!!doc.recipientCompanyId ? doc.recipientCompany : null}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 16,
            }}
          >
            {control}
          </div>
        </>
      )}
    </StyledDoc>
  );
}

export default memo(ContractDoc);
