import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNotification, useUserContext } from '@app/providers';
import {
  Button,
  Paper,
  Select,
  SelectOption,
  Tabs,
  TextField,
} from '@app/components';
import { ProfileAvatar } from '../../components';
import { IconCheckCircle16 } from '@app/icons';
import { useNavigate } from 'react-router-dom';
import {
  Kato,
  User,
  UserEmployment,
  UserEmploymentNames,
  UserFamilyStatusNames,
  UserGenderName,
  UserReadyForBusinessTrips,
  UserReadyForBusinessTripsNames,
  UserSchedule,
  UserScheduleNames,
} from '@app/models';
import { mapRecordToSelectOption } from '@app/helpers';
import * as yup from 'yup';
import {
  changePassword,
  getKatoList,
  getParentKatoList,
  getUserById,
  updateUser,
  uploadUserAvatarFiles,
} from '@app/api';
import { FormUserInfo, useForm, UserInfoData } from '@app/forms';
import { LayoutProfile } from '@app/layouts';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

interface Props {}

interface UserData extends UserInfoData {
  specialization: string;
  employment: SelectOption<UserEmployment> | null;
  schedule: SelectOption<UserSchedule> | null;
  readyForBusinessTrips: SelectOption<UserReadyForBusinessTrips> | null;
  additionalInformation: string;
  password: string | null;
  oldPassword: string | null;
  confirmPassword: string | null;
}

const StyledUserEdit = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledContent = styled(Paper)`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 380px 1fr;

  > div {
    display: grid;
    grid-gap: 16px;
  }
`;

const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;

  > * + * {
    margin-left: 16px;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
`;

const StyledInfoGrid = styled.div`
  display: grid;
  grid-gap: 16px;
`;

// const schema = yup.object().shape({
//   firstName: yup.string().required(),
//   lastName: yup.string().required(),
//   gender: yup.object().nullable().required(),
// });

function PageProfileUserEdit(props: Props) {
  const {
    user: propsUser,
    setUser: setUserContext,
    updateUser: updateUserContext,
  } = useUserContext();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pending, setPending] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [optionsKato, setOptionsKato] = useState<SelectOption<Kato>[]>([]);

  const { onChange, values, errors, validate, setValues } = useForm<UserData>(
    {} as UserData
  );

  const getData = useCallback(async () => {
    try {
      setPending(true);

      const response = await getUserById(propsUser.id);

      setUser(response);

      setValues({
        iin: response.iin || '',
        specialization: response.specialization || '',
        lastName: response.lastName || '',
        firstName: response.firstName || '',
        middleName: response.middleName || '',
        phone: response.phone || '',
        phones: !!response.phones ? JSON.parse(response.phones) : [],
        gender: !response.gender
          ? null
          : mapRecordToSelectOption(response.gender, UserGenderName),
        address: response.address || '',
        kato: response.kato.nameRu || '',
        instagram: response.instagram || '',
        telegram: response.telegram || '',
        employment: !response.employment
          ? null
          : mapRecordToSelectOption(response.employment, UserEmploymentNames),
        schedule: !response.schedule
          ? null
          : mapRecordToSelectOption(response.schedule, UserScheduleNames),
        readyForBusinessTrips:
          response.readyForBusinessTrips === null
            ? null
            : mapRecordToSelectOption(
                UserReadyForBusinessTrips[
                  response.readyForBusinessTrips ? 'YES' : 'NO'
                ],
                UserReadyForBusinessTripsNames
              ),
        additionalInformation: response.additionalInformation || '',
        whatsAppNumber: response.whatsAppNumber || '',
        familyStatus:
          response.familyStatus === null
            ? null
            : mapRecordToSelectOption(
                response.familyStatus,
                UserFamilyStatusNames
              ),
        password: null,
        oldPassword: null,
        confirmPassword: null,
      });
      const katoList = !response.kato
        ? await getParentKatoList()
        : await getKatoList({
            parentNumber: response.kato.parentNumber,
          });

      setOptionsKato(
        katoList.map((item) => ({
          label: item.nameRu,
          value: item,
        }))
      );
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [propsUser.id, setValues]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onChangeAvatar = useCallback(
    async (files: File) => {
      try {
        const response = await uploadUserAvatarFiles(user!.id, [files]);
        updateUserContext({
          filesIds: response.filesIds,
          avatarFilesIds: response.avatarFilesIds,
        });
      } catch (e) {}
    },
    [updateUserContext, user]
  );
  const onChangePassword = useCallback(async () => {
    try {
      if (!user) return;
      if (values.password === null) {
        onChange('', 'password');
      } else {
        const hasErrors = await validate(
          yup.object().shape({
            oldPassword: yup
              .string()
              .required(t('required', { ns: 'validation' })!),
            password: yup
              .string()
              .required(t('required', { ns: 'validation' })!),
            confirmPassword: yup
              .string()
              .required(t('required', { ns: 'validation' })!)
              .oneOf(
                [yup.ref('password')],
                t('passwordConfirmation', { ns: 'validation' })!
              ),
          })
        );

        if (hasErrors || !values.oldPassword) {
          return;
        }
        setPending(true);
        await changePassword({
          oldPassword: values.oldPassword,
          user: {
            password: values.password,
            phone: values.phone,
          },
        });

        showNotification({
          variant: 'success',
          message: 'Пароль успешно обновлен',
        });

        onChange(null, 'password');
        onChange(null, 'oldPassword');
        onChange(null, 'confirmPassword');
      }
    } catch (e) {
      const error = e as AxiosError<{
        message: string;
      }>;
      if (error.response) {
        showNotification({
          variant: 'error',
          message: error.response.data.message,
        });
      } else {
        showNotification({
          variant: 'error',
          message: 'Пароль обновить не удалось',
        });
      }
    } finally {
      setPending(false);
    }
  }, [
    onChange,
    showNotification,
    t,
    user,
    validate,
    values.oldPassword,
    values.password,
    values.phone,
  ]);

  const onClickSave = useCallback(async () => {
    try {
      if (!user) return;
      const hasErrors = await validate(
        yup.object().shape({
          firstName: yup.string().required('Поле должно быть заполнено'),
          iin: yup.string().required('Поле должно быть заполнено'),
          lastName: yup.string().required('Поле должно быть заполнено'),
          specialization: yup.string().required('Поле должно быть заполнено'),
          gender: yup.object().nullable().required('Выберите пол'),
          middleName: yup.string().required('Поле должно быть заполнено'),
          familyStatus: yup
            .object()
            .nullable()
            .required('Выберите семейное положение'),
        })
      );

      if (hasErrors) {
        return;
      }

      setPending(true);

      const userToUpdateData: Partial<User> = {
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender!.value,
        iin: values.iin,
        familyStatus: values.familyStatus!.value,
      };

      if (!!values.middleName) {
        userToUpdateData.middleName = values.middleName;
      }

      if (!!values.address) {
        userToUpdateData.address = values.address;
      }

      if (!!values.kato) {
        const kato = optionsKato.find(
          (item) => item.value.nameRu === values.kato
        );
        if (kato) userToUpdateData.kato = kato.value;
      }

      if (!!values.instagram) {
        userToUpdateData.instagram = values.instagram;
      }

      if (!!values.telegram) {
        userToUpdateData.telegram = values.telegram;
      }

      if (!!values.whatsAppNumber) {
        userToUpdateData.whatsAppNumber = values.whatsAppNumber;
      }

      if (!!values.additionalInformation) {
        userToUpdateData.additionalInformation = values.additionalInformation;
      }

      if (!!values.specialization) {
        userToUpdateData.specialization = values.specialization;
      }

      if (!!values.employment) {
        userToUpdateData.employment = values.employment.value;
      }

      if (!!values.schedule) {
        userToUpdateData.schedule = values.schedule.value;
      }

      if (!!values.readyForBusinessTrips) {
        userToUpdateData.readyForBusinessTrips =
          values.readyForBusinessTrips.value === 10;
      }

      if (values.phones.length > 0) {
        userToUpdateData.phones = JSON.stringify(values.phones);
      }

      const response = await updateUser(user?.id, userToUpdateData);

      setUser({
        ...user,
        ...response,
      });

      setUserContext({
        ...user,
        ...response,
        authenticated: true,
      });

      showNotification({
        variant: 'success',
        message: 'Данные пользователя успешно обновлены',
      });

      setPending(false);

      navigate('/profile');
    } catch (e) {
      showNotification({
        variant: 'error',
        message: 'Не удалось обновить данные пользователя',
      });

      setPending(false);
    }
  }, [navigate, setUser, showNotification, user, validate, values]);

  const leftContent = useMemo(
    () => (
      <ProfileAvatar
        avatarFiles={
          user?.avatarFilesIds ? user.avatarFilesIds.split(',')[0] : ''
        }
        onSubmit={onChangeAvatar}
        editable={true}
      />
    ),
    [onChangeAvatar, user?.avatarFilesIds]
  );

  const rightContent = useMemo(() => {
    if (!user) {
      return null;
    }

    return (
      <FormUserInfo
        values={values}
        onChangePassword={onChangePassword}
        onChange={onChange}
        errors={errors}
      />
    );
  }, [errors, onChange, onChangePassword, user, values]);

  return (
    <StyledUserEdit>
      <LayoutProfile
        pending={pending}
        toolbarProps={{
          title: 'Личные данные',
        }}
        leftContent={leftContent}
        rightContent={rightContent}
      >
        <Paper>
          <Tabs onChange={() => {}} labels={['Профессиональные данные']}>
            <StyledInfoGrid>
              <StyledGrid>
                <TextField
                  label="Специализация"
                  value={values.specialization}
                  placeholder="Введите специализацию"
                  name="specialization"
                  onChange={onChange}
                  error={!!errors.specialization}
                  helperText={errors.specialization}
                  required
                />
                <Select
                  label="Форма Занятости"
                  value={values.employment}
                  placeholder="Форма Занятости"
                  options={[
                    UserEmployment.FULL,
                    UserEmployment.PART_TIME,
                    UserEmployment.DISTANT,
                  ].map((option) =>
                    mapRecordToSelectOption(option, UserEmploymentNames)
                  )}
                  name="employment"
                  onChange={onChange}
                  required
                />
                <Select
                  label="График работы"
                  value={values.schedule}
                  placeholder="График"
                  options={[
                    UserSchedule.FLEXIBLE,
                    UserSchedule.SHIFT,
                    UserSchedule.REMOVABLE,
                  ].map((option) =>
                    mapRecordToSelectOption(option, UserScheduleNames)
                  )}
                  name="schedule"
                  onChange={onChange}
                  required
                />
                <Select
                  label="Готов к командировкам"
                  value={values.readyForBusinessTrips}
                  placeholder="Готов к командировкам"
                  options={[
                    UserReadyForBusinessTrips.YES,
                    UserReadyForBusinessTrips.NO,
                  ].map((option) =>
                    mapRecordToSelectOption(
                      option,
                      UserReadyForBusinessTripsNames
                    )
                  )}
                  name="readyForBusinessTrips"
                  onChange={onChange}
                  required
                />
              </StyledGrid>
              <TextField
                label="Дополнительная информация"
                onChange={onChange}
                name="additionalInformation"
                value={values.additionalInformation}
                placeholder="Дополнительная информация"
                helperText={errors.additionalInformation}
                error={!!errors.additionalInformation}
              />
            </StyledInfoGrid>
            <div />
          </Tabs>
        </Paper>
        <StyledControls>
          <Button text="Отмена" color={'default'} onClick={onClickCancel} />
          <Button
            text="Сохранить"
            icon={IconCheckCircle16}
            onClick={onClickSave}
          />
        </StyledControls>
      </LayoutProfile>
    </StyledUserEdit>
  );
}

export default PageProfileUserEdit;
