import { useCallback, useState } from 'react';
import styles from './CastomCheckbox.module.scss';

interface Props {
  toggleCheckbox?: boolean;
  onClick?: () => void;
}

const CustomCheckbox = (props: Props) => {
  const { onClick, toggleCheckbox: toggleCheckboxProps = false } = props;
  const [toggleCheckbox, setToggleCheckbox] = useState(toggleCheckboxProps);
  const onToggle = useCallback(() => {
    setToggleCheckbox((prevState) => !prevState);
    if (!!onClick) {
      onClick();
    }
  }, [onClick]);
  return (
    <div className={styles.castomCheckbox}>
      <input type="checkbox" checked={toggleCheckbox} onChange={onToggle} />
    </div>
  );
};

export default CustomCheckbox;
