import React, { memo, useCallback, useMemo } from 'react';
import { Doc, DocTypes } from '@app/models';
import styled from 'styled-components';
import { Button, DatePicker, Paper, TextField } from '@app/components';
import { useForm } from '../hooks';
import { date, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

interface Props {
  onSubmit: (doc: Partial<Doc>) => void;
  type: DocTypes;
}

interface FormData {
  name: string;
  price: string;
  description: string;
  endDate: Date | null;
}

const StyledDoc = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-bottom: 16px;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;
  box-sizing: border-box;
`;

function FormDoc(props: Props) {
  const { onSubmit, type } = props;
  const { t } = useTranslation();
  const { values, onChange, errors, validate } = useForm<FormData>({
    name: '',
    price: '',
    description: '',
    endDate: null,
  });

  const onClickSubmit = useCallback(async () => {
    try {
      const hasErrors = await validate(
        object().shape({
          name: string().required(t('required', { ns: 'validation' })!),
          price: string().required(t('required', { ns: 'validation' })!),
          description: string().required(t('required', { ns: 'validation' })!),
          endDate: date().required(t('required', { ns: 'validation' })!),
        })
      );

      if (hasErrors) {
        return;
      }

      onSubmit({
        name: values.name,
        price: values.price,
        description: values.description,
        endDate: values.endDate!.toJSON(),
      });
    } catch (e) {}
  }, [onSubmit, t, validate, values]);

  const label = useMemo(() => {
    return type === DocTypes.CONTRACT ? 'договора' : 'документа';
  }, [type]);

  return (
    <StyledDoc>
      <Paper>
        <StyledGrid>
          <TextField
            value={values.name}
            name="name"
            onChange={onChange}
            label={`предмет ${label}`}
            placeholder={`Введите предмет ${label}`}
            error={!!errors.name}
            helperText={errors.name}
            required={true}
          />
          <DatePicker
            value={values.endDate}
            name="endDate"
            onChange={onChange}
            label={`Cрок ${label}`}
            placeholderText="Выберите срок"
            required={true}
            minDate={new Date()}
          />
          <TextField
            value={values.price}
            onChange={onChange}
            name="price"
            label={`сумма ${label}`}
            placeholder={`Введите сумму ${label}`}
            error={!!errors.price}
            helperText={errors.price}
            required={true}
          />
        </StyledGrid>
        <TextField
          value={values.description}
          onChange={onChange}
          name="description"
          label="Краткое содержание"
          placeholder="Введите краткое содержание"
          error={!!errors.description}
          helperText={errors.description}
          required={true}
        />
      </Paper>
      <StyledFooter>
        <Button text="Продолжить" onClick={onClickSubmit} />
      </StyledFooter>
    </StyledDoc>
  );
}

export default memo(FormDoc);
