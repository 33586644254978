import React, { memo, useMemo } from 'react';
import { Company, User } from '@app/models';
import { Info } from '@app/components';
import { useUserContext } from '@app/providers';
import { setMask, shotName } from '@app/helpers';

interface Props {
  author: User;
  company: Company;
}

function EntityUserInfo(props: Props) {
  const { author, company } = props;
  const { user } = useUserContext();
  // Почему у нас у компании в поле phones возвращается строка а не объект ?
  const companyPhone = useMemo(() => {
    if (company.phones) {
      const phone = JSON.parse(company.phones);
      return phone[0].phone;
    }
  }, [company.phones]);
  return (
    <div className="section-author__info">
      <Info label="Компания" value={company?.name} />
      <Info label="Автор" value={shotName(author)} />
      {user.authenticated && (
        <>
          {company.phones && (
            <Info
              label="Номер телефона"
              value={setMask(companyPhone, '+7 (###) ### ## ##')}
            />
          )}
          <Info label="Почтовый адрес" value={company.email} />
        </>
      )}
    </div>
  );
}

export default memo(EntityUserInfo);
