import React, { memo, useCallback } from 'react';
import {
  Button,
  KatoSelect,
  Modal,
  ModalProps,
  SelectOption,
} from '@app/components';
import './styles.scss';
import { Kato } from '@app/models';
import { IconCloseArrow } from '@app/icons';

interface KatoModalProps extends Partial<ModalProps> {
  onChange: (value: SelectOption<Kato>) => void;
  onClose?: () => void;
}

function KatoModal(props: KatoModalProps) {
  const { visible, className = '', onChange, onClose } = props;

  const onClickClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (onClose) {
        onClose();
      }
    },
    [onClose]
  );

  if (!visible) {
    return null;
  }

  return (
    <Modal className={`m-kato ${className}`.trim()} visible={visible}>
      <div className="m-kato__header">
        Выберите регион{' '}
        <div className="m-kato__close-btn" onClick={onClickClose}>
          <IconCloseArrow />
        </div>
      </div>
      {visible && <KatoSelect onChange={onChange} />}
      <div className="m-kato__footer">
        <Button text="Выбрать" onClick={onClickClose} />
      </div>
    </Modal>
  );
}

export default memo(KatoModal);
