import React, { Component, memo } from 'react';
import { Grid, Modal, ModalProps, Paper, Stepper } from '@app/components';
import { Step1 } from './components';
import { Doc, DocTypes } from '@app/models';
import { FilePickerForm, FormDoc } from '@app/forms';
import { Doc as DocComponent } from '@app/common';
import { createDoc } from '@app/api';
import styled from 'styled-components';

interface Props extends Partial<ModalProps> {
  contract: Doc;
  onCreate: (doc: Doc) => void;
}

interface State {
  docType: DocTypes | null;
  doc: Partial<Doc> | null;
  currentStep: number;
  pending: boolean;
  files: File[];
}

const StyledStepper = styled.div`
  background-color: theme('color.white');
  margin: -16px -16px 16px -16px;
`;

class ModalAddDoc extends Component<Props, State> {
  steps = [
    'Выберите тип документа',
    'Заполните данные договора',
    'Загрузить документ',
  ];

  state: State = {
    docType: null,
    doc: null,
    currentStep: 0,
    pending: false,
    files: [],
  };

  onClickClose = () => {
    const { onClose } = this.props;

    if (!!onClose) {
      onClose();
    }
  };

  onSubmitDocType = (docType: DocTypes) => {
    this.setState({
      docType,
      currentStep: 1,
    });
  };

  onSubmitDoc = (doc: Partial<Doc>) => {
    this.setState({
      doc,
      currentStep: 2,
    });
  };

  onSubmitFiles = (files: File[]) => {
    this.setState(
      {
        files,
        pending: true,
      },
      async () => {
        await this.createDoc();
      }
    );
  };

  createDoc = async () => {
    try {
      const { onClose, contract, onCreate } = this.props;
      const { docType, files, doc } = this.state;
      const params: Partial<Doc> = {
        type: docType!,
        contractId: contract.id,
        ...doc,
      };

      if (contract.companyId) {
        params.companyId = contract.companyId;
      }

      if (contract.recipientCompanyId) {
        params.recipientCompanyId = contract.recipientCompanyId;
      }

      if (contract.recipientId) {
        params.recipientId = contract.recipientId;
      }

      const docResponse = await createDoc(params, files);

      this.setState(
        {
          docType: null,
          doc: null,
          currentStep: 0,
          pending: false,
          files: [],
        },
        () => {
          onCreate(docResponse);
          onClose!();
        }
      );
    } catch (e) {
      this.setState({
        pending: false,
      });
    }
  };

  render() {
    const { visible, contract } = this.props;
    const { currentStep, pending, docType } = this.state;

    if (!visible || !contract) {
      return null;
    }

    return (
      <Modal visible={visible} size="xlarge">
        <StyledStepper>
          <Stepper
            onClose={this.onClickClose}
            title="Добавить документ"
            steps={this.steps}
            currentStep={currentStep}
            fullWidth={true}
          />
        </StyledStepper>
        <Grid gap={16}>
          <Paper title="Описание документ">
            <DocComponent
              doc={contract}
              hideStatus={true}
              variant="secondary"
            />
          </Paper>
          {currentStep === 0 && <Step1 onSubmit={this.onSubmitDocType} />}
          {currentStep === 1 && (
            <FormDoc onSubmit={this.onSubmitDoc} type={docType!} />
          )}
          {currentStep === 2 && (
            <FilePickerForm
              accept="application/pdf"
              pending={pending}
              defaultExpanded={currentStep === 2}
              onSubmit={this.onSubmitFiles}
              required={true}
            />
          )}
        </Grid>
      </Modal>
    );
  }
}

export default memo(ModalAddDoc);
