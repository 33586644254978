import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { SearchTemplate } from '@app/templates';
import { Tender, UserType } from '@app/models';
import { getTenders, PageableParams } from '@app/api';
import { Button, Checkbox, Info } from '@app/components';
import { useUserContext } from '@app/providers';
import { dateFormat } from '@app/helpers';

interface Props {
  onSubmit: (tenderId: string | null) => void;
}

const StyledStep3 = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledFooter = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 0 24px;
  box-sizing: border-box;
`;

const StyledTender = styled.div`
  padding: 16px;
  box-sizing: border-box;
  gap: 24px;
  background: #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 150px 150px 150px 50px;
  align-items: center;
`;

function Step3(props: Props) {
  const { onSubmit } = props;
  const { user, company } = useUserContext();
  const [tenderId, setTenderId] = useState<string>('');

  const getData = useCallback(
    (params: PageableParams) => {
      const defaultParams: Partial<Tender> = {};

      if (user.type === UserType.INDIVIDUAL) {
        defaultParams.ownerId = user.id;
      } else {
        defaultParams.companyId = company!.id;
      }

      return getTenders({
        ...params,
        ...defaultParams,
      });
    },
    [user, company]
  );

  const onClickSubmit = useCallback(() => {
    onSubmit(!!tenderId ? tenderId : null);
  }, [tenderId, onSubmit]);

  const renderItem = useCallback(
    (item: Tender) => {
      return (
        <StyledTender onClick={() => setTenderId(item.id)}>
          <Info
            label="Наименование"
            value={dateFormat(item.name)}
            small={true}
          />
          <Info label="Цена" value={dateFormat(item.price)} small={true} />
          <Info
            label="Дата подачи"
            value={dateFormat(item.createdAt)}
            small={true}
          />
          <Checkbox
            label=""
            checked={tenderId === item.id}
            onChange={() => {}}
          />
        </StyledTender>
      );
    },
    [tenderId]
  );

  return (
    <StyledStep3>
      <SearchTemplate<Tender>
        variant="paper"
        renderItem={renderItem}
        title="Выберите объявление (-я)"
        getData={getData}
        emptyTitle="Нет обявлении"
        horizontal={true}
        viewControl={false}
      />
      <StyledFooter>
        <Button text="Продолжить" onClick={onClickSubmit} />
      </StyledFooter>
    </StyledStep3>
  );
}

export default memo(Step3);
