interface Events {
  [key: string]: any[];
}

interface IEventEmitter {
  events: Events;
  emit: (event: string, data?: any) => void;
  subscribe: (event: string, callback: (data: any) => void) => Function;
}

export const EventEmitter: IEventEmitter = {
  events: {},
  emit: function (event, data) {
    if (!this.events[event]) {
      return;
    }

    this.events[event].forEach((callback) => callback(data));
  },
  subscribe: function (event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(callback);

    return () => {
      delete this.events[event];
    };
  },
};
