import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { IconChevronLeft24, IconChevronRight24 } from '@app/icons';

export interface Props {
  initialPage: number;
  totalPages: number;
  totalItems: number;
  onChange: (page: number) => void;
}

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;

  > * + * {
    margin-left: 24px !important;
  }
`;

const StyledText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  margin: 0;
`;

const StyledControls = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`;

const StyledControl = styled.button`
  border: 1px solid #ededed;
  border-radius: 10px;
  background-color: transparent;
  outline: none;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  color: #757575;
  cursor: pointer;
`;

function Pagination(props: Props) {
  const { initialPage, totalPages, totalItems, onChange } = props;
  const [page, setPage] = useState<number>(initialPage);

  const onClickPrev = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (page > 1) {
        setPage(page - 1);
        onChange(page - 1);
      }
    },
    [onChange, page]
  );

  const onClickNext = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (page < totalPages) {
        setPage(page + 1);
        onChange(page + 1);
      }
    },
    [onChange, page, totalPages]
  );

  if (!totalPages) {
    return null;
  }

  return (
    <StyledPagination>
      <StyledText>Кол-во: {totalItems}</StyledText>
      <StyledText>Страница: {page}</StyledText>
      <StyledControls>
        <StyledControl onClick={onClickPrev}>
          <IconChevronLeft24 />
        </StyledControl>
        <StyledControl onClick={onClickNext}>
          <IconChevronRight24 />
        </StyledControl>
      </StyledControls>
    </StyledPagination>
  );
}

export default memo(Pagination);
