import { Review } from '@app/models';
import instance from './instance';
import { PageableParams } from './params';
import { PageableResponse } from './responses';

export function getReviews(
  params: PageableParams & Partial<Review>
): Promise<PageableResponse<Review>> {
  return instance()
    .get('/reviews/getAll', {
      params,
    })
    .then((response) => response.data);
}

export function createReview(data: Partial<Review>): Promise<Review> {
  return instance()
    .post('/reviews/create', data)
    .then((response) => response.data);
}
