import React, { memo, useCallback } from 'react';
import { SearchTemplate } from '@app/templates';
import { Tender, TenderStatusNames } from '@app/models';
import { getTenders, PageableParams } from '@app/api';
import styled from 'styled-components';
import { Info } from '@app/components';

interface Props {
  params: Partial<Tender>;
}

const StyledTender = styled.button`
  background: #f5f5f5;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  gap: 24px;
  padding: 16px;
  box-sizing: border-box;
  text-align: left;
`;

function ContractTenderSearch(props: Props) {
  const { params: defaultParams } = props;
  const onClickTender = useCallback((item: Tender) => () => {}, []);

  const renderItem = useCallback(
    (item: Tender) => {
      return (
        <StyledTender onClick={onClickTender(item)}>
          <Info
            label="Наименование объявления"
            value={item.name}
            small={true}
          />
          <Info label="Дата создания" value={item.name} small={true} />
          <Info
            label="Статус"
            value={TenderStatusNames[item.status]}
            small={true}
          />
        </StyledTender>
      );
    },
    [onClickTender]
  );

  const getData = useCallback(
    (params: PageableParams) => {
      return getTenders({
        ...params,
        ...defaultParams,
      });
    },
    [defaultParams]
  );

  return (
    <SearchTemplate<Tender>
      getData={getData}
      renderItem={renderItem}
      horizontal={true}
      viewControl={false}
    />
  );
}

export default memo(ContractTenderSearch);
