import React, { memo, useCallback, useMemo, useState } from 'react';
import { TenderSearch } from '@app/common';
import { Tender, TenderCategory, UserType } from '@app/models';
import { useUserContext } from '@app/providers';
import { useNavigate } from 'react-router-dom';
import { Button } from '@app/components';
import { IconPlusCircle24 } from '@app/icons';
import { ModalTenderCreate } from '@app/modals';

interface Props {
  tenderCategory: TenderCategory;
  title?: string;
}

function PageProfileTenderSearch(props: Props) {
  const { tenderCategory, title } = props;
  const { user, company } = useUserContext();
  const navigate = useNavigate();
  const [modalTenderCreateVisible, setModalTenderCreateVisible] =
    useState<boolean>(false);

  const onClickTender = useCallback(
    (id: string) => () => {
      navigate(`/profile/tender/${id}`);
    },
    [navigate]
  );

  const onClickCreate = useCallback(() => {
    setModalTenderCreateVisible(true);
  }, []);

  const closeModalTenderCreate = useCallback(() => {
    setModalTenderCreateVisible(false);
  }, []);

  const onClickReplies = useCallback(() => {
    navigate(`/profile/tender/${tenderCategory}/replies`);
  }, [navigate, tenderCategory]);

  const controls = useMemo(
    () => [
      <Button text="Перейти в список откликов" onClick={onClickReplies} />,
      <Button
        text="Создать объявление"
        icon={IconPlusCircle24}
        onClick={onClickCreate}
      />,
    ],
    [onClickCreate, onClickReplies]
  );

  const defaultParams = useMemo<Partial<Tender>>(() => {
    const paramsToReturn: Partial<Tender> = {
      category: tenderCategory,
    };

    if (user.type === UserType.ENTITY) {
      paramsToReturn.companyId = company!.id;
    } else {
      paramsToReturn.ownerId = user.id;
    }

    return paramsToReturn;
  }, [tenderCategory, user, company]);

  return (
    <>
      <TenderSearch
        params={defaultParams}
        onClick={onClickTender}
        tenderCategory={tenderCategory}
        columns={4}
        title={title}
        controls={controls}
        emptyTitle={`Вы еще не опубликовали заявки в категории «${title}»`}
      />
      <ModalTenderCreate
        companyId={!!company ? company.id : null}
        visible={modalTenderCreateVisible}
        closeModal={closeModalTenderCreate}
        tenderCategory={tenderCategory}
      />
    </>
  );
}

export default memo(PageProfileTenderSearch);
