import { memo } from 'react';
import styled from 'styled-components';
// import './styles.scss';

interface IHorizontal {
  horizontal?: boolean;
}

const StyledDescription = styled.p<IHorizontal>`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #424242;
  margin: 8px 0;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: ${(props) => (props.horizontal ? '576px' : '100%')};
`;

interface Props {
  text: string;
  horizontal?: boolean;
}

function CardDescription(props: Props) {
  const { text, horizontal } = props;

  return (
    <StyledDescription
      className="smr-card__description"
      horizontal={horizontal}
    >
      {text}
    </StyledDescription>
  );
}

export default memo(CardDescription);
