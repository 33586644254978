import styles from './Report.module.scss';
import { memo, useCallback, useState } from 'react';
import { IconSended } from '@app/icons';
import TextField from 'components/TextField';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { useUserContext } from '@app/providers';
import { useForm } from '@app/forms';
import { Company, Tender, User } from '@app/models';
import { postReport } from '@app/api';
import { Modal, ModalProps } from '@app/components';

interface Props extends Partial<ModalProps> {
  tender?: Tender;
  user?: User;
  company?: Company;
}

interface IReport {
  message: string;
}
function ReportModal(props: Props) {
  const { tender, user, company, visible, onClose } = props;
  const [messageSent, onSendMessage] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { user: author } = useUserContext();
  const [data, setData] = useState(
    company ||
      tender || {
        ...user,
        name: `${user?.firstName} ${user?.middleName} ${user?.lastName}`,
      }
  );

  const { errors, onChange, values, validate } = useForm<IReport>({
    message: '',
  });

  const onSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const reportData = {
        message: values.message,
        authorId: author.id,
        tenderId: tender && tender.id,
        userId: user && user.id,
        companyId: company && company.id,
      };
      await postReport(reportData);
      setIsPending(true);
      setTimeout(() => {
        setIsPending(false);
      }, 1500);
      onSendMessage(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = useCallback(() => {
    onSendMessage(false);
    onChange('', 'message');

    if (!!onClose) {
      onClose();
    }
  }, [onChange, onClose]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      title={!messageSent ? `Пожаловаться на ${data ? data.name : ''}` : ''}
      visible={visible}
      onClose={onCloseModal}
      size="medium"
      className={styles.container}
    >
      <div className={messageSent ? styles.sent : styles.content}>
        {messageSent ? (
          <>
            {isPending ? (
              <span className={styles.loader}>
                <Loader />
              </span>
            ) : (
              <>
                <span className={styles.sendIcon}>
                  <IconSended />
                </span>
                <div className={styles.sendText}>Жалоба отправлена!</div>
                <div className={styles.sendButton}>
                  <Button text="Понятно" onClick={onClose} />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className={styles.input}>
              <form onSubmit={onSubmitForm}>
                <TextField
                  label={'комментарий'}
                  value={values.message}
                  placeholder={'Введите суть жалобы'}
                  name="message"
                  onChange={onChange}
                />
                <div className={styles.buttonContainer}>
                  <Button
                    text="Отправить"
                    className={styles.button}
                    type="submit"
                    // onClick={onSendMessageHandler}
                  />
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default memo(ReportModal);
