import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Divider } from '@app/components';
import { useUserContext } from '@app/providers';
import {
  IconChevronLeft20,
  IconChevronRight20,
  IconCog24,
  IconLogout16,
  IconPersonCircle24,
} from '@app/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { shotName } from '@app/helpers';
import { useNavigate } from 'react-router-dom';
import { UserType } from '@app/models';

interface Props {
  setProfileDetailsVisible: (value: boolean) => void;
}

const StyledProfilesCard = styled.div`
  padding: 20px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 16px;
  width: 280px;
  position: absolute;
  top: calc(100% + 16px);
  background-color: ${theme('color.white')};
  box-shadow: 2px 1px 7px 0 rgba(0, 0, 0, 0.32);
  border-radius: 24px;
  right: 0;
`;

const StyledProfilesCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDots = styled.div`
  display: flex;
  align-items: center;
  margin-left: -6px;
`;

const StyledDot = styled.span<{
  active: boolean;
}>`
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  background-color: ${theme('color.dark')};
  border-color: ${(props) =>
    props.active ? theme('color.dark') : 'transparent'};
  opacity: ${(props) => (props.active ? '1' : '0.3')};
  margin-left: 6px;
  box-sizing: border-box;
`;

const StyledControls = styled.div`
  border: 1px solid ${theme('color.grayLight')};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const StyledControl = styled.button`
  display: inline-flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  color: ${(props) =>
    props.disabled ? theme('color.grayLight') : theme('color.dark')};

  &:hover {
    background-color: ${(props) => (props.disabled ? 'transparent' : '#eee')};
    cursor: pointer;
  }
`;

const StyledProfilesCardContent = styled.div``;

const StyledProfilesCardFooter = styled.div`
  display: flex;

  > * {
    width: 100%;
  }
`;

const StyledProfilesCardInfo = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

const StyledProfilesCardTitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme('color.dark')};
`;

const StyledProfilesCardText = styled.p`
  margin: 0 0 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${theme('color.gray')};
`;

const StyledProfilesCardNotifications = styled.span`
  display: block;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${theme('color.primary')};

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${theme('color.primary')};
    border-radius: 50%;
    margin-right: 4px;
  }
`;

const StyledNavigationButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${theme('color.primary')};
  background-color: transparent;
  border: none;
  padding: 0 8px;
  box-sizing: border-box;
  height: 56px;
  cursor: pointer;
`;

const StyledNavigationButtonText = styled.span`
  color: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
`;

const ProfilesCard: FC<Props> = ({ setProfileDetailsVisible }) => {
  const {
    user,
    company,
    logout,
    setCompany,
    currentProfile,
    setCurrentProfile,
  } = useUserContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(
    currentProfile.currentType === 'user'
      ? 0
      : user.type === UserType.ENTITY && company
      ? user.companies.findIndex(
          (userCompany) => userCompany.id === company.id
        ) + 1
      : 0
  );

  const onClickPrev = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  }, []);

  const onClickNext = useCallback(() => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }, []);

  const onClickLogout = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      logout();
    },
    [logout]
  );

  const onClickProfile = useCallback(() => {
    if (currentIndex === 0) {
      navigate('/profile');
      setProfileDetailsVisible(false);
    } else {
      setProfileDetailsVisible(false);
      navigate('/profile/company');
    }
  }, [currentIndex, navigate, setProfileDetailsVisible]);

  const onClickSettings = useCallback(() => {}, []);

  const popoverRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handler = (e: any) => {
      if (!popoverRef.current?.contains(e.target)) {
        setProfileDetailsVisible(false);
      }
    };

    document.addEventListener('click', handler);

    return () => document.removeEventListener('click', handler);
  }, [popoverRef, setProfileDetailsVisible]);

  const renderInfo = useCallback(
    (title: string) => {
      return (
        <StyledProfilesCardInfo ref={popoverRef}>
          <StyledProfilesCardTitle>{title}</StyledProfilesCardTitle>
          <StyledProfilesCardText>
            {currentProfile.currentType === 'user'
              ? 'Личный профиль'
              : 'Сотрудник'}
          </StyledProfilesCardText>
          {/* <StyledProfilesCardNotifications>
          5 уведомлений
        </StyledProfilesCardNotifications> */}
        </StyledProfilesCardInfo>
      );
    },
    [currentProfile.currentType]
  );

  const companiesLength = useMemo(
    () => user.companies.length,
    [user.companies]
  );

  useEffect(() => {
    if (user.companies.length > 0) {
      if (currentIndex > 0) {
        setCompany(user.companies[currentIndex - 1]);
        setCurrentProfile({
          ...user.companies[currentIndex - 1],
          currentType: 'company',
        });
      } else if (currentIndex === 0) {
        setCurrentProfile({ ...user, currentType: 'user' });
      }
    }
  }, [currentIndex, setCompany, setCurrentProfile, user, user.companies]);

  return (
    <StyledProfilesCard ref={popoverRef}>
      <StyledProfilesCardHeader>
        <StyledDots>
          <StyledDot active={currentIndex === 0} />
          {user.companies.map((userCompany, userCompanyIndex) => (
            <StyledDot
              key={userCompanyIndex.toString()}
              active={userCompanyIndex + 1 === currentIndex}
            />
          ))}
        </StyledDots>
        <StyledControls>
          <StyledControl disabled={currentIndex === 0} onClick={onClickPrev}>
            <IconChevronLeft20 />
          </StyledControl>
          <StyledControl
            disabled={currentIndex === companiesLength}
            onClick={onClickNext}
          >
            <IconChevronRight20 />
          </StyledControl>
        </StyledControls>
      </StyledProfilesCardHeader>
      <StyledProfilesCardContent>
        {currentIndex === 0
          ? renderInfo(shotName(user))
          : renderInfo(user.companies[currentIndex - 1].name)}
        <StyledNavigationButton onClick={onClickProfile}>
          <IconPersonCircle24 />
          <StyledNavigationButtonText>
            Перейти в профиль
          </StyledNavigationButtonText>
        </StyledNavigationButton>
        {currentIndex === 0 && (
          <>
            <Divider />
            <StyledNavigationButton onClick={onClickSettings}>
              <IconCog24 />
              <StyledNavigationButtonText>Настройки</StyledNavigationButtonText>
            </StyledNavigationButton>
          </>
        )}
      </StyledProfilesCardContent>
      <StyledProfilesCardFooter>
        <Button
          icon={IconLogout16}
          text={t('logout')}
          onClick={onClickLogout}
        />
      </StyledProfilesCardFooter>
    </StyledProfilesCard>
  );
};

export default memo(ProfilesCard);
