import React, { memo, PropsWithChildren } from 'react';
import styled from 'styled-components';

export const StyledContainer = styled.div<{
  fullWidth?: boolean;
}>`
  max-width: ${(props) => (!!props.fullWidth ? '100%' : '1144px')};
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
`;

interface ContainerProps {
  className?: string;
  fullWidth?: boolean;
}

function Container(props: PropsWithChildren<ContainerProps>) {
  const { className = '', fullWidth = false, children } = props;

  return (
    <StyledContainer className={className} fullWidth={fullWidth}>
      {children}
    </StyledContainer>
  );
}

export default memo(Container);
