import React, { memo, useCallback, useMemo } from 'react';
import placeholderSrc from './placeholder.svg';
import { IconEditProfile, IconPlusCircle24 } from '@app/icons';
import './styles.scss';
import styled from 'styled-components';

interface WrapperStyledProps {
  size: number;
}

interface LabelStyledProps extends WrapperStyledProps {}

interface Props {
  src?: string;
  onChange?: (file: File | null) => void;
  value?: File | null;
  size?: number;
  editable?: boolean;
  customPlaceholder?: string;
}

const WrapperStyled = styled.div<WrapperStyledProps>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  position: relative;
`;

const LabelStyled = styled.label<LabelStyledProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  width: ${(props) => `${props.size * 0.27}px`};
  height: ${(props) => `${props.size * 0.27}px`};
  background-color: #3a57e8;
  position: absolute;
  bottom: 0;
  right: 0;

  svg {
    width: ${(props) => `${props.size * 0.16}px`};
    height: ${(props) => `${props.size * 0.16}px`};
  }
`;

function Avatar(props: Props) {
  const {
    onChange,
    src = null,
    value = null,
    size = 244,
    editable = true,
    customPlaceholder,
  } = props;

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const { files } = e.target;

      onChange!(!!files ? files[0] : null);
    },
    [onChange]
  );

  const imageSrc = useMemo(() => {
    if (!!value) {
      return URL.createObjectURL(value);
    }

    return src
      ? `${process.env.REACT_APP_API_URL}/api/v1/files/getById/` + src
      : customPlaceholder || placeholderSrc;
  }, [customPlaceholder, src, value]);

  return (
    <WrapperStyled size={size} className="b-avatar">
      <img src={imageSrc} alt="" className="b-avatar__image" />
      {!!onChange && editable && (
        <LabelStyled size={size}>
          {!!src || !!value ? <IconEditProfile /> : <IconPlusCircle24 />}
          {/*{!value && <IconPlusCircle24 />}*/}
          <input
            className="b-avatar__input"
            type="file"
            onChange={onChangeInput}
          />
        </LabelStyled>
      )}
    </WrapperStyled>
  );
}

export default memo(Avatar);
