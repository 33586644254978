import React, {
  Fragment,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  FilePicker,
  Paper,
  PortfolioList,
  Tabs,
} from '@app/components';
import {
  IconEdit16,
  IconEmail16,
  IconPhone16,
  IconWhatsApp16,
} from '@app/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ModalReport } from '@app/modals';
import { LayoutProfile } from '@app/layouts';
import { ProfileCompanyAdditionalInfo, ProfileCompanyInfo } from './components';
import { deleteCompanyFile, uploadCompanyFiles } from '@app/api';
import { Company } from '@app/models';
import { ProfileAvatar } from '../../pages/Profile/components';
import AboutUser from '../../pages/Profile/components/AboutUser';
import { useUserContext } from '@app/providers';
import Reviews from '../Reviews';

interface Props {
  company: Company;
  updateCompany?: (company: Partial<Company>) => void;
  editable?: boolean;
}

const NOT_SPECIFIED = 'Не указан';

const StyledWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledEmployeesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledBlockForButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  margin-top: 15px;

  > button + button,
  button + a,
  a + button {
    margin-left: 8px;
  }
`;

function ProfileCompany(props: Props) {
  const { company, updateCompany, editable = false } = props;
  const { user: authUser, company: authCompany } = useUserContext();
  const navigate = useNavigate();
  const [addEmployeeModalOpen, setAddEmployeeModalOpen] =
    useState<boolean>(false);
  const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);

  const onClickEdit = useCallback(() => {
    navigate(`/profile/company/${company!.bin}/edit`);
  }, [company, navigate]);

  const onShowAddEmployeeModal = useCallback(() => {
    setAddEmployeeModalOpen(true);
  }, []);

  const onCloseAddEmployeeModal = useCallback(() => {
    setAddEmployeeModalOpen(false);
  }, []);

  const onChangeFiles = useCallback(
    async (files: File[]) => {
      try {
        const response = await uploadCompanyFiles(company!.id, files);

        if (updateCompany) {
          updateCompany({
            ...company,
            filesIds: response.filesIds,
          });
        }
      } catch (e) {}
    },
    [updateCompany, company]
  );

  const onDeleteFile = useCallback(
    async (fileId: string) => {
      try {
        if (updateCompany) {
          updateCompany({
            filesIds: company
              .filesIds!.split(',')
              .filter((item) => item !== fileId)
              .join(','),
          });
        }

        await deleteCompanyFile(company.id, fileId);

        return true;
      } catch (e) {
        return false;
      }
    },
    [updateCompany, company]
  );

  const onClickReport = useCallback(() => {
    setReportModalVisible(true);
  }, []);

  const onCloseReportModal = useCallback(() => {
    setReportModalVisible(false);
  }, []);

  const actionButtons = useMemo<ReactNode[]>(() => {
    if (!company) {
      return [];
    }

    const returnActionButtons = [
      <Button text="Пожаловаться" color="danger" onClick={onClickReport} />,
      <a
        className="section-author__footer-whatsapp"
        href={`mailto:+${company.email}`}
      >
        <IconEmail16 />
      </a>,
    ];

    if (!!company.whatsAppNumber) {
      returnActionButtons.push(
        <a
          className="section-author__footer-whatsapp"
          href={`https://api.whatsapp.com/send?phone=${company.whatsAppNumber}`}
        >
          <IconWhatsApp16 />
        </a>
      );
    }
    if (company.phones) {
      returnActionButtons.push(
        <a
          className="section-author__footer-whatsapp"
          href={`tel:+${company?.phones[0]}`}
        >
          <IconPhone16 />
        </a>
      );
    }

    return [...returnActionButtons];
  }, [onClickReport, company]);

  const leftContent = useMemo(() => {
    if (!company) {
      return null;
    }

    return (
      <>
        <ProfileAvatar
          avatarFiles={
            !!company.avatarFilesIds ? company.avatarFilesIds.split(',')[0] : ''
          }
          editable={false}
        />
        <AboutUser
          ratingUser={company.rating}
          views={14}
          lastDateUpdate={9}
          email={company.email}
          instagram={company.instagram || NOT_SPECIFIED}
          telegram={company.telegram || NOT_SPECIFIED}
          phones={company.phones}
          whatAppNumber={company.whatsAppNumber}
        />
      </>
    );
  }, [company]);

  const rightContent = useMemo(() => {
    if (!company) {
      return null;
    }

    return <ProfileCompanyInfo company={company} />;
  }, [company]);

  const tabLabels = useMemo<string[]>(() => {
    const labels = ['Профессиональные данные'];

    // if (editable) {
    //   labels.push('Сотрудники');
    // }

    if (editable) {
      labels.push('Документы и Файлы');
    }

    return [...labels, 'Опыт работы', 'Отзывы'];
  }, [editable]);

  const tabContent = useMemo<ReactNode[]>(() => {
    //Профессиональные данные
    //Документы и Файлы
    const content = [
      <StyledGrid>
        <ProfileCompanyAdditionalInfo company={company} />
      </StyledGrid>,
    ];
    //Профессиональные данные
    //Документы и Файлы

    // if (editable) {
    //   /*Сотрудники*/
    //   content.push(
    //     <StyledEmployeesWrapper>
    //       <Button
    //         text="Добавить сотрудника"
    //         icon={IconPlusCircle24}
    //         onClick={onShowAddEmployeeModal}
    //       />
    //       <ModalAddEmployee
    //         visible={addEmployeeModalOpen}
    //         onClose={onCloseAddEmployeeModal}
    //       />
    //     </StyledEmployeesWrapper>
    //   );
    // }

    if (editable) {
      content.push(
        <FilePicker
          loadableFiles={!!company.filesIds ? company.filesIds.split(',') : []}
          onChange={onChangeFiles}
          editable={editable}
          onDelete={onDeleteFile}
        />
      );
    }

    /*Опыт работы*/
    /*Отзывы*/
    return [
      ...content,
      <PortfolioList companyId={company.id} editable={editable} />,
      <Reviews
        companyId={company.id}
        showButton={!authCompany || company.id !== authCompany.id}
      />,
    ];
  }, [authCompany, company, editable, onChangeFiles, onDeleteFile]);

  return (
    <StyledWrapper>
      <LayoutProfile
        pending={false}
        toolbarProps={
          editable
            ? {
                title: 'Данные организации',
                rightControls:
                  authUser.id === company?.owner.id
                    ? [
                        <Button
                          text="Редактировать"
                          icon={IconEdit16}
                          onClick={onClickEdit}
                        />,
                      ]
                    : [],
              }
            : null
        }
        leftContent={leftContent}
        rightContent={rightContent}
      >
        <Paper>
          <Tabs labels={tabLabels}>
            {tabContent.map((elem, index) => {
              return <Fragment key={index}>{elem}</Fragment>;
            })}
          </Tabs>
          {!editable && authUser.authenticated && (
            <div className="section-author__footer">
              <StyledBlockForButton>
                {actionButtons.map((action, actionIndex) => (
                  <Fragment key={actionIndex}>{action}</Fragment>
                ))}
              </StyledBlockForButton>
            </div>
          )}
        </Paper>
      </LayoutProfile>
      {company && (
        <ModalReport
          company={company}
          visible={reportModalVisible}
          onClose={onCloseReportModal}
        />
      )}
    </StyledWrapper>
  );
}

export default memo(ProfileCompany);
