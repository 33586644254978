import React, { useCallback } from 'react';
import { Modal, ModalProps } from '@app/components';
import { ProfileCompany } from '@app/common';
import styled from 'styled-components';
import { Company } from '@app/models';

interface Props extends ModalProps {
  company: Company | null;
}

const StyledContent = styled.div`
  overflow-y: auto;
  max-height: 700px;
  margin: -16px -16px -24px;
  padding: 24px;
  box-sizing: border-box;
`;

function ModalProfile(props: Props) {
  const { company, visible, onClose: propsOnClose } = props;

  const closeModal = useCallback(() => {
    if (propsOnClose) {
      propsOnClose();
    }
  }, [propsOnClose]);

  if (!visible || !company) {
    return null;
  }

  return (
    <Modal
      title={company.name}
      visible={visible}
      onClose={closeModal}
      size="large"
    >
      <StyledContent>
        <ProfileCompany company={company} editable={false} />
      </StyledContent>
    </Modal>
  );
}

export default ModalProfile;
