import React, { memo, useCallback, useState } from 'react';
import { Button, Checkbox, Grid, Paper } from '@app/components';
import { DocTypeName, DocTypes } from '@app/models';

interface Props {
  onSubmit: (type: DocTypes) => void;
}

function TenderCategoryForm(props: Props) {
  const { onSubmit } = props;
  const [docType, setDocType] = useState<DocTypes>(DocTypes.ACT);

  const onChange = useCallback(
    (type: DocTypes) => (checked: boolean) => {
      if (!checked) {
        return;
      }

      setDocType(type);
    },
    []
  );

  const onClickNext = useCallback(
    (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onSubmit(docType);
    },
    [docType, onSubmit]
  );

  return (
    <Paper title="Выберите тип документа">
      <Grid gap={16}>
        <div>
          <Checkbox
            label={DocTypeName[DocTypes.ACT]}
            checked={DocTypes.ACT === docType}
            onChange={onChange(DocTypes.ACT)}
            variant="radio"
          />
          <Checkbox
            label={DocTypeName[DocTypes.INVOICE]}
            checked={DocTypes.INVOICE === docType}
            onChange={onChange(DocTypes.INVOICE)}
            variant="radio"
          />
          <Checkbox
            label={DocTypeName[DocTypes.ADDITIONAL_AGREEMENT]}
            checked={DocTypes.ADDITIONAL_AGREEMENT === docType}
            onChange={onChange(DocTypes.ADDITIONAL_AGREEMENT)}
            variant="radio"
          />
          <Checkbox
            label={DocTypeName[DocTypes.OTHER]}
            checked={DocTypes.OTHER === docType}
            onChange={onChange(DocTypes.OTHER)}
            variant="radio"
          />
        </div>
      </Grid>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button text="Продолжить" onClick={onClickNext} />
      </div>
    </Paper>
  );
}

export default memo(TenderCategoryForm);
