import { useCallback, useState } from 'react';
import { Schema, ValidationError } from 'yup';

type Error<T> = {
  [K in keyof T]?: string | null;
};

interface UserForm<T> {
  values: T;
  errors: Error<T>;
  onChange: (value: any, name?: string) => void;
  validate: (schema: Schema) => Promise<boolean>;
  setValues: (values: Partial<T>) => void;
  setErrors: (errors: Error<T>) => void;
}

export function useForm<T>(defaultValues: T): UserForm<T> {
  const [values, setValues] = useState<T>(defaultValues);
  const [errors, setErrors] = useState<Error<T>>({});

  const updateValues = useCallback((valuesToUpdate: Partial<T>) => {
    setValues((prevValues) => ({
      ...prevValues,
      ...valuesToUpdate,
    }));
  }, []);

  const onChange = useCallback(
    (value: any, name?: string) => {
      if (!!errors[name as keyof T] && !!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name as keyof T]: undefined,
        }));
      }

      setValues((prevValues) => ({
        ...prevValues,
        [name as keyof T]: value,
      }));
    },
    [errors]
  );

  const updateErrors = useCallback((errorToUpdate: Error<T>) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...errorToUpdate,
    }));
  }, []);

  const validate = useCallback(
    async (schema: Schema) => {
      try {
        await schema.validate(values, {
          abortEarly: false,
        });

        return false;
      } catch (e) {
        const validationErrors = e as ValidationError;
        const tempErrors: typeof errors = {};

        validationErrors.inner.forEach((error: ValidationError) => {
          if (
            Object.keys(values as keyof T).indexOf(error.path as string) > -1
          ) {
            tempErrors[error.path as keyof T] = error.errors[0];
          }
        });

        setErrors(tempErrors as typeof errors);

        return true;
      }
    },
    [values]
  );

  return {
    values,
    errors,
    onChange,
    validate,
    setValues: updateValues,
    setErrors: updateErrors,
  };
}
