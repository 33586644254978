import React, { Fragment, memo, useCallback } from 'react';
import {
  Checkbox,
  KatoSelectTrigger,
  Select,
  SelectOption,
  TextField,
} from '@app/components';
import { IconEdit16, IconPlusCircle24 } from '@app/icons';
import {
  Kato,
  UserFamilyStatus,
  UserFamilyStatusNames,
  UserGender,
  UserGenderName,
  UserPhone,
} from '@app/models';
import './styles.scss';
import { mapRecordToSelectOption } from '@app/helpers';

export interface UserInfoData {
  lastName: string;
  firstName: string;
  middleName: string;
  phone: string;
  gender: SelectOption<UserGender> | null;
  address: string;
  instagram: string;
  telegram: string;
  whatsAppNumber: string | null;
  phones: UserPhone[];
  iin: string;
  kato: string;
  familyStatus: SelectOption<UserFamilyStatus> | null;
  password: string | null;
  oldPassword: string | null;
  confirmPassword: string | null;
}

interface UserInfoFormProps {
  values: UserInfoData;
  errors: any;
  onChange: (value: any, name?: string) => void;
  onChangePassword?: () => void;
}

function UserInfoForm(props: UserInfoFormProps) {
  const { values, onChange, errors, onChangePassword } = props;

  const onChangePhones = useCallback(
    (index: number) => (value: string) => {
      onChange(
        values.phones.map((prevPhone, phoneIndex) => ({
          ...prevPhone,
          phone: phoneIndex === index ? value : prevPhone.phone,
        })),
        'phones'
      );
    },
    [onChange, values.phones]
  );

  const onClickAddPhone = useCallback(() => {
    onChange(
      [
        ...values.phones,
        {
          phone: '',
          isPrefer: false,
        },
      ],
      'phones'
    );
  }, [values.phones, onChange]);

  const onClickEditPassword = useCallback(() => {
    if (onChangePassword) {
      onChangePassword();
    }
  }, [onChangePassword]);

  const onCheckPreferred = useCallback(
    (index: number) => () => {
      onChange(
        values.phones.map((prevPhone, phoneIndex) => ({
          ...prevPhone,
          isPrefer: phoneIndex === index,
        })),
        'phones'
      );
    },
    [onChange, values.phones]
  );

  const onChangeKato = useCallback(
    (data: SelectOption<Kato>) => {
      onChange(data.value.nameRu, 'kato');
    },
    [onChange]
  );

  return (
    <div className="f-user-info">
      <div className="f-user-info__main">
        <TextField
          label="Фамилия"
          onChange={onChange}
          name="lastName"
          value={values.lastName}
          placeholder="Введите Фамилию"
          helperText={errors.lastName}
          error={!!errors.lastName}
          required={true}
        />
        <TextField
          label="Имя"
          onChange={onChange}
          name="firstName"
          value={values.firstName}
          placeholder="Введите имя"
          helperText={errors.firstName}
          error={!!errors.firstName}
          required={true}
        />
        <TextField
          label="Отчество"
          onChange={onChange}
          name="middleName"
          value={values.middleName}
          placeholder="Введите отчество"
          helperText={errors.middleName}
          error={!!errors.middleName}
          required={true}
        />
        <TextField
          label="ИИН"
          onChange={onChange}
          name="iin"
          value={values.iin}
          placeholder="Введите ИИН"
          mask="############"
          helperText={errors.iin}
          error={!!errors.iin}
          required={true}
        />
        <Select
          label="Выберите пол"
          value={values.gender}
          placeholder="Выберите пол"
          options={[UserGender.MALE, UserGender.FAMALE].map((gender) =>
            mapRecordToSelectOption(gender, UserGenderName)
          )}
          name="gender"
          onChange={onChange}
          helperText={errors.gender}
          error={!!errors.gender}
          required={true}
        />
        <Select
          label="Семейное положение"
          value={values.familyStatus}
          placeholder="Выберите семейное положение"
          options={[UserFamilyStatus.NOT_MARRIED, UserFamilyStatus.MARRIED].map(
            (familyStatus) =>
              mapRecordToSelectOption(familyStatus, UserFamilyStatusNames)
          )}
          name="familyStatus"
          onChange={onChange}
          helperText={errors.familyStatus}
          error={!!errors.familyStatus}
          required={true}
        />
        <TextField
          label="Номер телефона (Основной)"
          value={values.phone}
          placeholder="Введите ИИН"
          mask="(###) ### ## ##"
          required={true}
          editable={false}
        />
      </div>
      <div className="f-user-info__main-container">
        {values.phones.map((phone, phoneIndex) => (
          <Fragment key={phoneIndex.toString()}>
            <div className="f-user-info__main-phone">
              <TextField
                label={`Дополнительный номер телефона ${phoneIndex + 1}`}
                onChange={onChangePhones(phoneIndex)}
                value={phone.phone}
                mask="(###) ### ## ##"
                placeholder="(777) 777 77 77"
              />
              <Checkbox
                label={'Предпочитаемый способ связи'}
                checked={phone.isPrefer}
                onChange={onCheckPreferred(phoneIndex)}
              />
            </div>
          </Fragment>
        ))}
        <button
          onClick={onClickAddPhone}
          type="button"
          className="f-user-info__main-btn"
        >
          <IconPlusCircle24 /> Добавить дополнительный номер
        </button>
        <button
          onClick={onClickEditPassword}
          type="button"
          className="f-user-info__main-btn"
        >
          <IconEdit16 />{' '}
          {values.password === null
            ? 'Изменить пароль'
            : 'Сохранить новый пароль'}
        </button>
      </div>
      <div className="f-user-info__main-address">
        <TextField
          label="адрес"
          onChange={onChange}
          name="address"
          value={values.address}
          placeholder="Введите адрес"
        />
      </div>
      <div className="f-user-info__main-address">
        <KatoSelectTrigger
          onChange={onChangeKato}
          label="регион"
          name="kato"
          value={values.kato}
          placeholder="Выберите регион"
          helperText={errors.kato}
          error={!!errors.kato}
        />
      </div>
      <div className="f-user-info__main-bottom">
        <TextField
          label="Номер WhatsApp"
          onChange={onChange}
          name="whatsAppNumber"
          value={values.whatsAppNumber || ''}
          mask="(###) ### ## ##"
          placeholder="(777) 777 77 77"
        />
        <TextField
          label="instagram"
          onChange={onChange}
          name="instagram"
          value={values.instagram || ''}
          placeholder="Введите instagram"
        />
        <TextField
          label="telegram"
          onChange={onChange}
          name="telegram"
          value={values.telegram || ''}
          placeholder="Введите telegram"
        />
        <TextField
          label="Старый пароль"
          onChange={onChange}
          secureTextEntry={true}
          value={values.oldPassword || ''}
          placeholder="ХХХХХХХХХХХ"
          name="oldPassword"
          autoComplete="off"
          editable={values.password !== null}
          helperText={errors.oldPassword}
          error={!!errors.oldPassword}
        />
        <TextField
          label="Новый пароль"
          onChange={onChange}
          secureTextEntry={true}
          value={values.password || ''}
          placeholder="ХХХХХХХХХХХ"
          name="password"
          autoComplete="off"
          editable={values.password !== null}
          helperText={errors.password}
          error={!!errors.password}
        />
        <TextField
          label="Подтверждение пароля"
          onChange={onChange}
          secureTextEntry={true}
          value={values.confirmPassword || ''}
          placeholder="ХХХХХХХХХХХ"
          name="confirmPassword"
          autoComplete="off"
          editable={values.password !== null}
          helperText={errors.confirmPassword}
          error={!!errors.confirmPassword}
        />
      </div>
    </div>
  );
}

export default memo(UserInfoForm);
