import React, { memo, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface GridWrapperProps {
  gap?: number;
  columns?: number;
}

interface Props extends PropsWithChildren, GridWrapperProps {}

const GridStyled = styled.div<GridWrapperProps>`
  display: grid;
  grid-gap: ${(props) => `${props.gap}px`};
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
`;

function Grid(props: Props) {
  const { gap = 16, columns = 1, children } = props;

  return (
    <GridStyled gap={gap} columns={columns}>
      {children}
    </GridStyled>
  );
}

export default memo(Grid);
