import React, { memo, useCallback, useEffect, useState } from 'react';
import { LayoutDefault } from '@app/layouts';
import { Loader, StyledContainer } from '@app/components';
import { Vehicle } from '@app/models';
import { useParams } from 'react-router-dom';
import { getVehicleById } from '@app/api';
import { AuthorSection, VehicleInfo, WelcomeBanner } from '@app/common';
import styled from 'styled-components';

interface Props {}

const Grid = styled(StyledContainer)`
  display: grid;
  grid-gap: 16px;
`;

const StyledLoader = styled.div`
  text-align: center;
  margin: 16px 0;
`;

function VehiclePage(props: Props) {
  const { id } = useParams<'id'>();
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const getData = useCallback(async () => {
    try {
      const vehicleResponse = await getVehicleById(id as string);

      setVehicle(vehicleResponse);
      setError(false);
      setPending(false);
    } catch (e) {
      setError(true);
      setPending(false);
    }
  }, [id]);

  const findCompany = vehicle?.owner?.companies?.find(
    (val) => val.id === vehicle?.companyId
  );

  useEffect(() => {
    getData();
  }, []);

  return (
    <LayoutDefault>
      <WelcomeBanner />
      {pending && (
        <StyledLoader>
          <Loader />
        </StyledLoader>
      )}
      {!!vehicle && (
        <Grid>
          <VehicleInfo vehicle={vehicle} />
          <AuthorSection author={vehicle.owner} company={findCompany} />
        </Grid>
      )}
    </LayoutDefault>
  );
}

export default memo(VehiclePage);
