import React, { memo } from 'react';
import { TenderCategory } from '@app/models';
import { PageProfileTenderSearch } from '../index';

interface Props {}

function PageProfileSuppliers(props: Props) {
  return (
    <PageProfileTenderSearch
      tenderCategory={TenderCategory.SUPPLIER}
      title="Материалы"
    />
  );
}

export default memo(PageProfileSuppliers);
