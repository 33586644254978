import { memo, PropsWithChildren } from 'react';

import './styles.scss';

interface AccordionProps {
  className?: string;
}

function Accordion(props: PropsWithChildren<AccordionProps>) {
  const { className = '', children } = props;

  return <div className={`b-accordion ${className}`.trim()}>{children}</div>;
}

export default memo(Accordion);
