import { memo, ReactNode } from 'react';
import { IconEmpty } from '@app/icons';
import './styles.scss';

interface EmptyProps {
  title?: string | React.ReactNode;
  icon?: ReactNode;
}

function Empty(props: EmptyProps) {
  const { title = '', icon } = props;

  return (
    <div className="empty">
      {icon ?? <IconEmpty />}
      <p className="empty__title">{title}</p>
    </div>
  );
}

export default memo(Empty);
