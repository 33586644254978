import { IconCloseArrow } from '@app/icons';
import NotificationItem from 'components/NotificationItem';
import { FC, forwardRef, memo, useEffect, useRef } from 'react';
import styles from './NotificationContainer.module.scss';
import { useNotificationFromDBContext } from '@app/providers';
import { Empty } from '@app/components';

interface INotificationContainer {
  onClose: () => void;
}

const NotificationContainer: FC<INotificationContainer> = forwardRef(
  (props, ref) => {
    const { onClose } = props;
    const { notifications } = useNotificationFromDBContext();
    const popoverRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const handler = (e: any) => {
        if (!popoverRef.current?.contains(e.target)) {
          onClose();
        }
      };

      document.addEventListener('click', handler);

      return () => document.removeEventListener('click', handler);
    }, [popoverRef, onClose]);

    return (
      <div ref={popoverRef} className={styles.notificationContainer}>
        <div className={styles.head}>
          <span className={styles.title}>Уведомления</span>
          <button className={styles.close} onClick={onClose}>
            <IconCloseArrow />
          </button>
        </div>
        <div className={styles.main}>
          <ul className={styles.notificationLists}>
            {notifications?.map((notification, index) => {
              return (
                <NotificationItem notification={notification} key={index} />
              );
            })}
            {notifications.length === 0 && (
              <Empty title={'У вас пока нет никаких уведомлений'} />
            )}
          </ul>
        </div>
      </div>
    );
  }
);

export default memo(NotificationContainer);
