import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import './styles.scss';

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
}

function Switch(props: Props) {
  const { checked, onChange, className = '' } = props;

  const onChangeCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked);
    },
    [onChange]
  );

  return (
    <label
      className={classNames(`b-switch ${className}`.trim(), {
        'b-switch--checked': checked,
      })}
    >
      <input
        className="b-switch__input"
        type="checkbox"
        onChange={onChangeCheckbox}
        checked={checked}
      />
    </label>
  );
}

export default memo(Switch);
