import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button, Container } from '@app/components';
import Panel1Img from './1.png';
import Panel2Img from './2.png';
import Panel3Img from './3.png';
import Panel4Img from './4.png';
import { useUserContext } from '@app/providers';
import { ModalTenderCreate } from '@app/modals';
import { TenderCategory, UserType } from '@app/models';
import './styles.scss';

function WelcomeBanner() {
  const { user, showSignInModal, company } = useUserContext();
  const [tenderCategory, setTenderCategory] = useState<TenderCategory>(
    TenderCategory.CONTRACTOR
  );
  const [modalTenderCreateVisible, setModalTenderCreateVisible] =
    useState<boolean>(false);

  const panels = useMemo(
    () => [
      {
        title: 'СМР',
        text: 'Поиск подряда для Вас и Вашей компании',
        src: Panel1Img,
        tenderCategory: TenderCategory.CONTRACTOR,
      },
      {
        title: 'Поставка материалов',
        text: 'Выберите подходящий материал',
        src: Panel2Img,
        tenderCategory: TenderCategory.SUPPLIER,
      },
      {
        title: 'Аренда спецтехники',
        text: 'Выберите подходящую спецтехнику',
        src: Panel3Img,
        tenderCategory: TenderCategory.VEHICLE,
      },
      {
        title: 'Исполнители на любые работы',
        text: 'Выберите подходящих специалистов',
        src: Panel4Img,
        tenderCategory: TenderCategory.WORKER,
      },
    ],
    []
  );

  const onClickPanel = useCallback(
    (category: TenderCategory) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!user.authenticated) {
        showSignInModal();

        return;
      }

      setTenderCategory(category);
      setModalTenderCreateVisible(true);
    },
    [showSignInModal, user]
  );

  const closeModalTenderCreate = useCallback(() => {
    setModalTenderCreateVisible(false);
  }, []);

  return (
    <>
      <div className="banner-welcome">
        <Container className="banner-welcome__container">
          <p className="banner-welcome__title">
            Сократи время на поиск субподрядной организации, строительной
            бригады или специалиста
          </p>
          <p className="banner-welcome__excerpt">
            Детальные предложения от подрядчиков, размещение заказов, выбор
            подходящих исполнителей
          </p>
          <div className="banner-welcome__panels">
            {panels.map((panel, panelIndex) => (
              <div
                key={panelIndex.toString()}
                className="banner-welcome__panel"
              >
                <div>
                  <img src={panel.src} alt="" />
                </div>
                <div>
                  <p>{panel.title}</p>
                  <p>{panel.text}</p>
                  <Button
                    text="Подать заявку"
                    disabled={
                      !!user &&
                      user.type === UserType.INDIVIDUAL &&
                      [0, 1].indexOf(panelIndex) > -1
                    }
                    onClick={onClickPanel(panel.tenderCategory)}
                  />
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <ModalTenderCreate
        companyId={!!company ? company.id : null}
        visible={modalTenderCreateVisible}
        closeModal={closeModalTenderCreate}
        tenderCategory={tenderCategory}
      />
    </>
  );
}

export default memo(WelcomeBanner);
