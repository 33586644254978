import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Loader } from '@app/components';
import { IconPlusCircle24 } from '@app/icons';
import { ModalReview } from '@app/modals';
import Comment from '../../pages/Profile/components/Comment';
import { Review } from '@app/models';
import { getReviews } from '../../api/reviews';
import { PageableParams } from '@app/api';
import { fullName } from '@app/helpers';
import { format } from 'date-fns';
import { useUserContext } from '@app/providers';

interface Props {
  userId?: string;
  companyId?: string;
  vehicleId?: string;
  showButton: boolean;
}

const StyledReviews = styled.div``;

const StyledLoader = styled.div`
  text-align: center;
  padding-top: 24px;
  box-sizing: border-box;
`;

const StyledReviewsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function Reviews(props: Props) {
  const { userId, vehicleId, companyId, showButton } = props;
  const { user, company } = useUserContext();
  const [pending, setPending] = useState<boolean>(true);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [isReviewed, setIsReviewed] = useState<boolean>(false);

  const onShowModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const getData = useCallback(async () => {
    try {
      const params: PageableParams & Partial<Review> = {
        page: 1,
        perPage: 20,
      };

      if (!!companyId) {
        params.companyId = companyId;
      }

      if (!!userId) {
        params.userId = userId;
      }

      if (!!vehicleId) {
        params.vehicleId = vehicleId;
      }

      const response = await getReviews(params);
      const findReview = response.data.find(
        (elem) =>
          user.id === elem.authorId ||
          (company && company.id === elem.authorCompanyId)
      );

      if (findReview) setIsReviewed(true);

      setReviews(response.data);
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [company, companyId, user.id, userId, vehicleId]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (pending) {
    return (
      <StyledLoader>
        <Loader />
      </StyledLoader>
    );
  }

  return (
    <StyledReviews>
      {showButton && !isReviewed && (
        <StyledReviewsHeader>
          <Button
            text="Добавить отзыв"
            icon={IconPlusCircle24}
            onClick={onShowModal}
          />
          <ModalReview
            visible={modalVisible}
            onClose={onCloseModal}
            companyId={companyId}
            userId={userId}
            vehicleId={vehicleId}
            getData={getData}
          />
        </StyledReviewsHeader>
      )}
      {reviews.map((review) => (
        <Comment
          key={review.id}
          imgSrc=""
          rating={review.rating}
          likes={false}
          comments={[{ value: review.description }]}
          author={fullName(review.author)}
          date={format(new Date(review.createdAt), 'dd.MM.yyyy')}
          theme={!!review.contractId ? review.contract!.name : null}
          organization={
            !!review.authorCompanyId ? review.authorCompany!.name : null
          }
        />
      ))}
    </StyledReviews>
  );
}

export default memo(Reviews);
