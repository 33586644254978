import React, { Fragment, memo, ReactNode, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';

interface Props {
  labels: string[];
  onChange?: (index: number) => void;
  children?: ReactNode[];
  variant?: 'default' | 'secondary';
  controls?: ReactNode[];
}

const StyledTabs = styled.div``;

const StyledHeader = styled.div`
  display: flex;
  background-color: ${theme('color.white')};
`;

const StyledHeaderControls = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 16px;
`;

const StyledTabLabels = styled.div<Pick<Props, 'variant'>>`
  display: flex;

  ${switchProp('variant', {
    default: css`
      background-color: ${theme('color.white')};
      padding: 0;
    `,
    secondary: css`
      background-color: #f5f5f5;
      padding: 4px;
      box-sizing: border-box;
      border-radius: 8px;
    `,
  })}
`;

const StyledTab = styled.button<
  Pick<Props, 'variant'> & {
    isActive: boolean;
  }
>`
  box-sizing: border-box;
  border: none;
  position: relative;
  background: ${theme('color.white')};
  outline: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0 16px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;

  ${switchProp('variant', {
    default: css`
      font-weight: 400;
      background-color: ${theme('color.white')};
      color: ${ifProp('isActive', theme('color.primary'), theme('color.gray'))};
      height: 50px;

      &::before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #3a57e8;
        position: absolute;
        bottom: 0;
        left: 0;
        display: ${ifProp('isActive', 'block', 'none')};
      }
    `,
    secondary: css`
      height: 40px;
      font-weight: 500;
      border-radius: 6px;
      overflow: hidden;
      color: ${theme('color.dark')};
      box-shadow: ${ifProp(
        'isActive',
        '0 1px 4px rgba(0, 0, 0, 0.15)',
        'none'
      )};
      background-color: ${ifProp(
        'isActive',
        theme('color.white'),
        'transparent'
      )};
    `,
  })}
`;

const StyledTabContent = styled.div`
  padding: 24px 0;
  box-sizing: border-box;
`;

function Tabs(props: Props) {
  const { labels, onChange, variant = 'default', controls, children } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);

  const onClickTab = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setTabIndex(index);

      if (!!onChange) {
        onChange(index);
      }
    },
    [onChange]
  );

  if (!labels) {
    return null;
  }

  return (
    <StyledTabs>
      <StyledHeader>
        <StyledTabLabels variant={variant}>
          {labels.map((label, labelIndex) => (
            <StyledTab
              key={labelIndex.toString()}
              onClick={onClickTab(labelIndex)}
              isActive={tabIndex === labelIndex}
              variant={variant}
            >
              {label}
            </StyledTab>
          ))}
        </StyledTabLabels>
        {!!controls && (
          <StyledHeaderControls>
            {controls.map((control, controlIndex) => (
              <Fragment key={controlIndex.toString()}>{control}</Fragment>
            ))}
          </StyledHeaderControls>
        )}
      </StyledHeader>
      {!!children &&
        children.map((tabContent, tabContentIndex) => {
          if (tabContentIndex !== tabIndex) {
            return null;
          }

          return (
            <StyledTabContent key={tabContentIndex.toString()}>
              {tabContent}
            </StyledTabContent>
          );
        })}
    </StyledTabs>
  );
}

export default memo(Tabs);
