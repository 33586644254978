import React, { memo, useCallback, useEffect, useState } from 'react';
import Select, { SelectOption } from '../Select';
import { Kato } from '@app/models';
import { getKatoList, getParentKatoList } from '@app/api';
import TextField, { TextFieldProps } from '../TextField';
import './styles.scss';
import { KatoModal } from '@app/modals';

interface KatoSelectProps {
  parentNumber?: number;
  onChange: (value: SelectOption<Kato>) => void;
}

function KatoSelect(props: KatoSelectProps) {
  const { parentNumber = null, onChange } = props;
  const [pending, setPending] = useState<boolean>(true);
  const [option, setOption] = useState<SelectOption<Kato> | null>(null);
  const [options, setOptions] = useState<SelectOption<Kato>[]>([]);

  const onChangeSelect = useCallback(
    (selectedOption: SelectOption<Kato> | null) => {
      setOption(selectedOption);
      onChange(selectedOption as SelectOption<Kato>);
    },
    [onChange]
  );

  const getData = useCallback(async () => {
    try {
      const katoList = !parentNumber
        ? await getParentKatoList()
        : await getKatoList({
            parentNumber,
          });

      setOptions(
        katoList.map((item) => ({
          label: item.nameRu,
          value: item,
        }))
      );
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [parentNumber]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  if (!pending && options.length === 0) {
    return null;
  }

  return (
    <>
      <Select<Kato>
        onChange={onChangeSelect}
        value={option}
        valueKey="id"
        label="Выберите раздел"
        placeholder="Выберите"
        options={options}
      />
      {option && (
        <KatoSelect parentNumber={option.value.number} onChange={onChange} />
      )}
    </>
  );
}

interface KatoSelectTriggerProps
  extends Omit<
    Exclude<TextFieldProps, 'mask' | 'secureTextEntry' | 'editable'>,
    'onChange'
  > {
  onChange: (value: SelectOption<Kato>, name: string) => void;
  onClickClear?: () => void;
}

export function KatoSelectTrigger(props: KatoSelectTriggerProps) {
  const {
    label,
    placeholder,
    value,
    error,
    helperText,
    onChange,
    name,
    onClickClear,
  } = props;
  const [okedModalVisible, setOkedModalVisible] = useState<boolean>(false);

  const onClickShowOkedModal = useCallback(() => {
    setOkedModalVisible(true);
  }, []);

  const onCloseOkedModal = useCallback(() => {
    setOkedModalVisible(false);
  }, []);

  const onChangeOked = useCallback(
    (option: SelectOption<Kato>) => {
      if (!!onChange) {
        onChange(option as SelectOption<Kato>, name as string);
      }
    },
    [name, onChange]
  );

  return (
    <>
      <TextField
        label={label}
        value={value}
        onClickClear={onClickClear}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        onClick={onClickShowOkedModal}
        name="region"
      />
      <KatoModal
        visible={okedModalVisible}
        onChange={onChangeOked}
        onClose={onCloseOkedModal}
      />
    </>
  );
}

export default memo(KatoSelect);
