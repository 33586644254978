import React, { memo, PropsWithChildren, useCallback } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';

export interface FilterTab {
  title: string;
  path: string;
}

interface Props {
  data: FilterTab[];
}

function FilterTabs(props: Props & PropsWithChildren) {
  const { data, children } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const onChangeTab = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      navigate(data[index].path);
    },
    [data, navigate]
  );

  return (
    <div className="smr-filter-tabs">
      <div className="smr-filter-tabs__header">
        {data.map((tab, tabIndex) => (
          <div
            className={classNames('smr-filter-tabs__item', {
              'smr-filter-tabs__item--active': location.pathname === tab.path,
            })}
            key={tabIndex.toString()}
          >
            <button
              className="smr-filter-tabs__button"
              onClick={onChangeTab(tabIndex)}
            >
              {tab.title}
            </button>
          </div>
        ))}
      </div>
      {children}
    </div>
  );
}

export default memo(FilterTabs);
