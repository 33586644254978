import React, { memo, useCallback } from 'react';
import {
  IconSuccessCircle,
  IconWarningCircle,
  IconWarningTriangle,
} from '@app/icons';
import classNames from 'classnames';
import './styles.scss';

export type NotificationVariants = 'success' | 'error' | 'primary';

export interface Props {
  message: string;
  variant: NotificationVariants;
  className?: string;
}

function Notification(props: Props) {
  const { message, className = '', variant = 'primary' } = props;

  const renderIcon = useCallback(() => {
    switch (variant) {
      case 'success':
        return <IconSuccessCircle />;
      case 'error':
        return <IconWarningTriangle />;
      default:
        return <IconWarningCircle />;
    }
  }, [variant]);

  return (
    <div
      className={classNames(`b-notification ${className}`.trim(), {
        'b-notification--error': variant === 'error',
        'b-notification--success': variant === 'success',
      })}
    >
      <div className="b-notification__icon-container">{renderIcon()}</div>
      <p className="b-notification__message">{message}</p>
    </div>
  );
}

export default memo(Notification);
