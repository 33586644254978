import Switch from 'components/Switch';
import { FC, Fragment, useCallback, useState } from 'react';
import styled from 'styled-components';
import { IPrivacy } from '../ListItems/ListItems';

const StylesItemTextContainer = styled.div`
  padding: 16px;
  /* min-height: 64px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 8px auto;
  background: #ffffff;
  border-radius: 10px;
`;

const StylesItemTopContainer = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #0a0a0a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 8px; */
`;

const StylesItemContainer = styled.article`
  /* height: 100%; */
  /* font-weight: 500;
  font-size: 16px;
  line-height: 24px; */
  padding: 16px;
  color: #0a0a0a;
  &:not(:last-child) {
    padding-bottom: 16px;
  }
`;

const StylesItemTitle = styled.strong`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a0a0a;
`;

const StylesItemNumber = styled.span`
  display: inline;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a0a0a;
`;

const StylesParagraphContainer = styled.ul`
  padding: 0;
  padding-left: 16px;
  margin: 0;
`;
const StylesParagraphList = styled.li`
  margin-top: 6px;
`;

interface IItemProps {
  privacyDate: IPrivacy;
  index: number;
  opened: boolean;
  onToggleHandler: (index: number) => void;
}

const Item: FC<IItemProps> = ({
  index,
  privacyDate,
  onToggleHandler,
  opened,
}) => {
  const [openedItem, setOpenedItem] = useState<boolean>(false);
  const onChangeSwitch = useCallback(() => {
    onToggleHandler(index);
    if (privacyDate.opened !== openedItem) {
      setOpenedItem(true);
    } else {
      setOpenedItem((prevState) => (prevState = !opened));
    }
  }, [opened, openedItem]);

  return (
    <StylesItemTextContainer className="privacy-item__container">
      <StylesItemTopContainer className="privacy-item__head">
        <StylesItemNumber>
          {index !== 0 ? `${index}.` : null} {privacyDate.title}
        </StylesItemNumber>

        <Switch
          checked={openedItem && privacyDate.opened}
          onChange={onChangeSwitch}
        />
      </StylesItemTopContainer>
      {privacyDate.opened && openedItem && (
        <>
          {privacyDate.subtitleLists.map(({ subtitle, text, paragraph }, i) => {
            return (
              <Fragment key={i}>
                <>
                  {!!paragraph ? (
                    <>
                      <StylesItemContainer className="privacy-item__content">
                        <StylesItemTitle>{paragraph.subtitle}</StylesItemTitle>
                        {/* {text} */}
                        <StylesParagraphContainer>
                          {paragraph.items.map((items, i) => (
                            <StylesParagraphList key={i}>
                              {items}
                            </StylesParagraphList>
                          ))}
                        </StylesParagraphContainer>
                      </StylesItemContainer>
                    </>
                  ) : (
                    <>
                      <StylesItemContainer className="privacy-item__content">
                        <StylesItemTitle>{subtitle}</StylesItemTitle>
                        {text}
                      </StylesItemContainer>
                    </>
                  )}
                </>
              </Fragment>
            );
          })}
        </>
      )}
    </StylesItemTextContainer>
  );
};

export default Item;
