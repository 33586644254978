import React, { memo } from 'react';
import { TenderCategory } from '@app/models';
import { PageProfileTenderSearch } from '../index';

function PageProfileSpecialists() {
  return (
    <PageProfileTenderSearch
      tenderCategory={TenderCategory.WORKER}
      title="Специалисты"
    />
  );
}

export default memo(PageProfileSpecialists);
