import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { NavigationItem } from './Navigation';

const NavLink = (props: NavigationItem) => {
  const { to: toPathname, title, icon } = props;
  const location = useLocation();
  const locationPathname = location.pathname;

  const isActive =
    locationPathname === toPathname ||
    (locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/');

  return (
    <li
      className={classNames('b-nav__item', {
        'b-nav__item--active': isActive,
      })}
    >
      <Link
        to={toPathname}
        className={classNames('b-nav__link', {
          'b-nav__link--active': isActive,
        })}
      >
        {icon}
        <span className="b-nav__text">{title}</span>
      </Link>
    </li>
  );
};

export default NavLink;
