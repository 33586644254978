import React, { ButtonHTMLAttributes, useMemo } from 'react';
import { IconType } from '@app/icons';
import './styles.scss';
import Loader from '../Loader';
import classNames from 'classnames';
import { DefaultTFuncReturn } from 'i18next';

type ButtonColors = 'default' | 'primary' | 'danger' | 'cyan';

interface ButtonProps
  extends Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    'type' | 'onClick' | 'className'
  > {
  text?: string | DefaultTFuncReturn;
  variant?: 'contained' | 'outlined' | 'text';
  icon?: IconType;
  disabled?: boolean;
  color?: ButtonColors;
  loading?: boolean;
}

function Button(props: ButtonProps) {
  const {
    type = 'button',
    onClick,
    text,
    className = '',
    variant = 'contained',
    icon: Icon,
    disabled = false,
    color = 'primary',
    loading = false,
  } = props;

  const buttonColorClassName = useMemo<string>(() => {
    switch (color) {
      case 'default':
        return 'smr-button--default';
      case 'primary':
        return 'smr-button--primary';
      case 'danger':
        return 'smr-button--danger';
      case 'cyan':
        return 'smr-button--cyan';
      default:
        return '';
    }
  }, [color]);

  const buttonVariantClassName = useMemo<string>(() => {
    switch (variant) {
      case 'contained':
        return 'smr-button--contained';
      case 'outlined':
        return 'smr-button--outlined';
      case 'text':
        return 'smr-button--text';
      default:
        return '';
    }
  }, [variant]);

  return (
    <button
      className={classNames(
        `smr-button ${buttonColorClassName} ${buttonVariantClassName} ${className}`.trim(),
        {
          'smr-button--disabled': disabled,
          'smr-button--loading': loading,
        }
      )}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading && (
        <div className="smr-button__loader">
          <Loader size="small" color="default" />
        </div>
      )}
      {!!Icon && (
        <span className="smr-button__icon">
          <Icon />
        </span>
      )}
      {!!text && <span className="smr-button__text">{text}</span>}
    </button>
  );
}

export default Button;
