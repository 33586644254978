import { IconMap } from '@app/icons';
import { memo } from 'react';
import styles from './SelectLanguage.module.scss';
const SelectLanguage = memo(() => {
  return (
    <div className={styles.container}>
      <IconMap />
      <span className={styles.language}>ru</span>
    </div>
  );
});

export default SelectLanguage;
