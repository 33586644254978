import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Loader, TextField } from '@app/components';
import { SignInData, checkUserExists } from '@app/api';
import { AxiosError } from 'axios';
import './styles.scss';
import styled from 'styled-components';
import { IconArrowBack, IconCloseArrow } from '@app/icons';
import Logo from './logo.png';
import { useForm } from 'forms/hooks';
import { setMask } from '@app/helpers';
interface ISignInButton {
  active: boolean;
}

const StylesContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
`;

const StylesBackButtom = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: #f2f8fd;
  padding: 16px 8px;
  width: 99px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3a57e8;
  border-radius: 8px;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 32px;
  svg {
    margin-right: 4px;
  }
`;

const StylesTop = styled.div`
  margin-bottom: 24px;
`;

const StylesMainContainer = styled.div`
  width: 100%;
`;

const StylesImage = styled.img`
  width: 30px;
  height: 40px;
`;

const StylesTitle = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #424242;
`;

const StylesLogoContainer = styled.div`
  display: flex;
`;

const StylesCompanyNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const StylesCompanyName = styled.h2`
  font-weight: 500;
  font-size: 20.7945px;
  line-height: 130%;
  margin: 0;
  padding: 0;
  color: #0a0a0a;
`;
const StylesCompanySlogan = styled.p`
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.25px;
  color: #757575;
  margin: 0;
  padding: 0;
`;

const StylesLink = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #3a57e8;
  text-align: center;

  display: inline;

  margin-top: 22px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const StylesSignInButton = styled.button<ISignInButton>`
  width: 100%;
  background-color: ${(props) => (!props.disabled ? '#3A57E8' : '#d8d8d8')};
  color: ${(props) => (!props.disabled ? '#ffff' : '#989898')};
  height: 52px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  &:focus {
    background-color: #3a57e8da;
  }
`;

const StylesInputContainer = styled.div`
  margin: 24px 0;
  max-height: 112px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
`;

const StylesLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StylesCloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  right: 32px;
`;

interface SignInFormProps {
  onSubmit: (data: SignInData) => Promise<boolean>;
  pending: boolean;
  onClickSignUp: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onCurrentStepHandler: (step: number) => void;
  currentStep: number;
  onCancel?: () => void;
}

export interface UserFormData {
  phone: string;
  password: string;
}

const SignInForm: FC<SignInFormProps> = ({
  onSubmit,
  pending,
  onClickSignUp,
  onCurrentStepHandler,
  currentStep,
  onCancel,
}) => {
  const [disabledBtnNext, setDisabledBtnNext] = useState<boolean>(true);
  const [disabledBtnSignIn, setDisabledBtnSignIn] = useState<boolean>(true);
  const phoneRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const { values, onChange, errors, validate, setErrors } =
    useForm<UserFormData>({
      phone: '',
      password: '',
    });

  const onCheckPhoneNumber = async () => {
    try {
      const exists = await checkUserExists({ phone: values.phone, email: '' });
      if (exists.phone) {
        onCurrentStepHandler(1);
      } else {
        onCurrentStepHandler(0);
        setErrors({
          phone: 'Не корректный номер',
        });
      }
    } catch (error) {}
  };
  const onSubmitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        if (currentStep === 1) {
          const data: SignInData = {
            phone: values.phone,
            password: values.password,
          };
          await onSubmit(data);
        }
      } catch (e) {
        const error = e as AxiosError<{
          message: string;
        }>;

        if (error.response) {
          const { message } = error.response.data;
          setErrors({
            password: message,
          });
        }
      }
    },
    [onSubmit, setErrors, values, currentStep]
  );

  useEffect(() => {
    if (currentStep === 0) {
      phoneRef.current?.focus();
    }
    if (currentStep === 1) {
      passwordRef.current?.focus();
    }
  }, [currentStep, values]);

  useEffect(() => {
    if (values.phone.length <= 9) {
      setDisabledBtnNext(true);
    } else {
      setDisabledBtnNext(false);
    }

    if (values.password.length <= 0) {
      setDisabledBtnSignIn(true);
    } else {
      setDisabledBtnSignIn(false);
    }

    if (values.phone.length === 0) {
      setErrors({
        phone: '',
      });
    }
  }, [setDisabledBtnNext, values, setDisabledBtnSignIn, setErrors]);

  return (
    <StylesContainer>
      <StylesTop>
        {currentStep !== 0 && (
          <StylesBackButtom onClick={() => onCurrentStepHandler(0)}>
            <IconArrowBack />
            Назад
          </StylesBackButtom>
        )}

        <StylesLogoContainer className="signin-container__logo-container">
          <StylesImage src={Logo} className={'logo  signin-container__logo'} />
          <StylesCompanyNameContainer className="signin-container__title">
            <StylesCompanyName className="signin-container__name">
              SMR
            </StylesCompanyName>
            <StylesCompanySlogan className="signin-container__slogan">
              Поиск подряда и поставщиков
            </StylesCompanySlogan>
          </StylesCompanyNameContainer>
          {!!onCancel && (
            <StylesCloseBtn onClick={onCancel}>
              <IconCloseArrow />
            </StylesCloseBtn>
          )}
        </StylesLogoContainer>
      </StylesTop>
      <StylesMainContainer>
        <StylesTitle className="signin-container__text">
          {currentStep === 1
            ? `${setMask(values.phone, '(###) ### ## ##')}`
            : 'Введите номер'}
        </StylesTitle>
        <form onSubmit={onSubmitForm}>
          <StylesInputContainer>
            {currentStep !== 1 ? (
              <>
                <TextField
                  label="Номер телефона"
                  editable={!pending}
                  onChange={onChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  value={values.phone}
                  name="phone"
                  mask="(###) ### ## ##"
                  placeholder="(777) 777 77 77"
                  variant="modern"
                  ref={phoneRef}
                />
              </>
            ) : (
              <TextField
                label="Пароль"
                editable={!pending}
                onChange={onChange}
                error={!!errors.password}
                helperText={errors.password}
                value={values.password}
                name="password"
                placeholder="XXXXXXXXXXXX"
                secureTextEntry={true}
                variant="modern"
                ref={passwordRef}
              />
            )}
          </StylesInputContainer>
          {currentStep === 1 && (
            <StylesSignInButton
              className="signin-container__btn"
              active={true}
              type="submit"
              disabled={disabledBtnSignIn || pending}
            >
              {pending ? <Loader size="small" color="primary" /> : 'Войти'}
            </StylesSignInButton>
          )}
        </form>

        <StylesLinkContainer>
          {currentStep === 0 && (
            <StylesSignInButton
              type="button"
              onClick={onCheckPhoneNumber}
              className="signin-container__btn"
              active={true}
              disabled={disabledBtnNext || pending}
              // z-index={0}
            >
              {pending ? <Loader size="small" color="primary" /> : 'Продолжить'}
            </StylesSignInButton>
          )}
          {currentStep === 1 ? (
            <StylesLink onClick={() => onCurrentStepHandler(2)}>
              Забыли пароль?
            </StylesLink>
          ) : (
            <StylesLink onClick={onClickSignUp}>Регистрация</StylesLink>
          )}
        </StylesLinkContainer>
      </StylesMainContainer>
    </StylesContainer>
  );
};

export default memo(SignInForm);
