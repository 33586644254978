import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IconButton, Navigation, NavigationItem } from '../index';
import ProfileSrc from './avatar.png';
import LogoSrc from './logo.png';
import { IconNewNotification, IconPlusCircle24 } from '../../assets/icons';
import { useNotificationFromDBContext, useUserContext } from '@app/providers';
import { Link } from 'react-router-dom';
import { getCompanyTypeShortName, shotName } from '@app/helpers';
import { UserType } from '@app/models';
import { ModalTenderCreate } from '@app/modals';
import Button from '../Button';
import './styles.scss';
import SelectLanguage from 'components/SelectLanguage';
import { ProfilesCard } from '@app/common';
import Container from '../Container';
import styled from 'styled-components';
import classNames from 'classnames';
import NotificationContainer from '../NotificationContainer';
import { getNotificationsForUser } from '@app/api';
import { ProfileAvatar } from '../../pages/Profile/components';

interface HeaderProps {
  hideNav?: boolean;
  wide?: boolean;
}

function Header(props: PropsWithChildren<HeaderProps>) {
  const { hideNav = false, wide = false } = props;
  const { user, showSignInModal, logout, company, currentProfile } =
    useUserContext();
  const { notifications, setNotifications } = useNotificationFromDBContext();
  const [profileDetailsVisible, setProfileDetailsVisible] =
    useState<boolean>(false);
  const [notificationsVisible, setNotificationsVisible] =
    useState<boolean>(false);
  const [modalTenderCreateVisible, setModalTenderCreateVisible] =
    useState<boolean>(false);
  const navigationItems = useMemo<NavigationItem[]>(
    () => [
      // { title: 'Новости', to: '/news' },
      // { title: 'Вопросы-ответы', to: '/' },
      // { title: 'Избранное', to: '/' },
      // { title: 'Контакты', to: '/' },
    ],
    []
  );
  const getNotification = useCallback(async () => {
    const response = await getNotificationsForUser({ isRead: false });
    setNotifications(response);
  }, [setNotifications, user.id]);

  useEffect(() => {
    getNotification();
  }, []);

  const onClickProfile = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setNotificationsVisible(false);

      setProfileDetailsVisible(
        (prevProfileDetailsVisible) => !prevProfileDetailsVisible
      );
    },
    []
  );

  const onClickNotification = useCallback(() => {
    setProfileDetailsVisible(false);
    setNotificationsVisible((notificationsVisible) => !notificationsVisible);
  }, []);

  const onClickCloseNotification = useCallback(() => {
    setNotificationsVisible(false);
  }, []);

  const onClickLogin = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      showSignInModal();
    },
    [showSignInModal]
  );

  const onClickLogout = useCallback(() => {
    logout();
  }, [logout]);

  const onClickCreateTender = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (user.authenticated) {
        setModalTenderCreateVisible(true);

        return;
      }

      showSignInModal();
    },
    [showSignInModal, user]
  );

  const closeModalTenderCreate = useCallback(() => {
    setModalTenderCreateVisible(false);
  }, []);

  return (
    <>
      <header className="b-header">
        <Container
          className={classNames('b-header__container', {
            'b-header__container--wide': wide,
          })}
        >
          <div className="b-header__left">
            <Link to="/" className="b-header__logo logo">
              <img src={LogoSrc} alt="" className="logo__image" />
              <div className="logo__content">
                <span className="b-header__text">SMR</span>
                <span className="b-header__text b-header__text--small">
                  Поиск подряда и поставщиков
                </span>
              </div>
            </Link>
            <ButtonContainer>
              <Button
                text="Создать объявление"
                onClick={onClickCreateTender}
                color={'default'}
                icon={IconPlusCircle24}
                className={'b-header__left-btn'}
              />
            </ButtonContainer>

            {!hideNav && <Navigation items={navigationItems} />}
          </div>
          <div className="b-header__right">
            <div className="b-header__controls">
              {user.authenticated && (
                <>
                  <IconButton
                    icon={IconNewNotification}
                    onClick={onClickNotification}
                    count={notifications.length}
                  />
                  {notificationsVisible && (
                    <NotificationContainer onClose={onClickCloseNotification} />
                  )}
                </>
              )}
              <SelectLanguage />
              {user.authenticated && (
                <>
                  <button
                    className="b-header__profile"
                    onClick={onClickProfile}
                  >
                    <ProfileAvatar
                      className="img"
                      customPlaceholder={ProfileSrc}
                      size={45}
                      avatarFiles={
                        !!currentProfile.avatarFilesIds
                          ? currentProfile.avatarFilesIds.split(',')[0]
                          : ''
                      }
                    />
                    {user && (
                      <div>
                        <p>{shotName(user)}</p>
                        <p>
                          {currentProfile.currentType === 'company' &&
                            user.type === UserType.ENTITY &&
                            !!company &&
                            `${getCompanyTypeShortName(company.type)} ${
                              company.name
                            }`}
                        </p>
                      </div>
                    )}
                  </button>
                  {profileDetailsVisible && (
                    <ProfilesCard
                      setProfileDetailsVisible={setProfileDetailsVisible}
                    />
                  )}
                </>
              )}
              {!user.authenticated && (
                <button className="b-header__action" onClick={onClickLogin}>
                  Войти
                </button>
              )}
            </div>
          </div>
        </Container>
      </header>
      <ModalTenderCreate
        companyId={!!company ? company.id : null}
        visible={modalTenderCreateVisible}
        closeModal={closeModalTenderCreate}
      />
    </>
  );
}

const ButtonContainer = styled.div`
  @media (max-width: 640px) {
    display: none;
  }
`;

export default memo(Header);
