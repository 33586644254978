import React, { memo, useCallback, useEffect, useState } from 'react';
import { moneyFormat, onlyDigit } from '@app/helpers';
import {
  Button,
  Checkbox,
  FilePicker,
  Grid,
  Paper,
  TextField,
} from '../../components';
import { createReply } from '@app/api';
import { useNotification, useUserContext } from '@app/providers';
import * as yup from 'yup';
import styled from 'styled-components';
import { Reply, Tender } from '@app/models';
import { useForm } from '../hooks';
import { useTranslation } from 'react-i18next';

interface ReplyFormProps {
  tender: Tender | null;
  onSubmit: (reply?: Reply) => void;
}

interface Requirement {
  id: string;
  name: string;
  comment: string;
  checked: boolean;
}

interface ReplyFormData {
  price: string;
  description: string;
  files: File[];
  requirements: Requirement[];
}

const StyledFormWrapper = styled.form`
  max-height: 600px;
  overflow-y: auto;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 16px;
`;

const StyledRequirement = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
`;

function ReplyForm(props: ReplyFormProps) {
  const { tender, onSubmit } = props;
  const { company } = useUserContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const { values, onChange, validate, errors, setValues } =
    useForm<ReplyFormData>({
      price: '',
      description: '',
      files: [],
      requirements: [],
    });
  const [pending, setPending] = useState(false);
  const onChangePrice = useCallback(
    (value: string) => {
      onChange(onlyDigit(value), 'price');
    },
    [onChange]
  );

  const onChangeRequirement = useCallback(
    (requirementId: string) => (checked: boolean) => {
      onChange(
        values.requirements.map((requirement) => ({
          ...requirement,
          checked:
            requirement.id === requirementId ? checked : requirement.checked,
        })),
        'requirements'
      );
    },
    [onChange, values.requirements]
  );

  const onChangeRequirementComment = useCallback(
    (requirementId: string) => (comment: string) => {
      onChange(
        values.requirements.map((requirement) => ({
          ...requirement,
          comment:
            requirement.id === requirementId ? comment : requirement.comment,
        })),
        'requirements'
      );
    },
    [onChange, values.requirements]
  );

  const onChangeFiles = useCallback(
    (files: File[]) => {
      onChange(files, 'files');
    },
    [onChange]
  );

  const onSubmitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        const { files, ...restValues } = values;

        const hasErrors = await validate(
          yup.object().shape({
            price: yup.string().required(t('required', { ns: 'validation' })!),
            description: yup
              .string()
              .required(t('required', { ns: 'validation' })!),
          })
        );

        if (hasErrors) {
          return;
        }

        setPending(true);

        const data: any = {
          ...restValues,
          tenderId: tender!.id,
          requirements: restValues.requirements.map((requirement) =>
            JSON.stringify({
              requirementId: requirement.id,
              comment: requirement.comment,
              checked: requirement.checked,
            })
          ),
        };

        if (!!company) {
          data.companyId = company.id;
        }

        const reply = await createReply(data, files);

        setPending(false);

        onSubmit(reply);

        showNotification({
          message: 'Отклик был успешно отправлен',
          variant: 'success',
        });
      } catch (e) {
        setPending(false);

        showNotification({
          message:
            'Ошибка не удалось отправить отклик. Обратитесь к администратору',
          variant: 'error',
        });
      }
    },
    [t, company, values, validate, tender, onSubmit, showNotification]
  );

  useEffect(() => {
    if (!!tender) {
      setValues({
        requirements: tender.requirements.map((requirement) => ({
          id: requirement.id,
          name: requirement.name,
          comment: '',
          checked: false,
        })),
      });
    }
  }, [setValues, tender]);

  return (
    <StyledFormWrapper onSubmit={onSubmitForm}>
      <Grid>
        <Paper title="Данные отклика">
          <StyledGrid>
            <TextField
              value={values.description}
              onChange={onChange}
              name="description"
              label="Краткое содержание"
              placeholder="Описание документа"
              error={!!errors.description}
              helperText={errors.description}
              required={true}
            />
            <TextField
              value={moneyFormat(values.price)}
              onChange={onChangePrice}
              label="Сумма"
              placeholder="Введите сумму"
              error={!!errors.price}
              helperText={errors.price}
              required={true}
            />
          </StyledGrid>
        </Paper>
        {!!tender && (
          <Paper title="Требования">
            <Grid>
              {values.requirements.map((requirement) => (
                <StyledRequirement key={requirement.id}>
                  <Checkbox
                    label={requirement.name}
                    onChange={onChangeRequirement(requirement.id)}
                    checked={requirement.checked}
                  />
                  <TextField
                    value={requirement.comment}
                    onChange={onChangeRequirementComment(requirement.id)}
                    name="description"
                    label="Комментарий"
                    placeholder="Комментарий по отклику"
                  />
                </StyledRequirement>
              ))}
            </Grid>
          </Paper>
        )}
        <Paper>
          <FilePicker value={values.files} onChange={onChangeFiles} />
        </Paper>
        <StyledFooter>
          <Button
            text="Отправить"
            type="submit"
            disabled={pending}
            loading={pending}
          />
        </StyledFooter>
      </Grid>
    </StyledFormWrapper>
  );
}

export default memo(ReplyForm);
