import instance from './instance';
import { Portfolio } from '../models';

export function createPortfolio(data: Partial<Portfolio>): Promise<Portfolio> {
  return instance()
    .post('/portfolios/create', data)
    .then((response) => response.data);
}

export function getPortfolios(
  params: Partial<Portfolio>
): Promise<Portfolio[]> {
  return instance()
    .get('/portfolios/getAll', {
      params,
    })
    .then((response) => response.data);
}

export function getPortfoliosByCompanyId(
  companyId: string
): Promise<Portfolio[]> {
  return instance()
    .get(`/portfolios/getByCompanyId/${companyId}`)
    .then((response) => response.data);
}

export function getPortfoliosByUserId(userId: string): Promise<Portfolio[]> {
  return instance()
    .get(`/portfolios/getByUserId/${userId}`)
    .then((response) => response.data);
}
