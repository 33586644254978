import React, { memo, useCallback } from 'react';
import { Modal, ModalProps } from '../../components';
import { ReplyForm } from '../../forms';
import { Reply, Tender } from '@app/models';

interface ReplyModalProps extends Omit<ModalProps, 'onClose'> {
  tender: Tender | null;
  onClose: (reply?: Reply) => void;
}

function ReplyModal(props: ReplyModalProps) {
  const { visible, tender, onClose } = props;

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      title={`Откликнуться на ${tender ? tender.name : ''}`}
      visible={visible}
      onClose={closeModal}
      size="large"
    >
      <ReplyForm tender={tender} onSubmit={onClose!} />
    </Modal>
  );
}

export default memo(ReplyModal);
