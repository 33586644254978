import React, {
  Fragment,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { IconChevronLeft, IconEye } from '@app/icons';
import { dateFormat, moneyFormat } from '@app/helpers';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Vehicle, VehicleStatusNames } from '@app/models';
import placeholderImage from '../../assets/images/vehicle_fallback.png';
import {
  FilePicker,
  Info,
  LoadableImage,
  StarRating,
  Tabs,
} from '@app/components';
import Reviews from '../Reviews/Reviews';
import { useUserContext } from '@app/providers';

interface Props {
  vehicle: Vehicle;
  actions?: ReactNode[];
  viewReplies?: boolean;
  editable?: boolean;
}

const RequirementsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-top: -16px;
`;

const RequirementStyled = styled.div`
  background: #f5f5f5;
  border-radius: 10px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 16px;
  margin-left: 16px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #0a0a0a;
    max-width: 500px;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const StyledVehicleInfo = styled.section`
  display: grid;
  grid-gap: 16px;
`;

const StyledHeader = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const StyledBackButton = styled.button`
  width: 48px;
  height: 48px;
  background: #ededed;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: #3a57e8;
  margin-right: 16px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const StyledName = styled.p`
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -1px;
  margin: 0;
  max-width: 300px;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledStatus = styled.p`
  background: #25aa51;
  border-radius: 4px;
  padding: 6px 8px;
  color: #fff;
  margin: 0 0 0 16px;
`;

const StyledContent = styled.div`
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
`;

const StyledContentGrid = styled.div`
  display: flex;
`;

const StyledImageWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 400px;
  height: 400px;
  margin-right: 64px;
`;

const StyledInfo = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: min-content;
`;

const StyledPrice = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  color: #3a57e8;
  width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledViews = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #757575;
  display: flex;
  align-items: center;
  margin: 8px 0 16px;

  > span {
    margin-left: 8px;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button + button {
    margin-left: 8px;
  }
`;

const StyledTypeCategoryContainer = styled.div`
  margin-left: 25px;
`;

const StyledContentContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
`;

const StyledList = styled.li`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 10px;
  }

  color: #ababab;
`;

const StyledText = styled.p`
  margin: 0 0 0 5px;
  padding: 0;
  color: #707070;
`;

function VehicleInfo(props: Props & PropsWithChildren) {
  const {
    vehicle,
    actions = [],
    viewReplies = false,
    editable = false,
  } = props;
  const { user: authUser } = useUserContext();
  const navigate = useNavigate();

  const onClickBack = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      navigate(-1);
    },
    [navigate]
  );

  const vehicleImages = useMemo(() => {
    if (!vehicle.imagesIds) {
      return [];
    }

    return vehicle.imagesIds.split(',');
  }, [vehicle.imagesIds]);

  const tabLabels = useMemo(() => {
    const returnTabLabels = ['Общая информация'];
    if (authUser.authenticated) {
      returnTabLabels.push('Документы и Файлы');
    }
    return [...returnTabLabels, 'Отзывы'];
  }, [authUser.authenticated]);

  const tabContent = useMemo<ReactNode[]>(() => {
    const returnTabContent = [
      <StyledInfo>
        <Info label="Регион" value={vehicle.kato.nameRu} />
        {/* <Info label="Сфера деятельности" value={vehicle.oked?.nameRu} />*/}
        <Info label="Описание" value={vehicle.description} />
      </StyledInfo>,
    ];

    if (authUser.authenticated) {
      returnTabContent.push(
        <FilePicker
          loadableFiles={!!vehicle.filesIds ? vehicle.filesIds.split(',') : []}
          onChange={() => ''}
          editable={editable}
        />
      );
    }

    return [
      ...returnTabContent,
      <Reviews
        vehicleId={vehicle.id}
        showButton={vehicle.ownerId !== authUser.id}
      />,
    ];
  }, [authUser, editable, vehicle]);

  return (
    <StyledVehicleInfo>
      <StyledHeader>
        <StyledBackButton onClick={onClickBack}>
          <IconChevronLeft />
        </StyledBackButton>
        <StyledName>{vehicle.name}</StyledName>
        {vehicle.status && (
          <StyledStatus>{VehicleStatusNames[vehicle.status]}</StyledStatus>
        )}
        <StyledTypeCategoryContainer>
          <StyledContentContainer>
            <StyledList>
              Тип:
              <StyledText>Предложение</StyledText>
            </StyledList>
            <StyledList>
              Категория:
              <StyledText>Спецтехника</StyledText>
            </StyledList>
          </StyledContentContainer>
        </StyledTypeCategoryContainer>
        {vehicle.rating && (
          <StyledList>
            Рейтинг:
            <StarRating
              rating={vehicle.rating}
              reverse={false}
              marginSize={14}
            />
          </StyledList>
        )}
      </StyledHeader>
      <StyledContent>
        <StyledContentGrid>
          <StyledImageWrapper>
            <LoadableImage
              imageIds={vehicleImages}
              placeholder={placeholderImage}
            />
          </StyledImageWrapper>
          <StyledInfo>
            <Info label="Номер объявления" value="Не указан" />
            <Info
              label="Дата публикации"
              value={dateFormat(vehicle.createdAt)}
            />
            <Info
              label="Стоимость"
              value={<StyledPrice>{moneyFormat(vehicle.price)} ₸</StyledPrice>}
            />
          </StyledInfo>
        </StyledContentGrid>
        <StyledViews>
          <IconEye />
          <span>Просмотрели объявление: 15</span>
        </StyledViews>
        <Tabs labels={tabLabels}>
          {tabContent.map((elem, index) => {
            return <Fragment key={index}>{elem}</Fragment>;
          })}
        </Tabs>
        <StyledFooter>
          {actions.map((action, actionIndex) => (
            <Fragment key={actionIndex}>{action}</Fragment>
          ))}
        </StyledFooter>
      </StyledContent>
    </StyledVehicleInfo>
  );
}

export default memo(VehicleInfo);
