import React, { memo, useCallback } from 'react';
import { Modal, ModalProps } from '@app/components';
import { Reply } from '@app/models';
import { ReplyDetail } from '@app/common';
import styled from 'styled-components';
import { fullName } from '@app/helpers';

interface Props extends Omit<ModalProps, 'onClose'> {
  reply: Reply | null;
  onClose: (reply?: Reply) => void;
  onChange?: (id: string, isChange: boolean) => void;
}

const StyledModalContent = styled.div`
  margin: -16px -16px -24px;
  padding: 24px;
  box-sizing: border-box;
  max-height: 650px;
  overflow-y: auto;
`;

function ModalReplyDetail(props: Props) {
  const { visible, reply, onClose, onChange } = props;

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const onUpdateReply = useCallback(
    (reply: Reply) => {
      onClose(reply);

      if (!!onChange) {
        onChange(reply.id, !reply.pinned);
      }
    },
    [onChange, onClose]
  );

  if (!visible || !reply) {
    return null;
  }

  return (
    <Modal
      title={`Автор отклика: ${fullName(reply.owner)}`}
      visible={visible}
      onClose={closeModal}
      size="large"
    >
      <StyledModalContent>
        <ReplyDetail reply={reply} onUpdate={onUpdateReply} />
      </StyledModalContent>
    </Modal>
  );
}

export default memo(ModalReplyDetail);
