import instance from './instance';
import { PageableResponse } from './responses';
import { Favorite } from '@app/models';
import { PageableParams } from './params';

export function getFavorites(
  params: PageableParams
): Promise<PageableResponse<Favorite>> {
  return instance()
    .get('/favorites/getAll', {
      params,
    })
    .then((response) => response.data);
}

export function checkFavorite(data: Partial<Favorite>): Promise<boolean> {
  return instance()
    .post('/favorites/isFavorite', data)
    .then((response) => response.data);
}

export function addToFavorites(data: Partial<Favorite>) {
  return instance()
    .post('/favorites/add', data)
    .then((response) => response.data);
}

export function deleteFromFavorites(data: Partial<Favorite>) {
  return instance()
    .post('/favorites/delete', data)
    .then((response) => response.data);
}
