import React, { Component, Fragment, ReactNode } from 'react';
import { PageableParams, PageableResponse } from '@app/api';
import { genericMemo } from '@app/helpers';
import classNames from 'classnames';
import { Empty, Loader, Pagination } from '@app/components';
import styled from 'styled-components';
import { prop, theme } from 'styled-tools';
import { IconGrid, IconInline } from '@app/icons';
import { EventEmitter } from '../../EventEmitter';
import './styles.scss';

export interface RenderFilterProps<T> {
  onSubmit: (data: T) => void;
  pending: boolean;
}

interface Props<T, F> {
  getData: (params: PageableParams, filter?: F) => Promise<PageableResponse<T>>;
  renderItem: (item: T, horizontal?: boolean) => ReactNode;
  initialFilter?: F;
  renderFilter?: (filterProps: RenderFilterProps<F>) => ReactNode;
  horizontal?: boolean;
  variant?: 'default' | 'paper';
  emptyIcon?: ReactNode;
  emptyTitle?: string | React.ReactNode;
  columns?: number;
  title?: string | ReactNode;
  controls?: ReactNode[];
  viewControl?: boolean;
  refreshEventName?: string;
  gap?: number;
}

interface State<T, F> {
  pending: boolean;
  error: boolean;
  viewInline: boolean;
  page: number;
  totalPages: number;
  totalItems: number;
  filter: F;
  items: T[];
}

const StyledHeader = styled.div`
  box-sizing: border-box;
  padding: 24px 16px 16px;
  background-color: ${theme('color.white')};
  border-radius: 10px;
`;

const StyledHeaderGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledTitle = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${theme('color.dark')};
  margin: 0;
`;

const StyledControls = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledPaginationWrapper = styled.div`
  margin-top: 16px;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 16px;
  box-sizing: border-box;
`;

const StyledGrid = styled.div<{
  columns: number;
  gap: number;
}>`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(${prop('columns')}, 1fr);
  grid-gap: ${prop('gap')}px;

  @media (max-width: 980px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;

class SearchTemplate<T extends { id: string }, F = unknown> extends Component<
  Props<T, F>,
  State<T, F>
> {
  eventEmitter: Function | null = null;

  state: State<T, F> = {
    pending: true,
    error: false,
    viewInline: Boolean(this.props.horizontal),
    page: 1,
    totalPages: 0,
    totalItems: 0,
    filter: this.props.initialFilter || ({} as F),
    items: [],
  };

  async componentDidMount() {
    const { refreshEventName } = this.props;

    await this.getData();

    if (refreshEventName) {
      this.eventEmitter = EventEmitter.subscribe(refreshEventName, async () => {
        await this.getData();
      });
    }
  }

  componentWillUnmount() {
    if (this.eventEmitter) {
      this.eventEmitter();
    }
  }

  getData = async () => {
    try {
      const { page, filter } = this.state;
      const { data, meta } = await this.props.getData(
        {
          page,
          perPage: 6,
        },
        filter
      );

      this.setState({
        items: data,
        totalPages: meta.totalPages,
        totalItems: meta.totalItems,
        pending: false,
        error: false,
      });
    } catch (e) {
      this.setState({
        pending: true,
        error: true,
      });
    }
  };

  onClickControl =
    (value: boolean) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      this.setState({
        viewInline: value,
      });
    };

  onSubmitFilter = (filter: F) => {
    this.setState(
      {
        filter,
        items: [],
        page: 1,
        pending: true,
      },
      async () => {
        await this.getData();
      }
    );
  };

  onChangePage = (page: number) => {
    this.setState(
      {
        page,
      },
      async () => {
        await this.getData();
      }
    );
  };

  renderFilter = () => {
    const { renderFilter } = this.props;
    const { pending } = this.state;

    if (!renderFilter) {
      return null;
    }

    return renderFilter({
      onSubmit: this.onSubmitFilter,
      pending,
    });
  };

  render() {
    const {
      renderFilter,
      renderItem,
      variant,
      emptyIcon,
      emptyTitle,
      columns = 3,
      title,
      controls,
      viewControl = true,
      gap = 16,
    } = this.props;
    const { pending, viewInline, items, page, totalPages, totalItems } =
      this.state;

    return (
      <>
        {!!title || !!controls || !!renderFilter ? (
          <StyledHeader>
            {(!!title || !!controls) && (
              <StyledHeaderGrid>
                {!!title && <StyledTitle>{title}</StyledTitle>}
                {!!controls && (
                  <StyledControls>
                    {controls.map((control, controlIndex) => (
                      <Fragment key={controlIndex.toString()}>
                        {control}
                      </Fragment>
                    ))}
                  </StyledControls>
                )}
              </StyledHeaderGrid>
            )}
            {this.renderFilter()}
          </StyledHeader>
        ) : null}
        <div
          className={classNames('template-search', {
            'template-search--paper': variant === 'paper',
          })}
        >
          {viewControl && (
            <div className="template-search__controls">
              <button
                onClick={this.onClickControl(false)}
                className={classNames('template-search__control', {
                  'template-search__control--active': !viewInline,
                })}
              >
                <IconGrid />
              </button>
              <button
                onClick={this.onClickControl(true)}
                className={classNames('template-search__control', {
                  'template-search__control--active': viewInline,
                })}
              >
                <IconInline />
              </button>
            </div>
          )}
          {pending && (
            <div className="template-search__loader">
              <Loader />
            </div>
          )}
          {!pending && items.length > 0 && (
            <StyledGrid
              columns={viewInline ? 1 : columns}
              gap={columns === 3 ? gap : 24}
            >
              {items.map((item) => (
                <Fragment key={item.id}>
                  {renderItem(item, viewInline)}
                </Fragment>
              ))}
            </StyledGrid>
          )}
          {!pending && items.length === 0 && (
            <div className="template-search__empty">
              <Empty title={emptyTitle} icon={emptyIcon} />
            </div>
          )}
          <StyledPaginationWrapper>
            <Pagination
              initialPage={page}
              totalPages={totalPages}
              totalItems={totalItems}
              onChange={this.onChangePage}
            />
          </StyledPaginationWrapper>
        </div>
      </>
    );
  }
}

export default genericMemo(SearchTemplate);
