import React, { useCallback } from 'react';
import { Modal, ModalProps } from '@app/components';
import { Company, User, UserType } from '@app/models';
import { ProfileCompany, ProfileUser } from '@app/common';
import { fullName } from '@app/helpers';
import styled from 'styled-components';

interface Props extends ModalProps {
  user: User | null;
  company?: Company | null;
}

const StyledContent = styled.div`
  overflow-y: auto;
  max-height: 700px;
  margin: -16px -16px -24px;
  padding: 24px;
  box-sizing: border-box;
`;

function ModalProfile(props: Props) {
  const { user, company, visible, onClose: propsOnClose } = props;

  const closeModal = useCallback(() => {
    if (propsOnClose) {
      propsOnClose();
    }
  }, [propsOnClose]);

  if (!visible || !user) {
    return null;
  }

  const titleModal =
    company && user.type === UserType.ENTITY ? company?.name : fullName(user);

  return (
    <Modal
      title={titleModal}
      visible={visible}
      onClose={closeModal}
      size="large"
    >
      <StyledContent>
        {company && user.type === UserType.ENTITY ? (
          <ProfileCompany company={company} editable={false} />
        ) : (
          <ProfileUser user={user} editable={false} />
        )}
      </StyledContent>
    </Modal>
  );
}

export default ModalProfile;
