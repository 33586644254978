import React, { memo, ReactNode, useCallback, useMemo } from 'react';
import { Vehicle } from '@app/models';
import {
  Card,
  CardFavoriteButton,
  CardHideActiveButton,
} from '@app/components';
import {
  getCompanyTypeShortName,
  getFormattedDate,
  shotName,
} from '@app/helpers';
import { IconCalendar, IconMapMarker } from '@app/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  activateVehicle,
  addToFavorites,
  deleteFromFavorites,
  hideVehicle,
} from '@app/api';
import { useUserContext } from '@app/providers';

interface Props {
  vehicle: Vehicle;
  horizontal?: boolean;
  onClick?: (id: string) => void;
  fallbackImage?: string;
  onChangeFavorite?: (id: string, isFavorite: boolean) => void;
  hideThumbnail?: boolean;
}

function VehicleCard(props: Props) {
  const {
    vehicle,
    horizontal,
    onClick,
    fallbackImage,
    onChangeFavorite,
    hideThumbnail,
  } = props;
  const { owner, kato, active, isFavorite, ...vehicleItem } = vehicle;
  const location = useLocation();
  const navigate = useNavigate();
  const isProfile = location.pathname.includes('profile');
  const { user, showSignInModal } = useUserContext();

  const onClickVehicle = useCallback(() => {
    if (!!onClick) {
      // onClick(vehicleItem.id);
      navigate(`/profile/vehicles/${vehicleItem.id}`); // todo: плохое решение, но я не понял как заставить onClick(id) работать
      return;
    }

    navigate(`/vehicles/${vehicleItem.id}`);
  }, [navigate, onClick, vehicleItem.id]);

  const onClickAddFavorite = useCallback(
    async (isFavorite: boolean) => {
      if (!user.authenticated) {
        showSignInModal();

        return;
      }

      isFavorite
        ? await addToFavorites({
            vehicleId: vehicle.id,
          })
        : await deleteFromFavorites({
            vehicleId: vehicle.id,
          });

      if (!!onChangeFavorite) {
        onChangeFavorite(vehicle.id, isFavorite);
      }
    },

    [onChangeFavorite, showSignInModal, vehicle.id, user.authenticated]
  );

  const onClickHideActive = useCallback(
    (isActive: boolean) => {
      if (!user.authenticated) {
        return;
      }

      isActive ? activateVehicle(vehicle.id) : hideVehicle(vehicle.id);
    },

    [vehicle.id, user.authenticated]
  );

  const actionButtons = useMemo<ReactNode[]>(() => {
    if (!vehicle) {
      return [];
    }

    const returnActionButtons = [
      <CardFavoriteButton
        onClickFavorite={onClickAddFavorite}
        isFavorite={isFavorite}
      />,
      /*    <CardReplyCount count={vehicle.replies.length} />,*/
    ];
    if (isProfile)
      returnActionButtons.push(
        <CardHideActiveButton
          onClickHideActive={onClickHideActive}
          isActive={active}
        />
      );

    return [...returnActionButtons];
  }, [
    active,
    isFavorite,
    isProfile,
    onClickAddFavorite,
    onClickHideActive,
    vehicle,
  ]);

  const authorInfo = useMemo(() => {
    const author = !!vehicle.company
      ? {
          name: `${getCompanyTypeShortName(vehicle.company.type)} ${
            vehicle.company.name
          }`,
          image: !!vehicle.company.avatarFilesIds
            ? vehicle.company.avatarFilesIds.split(',')[0]
            : '',
        }
      : {
          name: 'Физическое лицо',
          image: !!owner.avatarFilesIds
            ? owner.avatarFilesIds.split(',')[0]
            : '',
        };

    return {
      ...author,
      owner: shotName(owner),
      type: owner.type,
    };
  }, [vehicle.company, owner]);

  return (
    <Card
      imagesIds={vehicle.imagesIds ? vehicle.imagesIds.split(',') : []}
      onClick={onClickVehicle}
      fallbackImage={fallbackImage}
      horizontal={horizontal}
      title={vehicleItem.name}
      description={vehicleItem.description}
      price={vehicleItem.price}
      free={vehicleItem.status}
      publishDate={getFormattedDate(vehicleItem.createdAt)}
      actions={actionButtons}
      hideThumbnail={hideThumbnail}
      authorInfo={authorInfo}
      rating={vehicleItem.rating}
      infos={[
        {
          icon: IconMapMarker,
          text: kato.nameRu,
        },
        {
          icon: IconCalendar,
          text: `Дата публикации: ${getFormattedDate(vehicleItem.createdAt)}`,
        },
      ]}
    />
  );
}

export default memo(VehicleCard);
