import React from 'react';
import RDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import '../TextField/styles.scss';
import styles from './datePicker.module.scss';
import { IconCalendar } from '@app/icons';
import classNames from 'classnames';

registerLocale('ru', ru);
type Props = {
  value: Date | null | undefined;
  onChange: (v: Date | null, name?: string) => void;
  error?: boolean;
  label: string;
  helperText?: string | null;
  width?: number;
  name?: string;
  disabled?: boolean;
  placeholderText?: string;
  minDate?: Date;
  maxDate?: Date;
  rightIcon?: React.ReactNode;
  required?: boolean;
};

export const DatePicker: React.FC<Props> = ({
  value,
  onChange,
  error = false,
  label,
  helperText = '',
  width,
  name,
  disabled,
  placeholderText,
  maxDate,
  minDate,
  rightIcon,
  required = false,
}) => {
  return (
    <div
      // className={styles.datePicker}
      style={{ width }}
      className={`${styles.datePicker} ${error && styles.datePickerError}`}
    >
      <label className={styles.label}>
        {label}
        {required ? ' *' : ''}
      </label>
      <div className={styles.wrapper}>
        <span className={styles.icon}>
          <IconCalendar />
        </span>
        <RDatePicker
          selected={value}
          onChange={(d: any) => onChange(d, name)}
          // className={classNames('b-text-field__input datepicker', {
          //   'b-text-field__input--icon': Boolean(<IconCalendar />),
          // })}
          locale="ru"
          dateFormat="dd.MM.yyyy"
          placeholderText={placeholderText ?? 'ДД.ММ.ГГГГ'}
          name={name}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          autoComplete="off"
        />
        {/* {rightIcon && (
          <div className="b-text-field__right-icon-dp">{rightIcon}</div>
        )} */}
      </div>
      {!!helperText && <p className={styles.helperText}>{helperText}</p>}
    </div>
  );
};

export default React.memo(DatePicker);
