import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { SignInModal } from '@app/modals';
import { Company, User, UserType } from '@app/models';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useNotificationFromDBContext } from './NotificationFromDB';

interface Authenticated {
  authenticated: boolean;
}

type CurrentUser = { currentType: 'user' } & User;
type CurrentCompany = { currentType: 'company' } & Company;

type Profile = CurrentUser | CurrentCompany extends { currentType: infer T }
  ? T extends 'user'
    ? CurrentUser
    : CurrentCompany
  : never;

interface StoredUser extends Authenticated, User {}

interface UserContextValue {
  user: StoredUser;
  setUser: (user: StoredUser) => void;
  showSignInModal: () => void;
  hideSignInModal: () => void;
  logout: () => void;
  currentProfile: Profile;
  setCurrentProfile: (profile: Profile) => void;
  company: Company | null;
  setCompany: (company: Company) => void;
  updateUser: (user: Partial<StoredUser>) => void;
}

const UserContext = createContext<UserContextValue>({} as UserContextValue);

function UserProvider(props: PropsWithChildren) {
  const { children } = props;
  const navigate = useNavigate();
  const { setNotifications } = useNotificationFromDBContext();
  const [signInModalVisible, setSignInModalVisible] = useState<boolean>(false);
  const [company, setCompany] = useState<Company | null>(null);
  const [currentProfile, setCurrentProfile] = useState<Profile>({} as Profile);
  const [user, setUser] = useState<StoredUser>({
    authenticated: false,
  } as StoredUser);

  const showSignInModal = useCallback(() => {
    setSignInModalVisible(true);
  }, []);

  const hideSignInModal = useCallback(() => {
    setSignInModalVisible(false);
  }, []);

  const setUserData = useCallback((data: StoredUser) => {
    if (data.type === UserType.ENTITY) {
      setCompany(data.companies[0]);
      setCurrentProfile({ ...data.companies[0], currentType: 'company' });
    }

    setUser({
      ...data,
    });

    setCurrentProfile({
      ...data,
      currentType: 'user',
    } as Profile);
  }, []);

  const setCurrentProfileData = useCallback((data: Profile) => {
    Cookies.set('currentProfile', JSON.stringify(data));
    setCurrentProfile({
      ...data,
    });
  }, []);

  const updateUser = useCallback((data: Partial<StoredUser>) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...data,
    }));
  }, []);

  const logout = useCallback(() => {
    Cookies.remove('accessToken');
    Cookies.remove('currentUserType');
    Cookies.remove('currentProfile');

    setNotifications([]);

    setUser({
      authenticated: false,
    } as StoredUser);

    navigate('/');
  }, [setNotifications, navigate]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser: setUserData,
        showSignInModal,
        hideSignInModal,
        logout,
        company,
        setCompany,
        updateUser,
        currentProfile,
        setCurrentProfile: setCurrentProfileData,
      }}
    >
      <>
        {children}
        {!user.authenticated && <SignInModal visible={signInModalVisible} />}
      </>
    </UserContext.Provider>
  );
}

export function useUserContext(): UserContextValue {
  return useContext(UserContext);
}

export { UserProvider, UserContext };
