import {
  IconArrowLeft,
  IconComments,
  IconLikes,
  IconSendMessage,
  IconShares,
} from '@app/icons';
import { useNavigate } from 'react-router-dom';
import styles from './NewsCardPage.module.scss';
import image from '../news.jpg';
import { memo } from 'react';
const NewsCardPage = memo(() => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.back} onClick={() => navigate(-1)}>
          <IconArrowLeft />
        </button>
        <span className={styles.headerTitle}>Вам подарок!</span>
        <p className={styles.date}>12.01.2022</p>
      </div>
      <div className={styles.main}>
        <div className={styles.imgContainer}>
          <img className={styles.img} src={image} alt="" />
        </div>
        <div className={styles.context}>
          <span className={styles.description}>Описание</span>
          <p className={styles.text}>
            В этом декабре Дед Мороз придет не только к детям, но и к
            покупателям апартаментов в Донском квартале! Все мы помним тот
            трепет и приятное волнение ребенка, когда под новогодней елкой
            появлялись красивые коробки с заветными подарками. Предлагаем
            ненадолго вернуться в детство и впустить в свою жизнь чуточку
            волшебства. Мы с Дедом Морозом подготовили подарки для любимых
            клиентов. Все, что нужно — выбрать свой под елочкой. Мы хотим, чтобы
            в этот Новый год для вас было на одно чудо больше. С теплотой и
            заботой, ваш Донской квартал.
          </p>
          <div className={styles.info}>
            <span className={styles.comments}>
              <IconComments /> 20
            </span>
            <span className={styles.shares}>
              <IconShares />
              20
            </span>
            <span className={styles.likes}>
              <IconLikes />
              100
            </span>
          </div>
        </div>

        <div className={styles.comments}>
          <div className={styles.input}>
            <label className={styles.label} htmlFor="">
              комментарий
            </label>
            <div className={styles.inputContainer}>
              <input
                className={styles.textInput}
                type="text"
                placeholder="Введите текст"
              />
              <IconSendMessage />
            </div>
          </div>

          <div className={styles.commentContainer}>
            <div className={styles.commentContext}>
              <span className={styles.comment}>Комментарий</span>
              <p className={styles.text}>Вау, классно!!!</p>
            </div>
            <div className={styles.userInfo}>
              <div className={styles.userContainer}>
                <img className={styles.userImg} src={image} alt="" />
                <ul className={styles.userInfoContainer}>
                  <li className={styles.userName}>Автор</li>
                  <li className={styles.firstName}>
                    Каримов Алишер Махмутович
                  </li>
                </ul>
              </div>
              <div className={styles.buttons}>
                <button className={styles.commentBtn}>
                  <IconComments />
                </button>
                <button className={styles.likeBtn}>
                  <IconLikes />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NewsCardPage;
