import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Loader, TextField } from '@app/components';
import { changePassword, getAuthCode, SignInData } from '@app/api';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import { IconArrowBack } from '@app/icons';
import Logo from './logo.png';
import { setMask } from '@app/helpers';
import { useForm } from 'forms/hooks';
import { ChangeUserPassword } from '@app/models';
import * as yup from 'yup';
import { t } from 'i18next';
interface ISignInButton {
  active: boolean;
}

const StylesContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
`;

const StylesBackButtom = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: #f2f8fd;
  padding: 16px 8px;
  width: 99px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3a57e8;
  border-radius: 8px;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 32px;
  svg {
    margin-right: 4px;
  }
`;

const StylesTop = styled.div`
  margin-bottom: 24px;
`;

const StylesMainContainer = styled.div`
  width: 100%;
`;

const StylesImage = styled.img`
  width: 30px;
  height: 40px;
`;

const StylesTitle = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #424242;
`;

const StylesLogoContainer = styled.div`
  display: flex;
`;

const StylesCompanyNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const StylesCompanyName = styled.h2`
  font-weight: 500;
  font-size: 20.7945px;
  line-height: 130%;
  margin: 0;
  padding: 0;
  color: #0a0a0a;
`;
const StylesCompanySlogan = styled.p`
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.25px;
  color: #757575;
  margin: 0;
  padding: 0;
`;

const StylesLink = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #3a57e8;
  text-align: center;

  display: inline;

  margin-top: 22px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const StylesErroMessage = styled.span`
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: red;
`;

const StylesSignInButton = styled.button<ISignInButton>`
  width: 100%;
  background-color: ${(props) => (!props.disabled ? '#3A57E8' : '#d8d8d8')};
  color: ${(props) => (!props.disabled ? '#ffff' : '#989898')};
  height: 52px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const StylesInputContainer = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
`;

const StylesLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StylesEmailNotification = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 0;
  line-height: 20px;
  width: 100%;
  text-align: center;
`;
interface SignInFormProps {
  onSubmit: (data: SignInData) => Promise<boolean>;
  pending: boolean;
  onCurrentStepHandler: (step: number) => void;
  currentStep: number;
}

export interface UserFormData {
  pinCode: string;
  phone: string;
  repeatPassword: string;
  newPassword: string;
}

const RestorePassword: FC<SignInFormProps> = ({
  onSubmit,
  pending,
  onCurrentStepHandler,
  currentStep,
}) => {
  const [disabledBtnNext, setDisabledBtnNext] = useState(true);
  const [disabledBtRestore, setDisabledBtnRestore] = useState(true);
  const [step, setStep] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const phoneRef = useRef<HTMLInputElement | null>(null);
  const [sendText, setSendText] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const { values, onChange, errors, validate, setErrors } =
    useForm<UserFormData>({
      pinCode: '',
      phone: '',
      newPassword: '',
      repeatPassword: '',
    });

  const onCheckout = useCallback(async () => {
    try {
      setLoader(true);
      const data = await getAuthCode(values.phone);
      setLoader(false);
      if (!!data) {
        setStep(1);
        onChange('', 'pinCode');
        onChange('', 'newPassword');
        onChange('', 'repeatPassword');
        setShowErrorMessage('');
        setSendText(data.message);
        setErrors({
          repeatPassword: '',
        });
      }
    } catch (e) {
      const error = e as AxiosError<{
        message: string;
      }>;
      if (error.response) {
        const { message } = error.response.data;
        setErrors({
          phone: message,
        });
        setLoader(false);
      }
    }
  }, [values, setErrors, setStep, onChange]);

  const onSubmitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        const schema = yup.object().shape({
          pinCode: yup
            .string()
            .required(t('required', { ns: 'validation' })!)
            .min(4)
            .max(4),
          phone: yup
            .string()
            .required(t('required', { ns: 'validation' })!)
            .length(10),
          newPassword: yup
            .string()
            .required(t('required', { ns: 'validation' })!),
          repeatPassword: yup
            .string()
            .oneOf(
              [yup.ref('newPassword')],
              t('passwordConfirmation', { ns: 'validation' })!
            ),
        });

        const hasErrors = await validate(schema);

        if (hasErrors) {
          return;
        }

        const data: ChangeUserPassword = {
          user: {
            phone: values.phone,
            password: values.newPassword,
          },
          code: values.pinCode,
        };

        await changePassword(data);
        onSubmit({
          phone: values.phone,
          password: values.newPassword,
        });
      } catch (e) {
        const error = e as AxiosError<{
          message: string;
        }>;
        if (error.response) {
          const { message } = error.response.data;
          setShowErrorMessage(message);
        }
      }
    },
    [changePassword, onSubmit, values, validate]
  );
  useEffect(() => {
    if (currentStep === 2) {
      phoneRef.current?.focus();
    }
  }, [currentStep]);
  useEffect(() => {
    if (values.phone.length <= 9) {
      setDisabledBtnNext(true);
    } else {
      setDisabledBtnNext(false);
    }
    if (
      values.newPassword.length <= 0 ||
      values.repeatPassword.length <= 0 ||
      values.pinCode.length !== 4
    ) {
      setDisabledBtnRestore(true);
    } else {
      setDisabledBtnRestore(false);
    }
  }, [setDisabledBtnNext, setDisabledBtnRestore, values]);
  return (
    <StylesContainer>
      <StylesTop>
        {step === 0 ? (
          <StylesBackButtom onClick={() => onCurrentStepHandler(0)}>
            <IconArrowBack />
            Назад
          </StylesBackButtom>
        ) : (
          <StylesBackButtom onClick={() => setStep(0)}>
            <IconArrowBack />
            Назад
          </StylesBackButtom>
        )}

        <StylesLogoContainer className="signin-container__logo-container">
          <StylesImage src={Logo} className={'logo  signin-container__logo'} />
          <StylesCompanyNameContainer className="signin-container__title">
            <StylesCompanyName className="signin-container__name">
              SMR
            </StylesCompanyName>
            <StylesCompanySlogan className="signin-container__slogan">
              Поиск подряда и поставщиков
            </StylesCompanySlogan>
          </StylesCompanyNameContainer>
        </StylesLogoContainer>
      </StylesTop>
      <StylesMainContainer>
        <StylesTitle className="signin-container__text">
          {/* {step === 1
            ? `${setMask(values.phone, '(###) ### ## ##')}`
            : 'Введите номер'} */}
          {step === 1 ? (
            <StylesEmailNotification>{sendText}</StylesEmailNotification>
          ) : (
            'Введите номер'
          )}
        </StylesTitle>
        <form onSubmit={onSubmitForm}>
          <StylesInputContainer>
            {step === 1 ? (
              <>
                <TextField
                  label=""
                  editable={!pending}
                  onChange={onChange}
                  error={!!errors.pinCode}
                  helperText={errors.pinCode}
                  value={values.pinCode}
                  name="pinCode"
                  placeholder="Пин-код"
                  mask="####"
                  secureTextEntry={true}
                  variant="modern"
                />
                <TextField
                  label=""
                  editable={!pending}
                  onChange={onChange}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword}
                  value={values.newPassword}
                  name="newPassword"
                  placeholder="Новый пароль"
                  secureTextEntry={true}
                  variant="modern"
                />
                <TextField
                  label=""
                  editable={!pending}
                  onChange={onChange}
                  error={!!errors.repeatPassword}
                  helperText={errors.repeatPassword}
                  value={values.repeatPassword}
                  name="repeatPassword"
                  placeholder="Подтверждение пароля"
                  secureTextEntry={true}
                  variant="modern"
                />
                {showErrorMessage.length !== 0 ? (
                  <StylesErroMessage>{showErrorMessage}</StylesErroMessage>
                ) : null}
              </>
            ) : (
              <TextField
                label="Номер телефона"
                editable={!pending}
                onChange={onChange}
                error={!!errors.phone}
                helperText={errors.phone}
                value={values.phone}
                name="phone"
                mask="(###) ### ## ##"
                placeholder="(777) 777 77 77"
                variant="modern"
                ref={phoneRef}
              />
            )}
          </StylesInputContainer>
          {step === 1 && (
            <StylesSignInButton
              className="signin-container__btn"
              active={true}
              disabled={disabledBtRestore || pending}
            >
              {pending ? (
                <Loader size="small" color="primary" />
              ) : (
                'Восстановить'
              )}
            </StylesSignInButton>
          )}
        </form>
        <StylesLinkContainer>
          {step === 0 && (
            <StylesSignInButton
              onClick={onCheckout}
              className="signin-container__btn"
              active={true}
              disabled={disabledBtnNext || loader}
            >
              {loader ? <Loader size="small" color="primary" /> : 'Продолжить'}
            </StylesSignInButton>
          )}
        </StylesLinkContainer>
      </StylesMainContainer>
    </StylesContainer>
  );
};

export default memo(RestorePassword);
