import React, { memo, useCallback, useEffect, useState } from 'react';
import Select, {
  SelectOption,
  SelectProps,
} from '../../../../components/Select';
import { getAllTypes } from '@app/api';

interface Props
  extends Pick<SelectProps<string>, 'value' | 'onChange' | 'name'> {}

const defaultOption: SelectOption<string> = {
  label: 'Все',
  value: 'all',
};

function VehicleTypeSelect(props: Props) {
  const { onChange, name, value } = props;
  const [options, setOptions] = useState<SelectOption[]>([]);

  const onChangeSelect = useCallback(
    (option: SelectOption | null) => {
      onChange(option!.value === 'all' ? null : option, name);
    },
    [name, onChange]
  );

  const getData = useCallback(async () => {
    try {
      setOptions([]);

      const typesResponse = await getAllTypes();

      setOptions([
        defaultOption,
        ...typesResponse.map((type) => ({
          label: type,
          value: type,
        })),
      ]);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  return (
    <>
      <Select<string>
        onChange={onChangeSelect}
        value={value}
        label="вид техники"
        placeholder="Выберите"
        options={options}
      />
    </>
  );
}

export default memo(VehicleTypeSelect);
