import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { Company, User } from '@app/models';
import { fullName, getCompanyTypeShortName, setMask } from '@app/helpers';
import { ifProp, theme } from 'styled-tools';
import { IconCheckCircle24, IconHourGlass24 } from '@app/icons';

interface Props {
  company: Company | null;
  user: User | null;
  signed: boolean;
  signedText: string;
}

const StyledSigner = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const StyledInfo = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledLabel = styled.p`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 8px 0 0;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 2px;
    width: 100%;
    height: 0;
    border-bottom: 2px dotted ${theme('color.gray')};
  }

  span {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${theme('color.gray')};
    background: inherit;
    display: inline;
    z-index: 1;
    padding-right: 8px;
    position: relative;
  }
`;

const StyledText = styled.p`
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  margin: 0;
`;

const StyledStatus = styled.div<{
  signed: boolean;
}>`
  display: flex;
  gap: 2px;
  align-items: center;
  color: ${ifProp('signed', theme('color.dark'), theme('color.gray'))};

  svg {
    color: ${ifProp('signed', theme('color.green'), theme('color.gray'))};
  }
`;

function Signer(props: Props) {
  const { signed, company, user, signedText } = props;

  const signerInfo = useMemo(() => {
    if (!!company) {
      return `${company.bin} - ${getCompanyTypeShortName(company.type)} ${
        company.name
      }`;
    }

    return `${user!.iin} - Физическое лицо`;
  }, [company, user]);

  const signerFullName = useMemo(() => {
    if (!!company) {
      return fullName(company.owner);
    }

    return fullName(user!);
  }, [company, user]);

  const signerPhone = useMemo(() => {
    if (!!company) {
      return setMask(company.owner.phone, '+7 (###) ### ## ##');
    }

    return setMask(user!.phone, '+7 (###) ### ## ##');
  }, [company, user]);

  return (
    <StyledSigner>
      <StyledInfo>
        <StyledLabel>
          <span>{signerInfo}</span>
        </StyledLabel>
        <StyledStatus signed={signed}>
          <StyledText>{signed ? signedText : 'На подписании'}</StyledText>
          {signed ? <IconCheckCircle24 /> : <IconHourGlass24 />}
        </StyledStatus>
      </StyledInfo>
      <StyledInfo>
        <StyledLabel>
          <span>Ф.И.О</span>
        </StyledLabel>
        <StyledText>{signerFullName}</StyledText>
      </StyledInfo>
      <StyledInfo>
        <StyledLabel>
          <span>Номер телефона</span>
        </StyledLabel>
        <StyledText>{signerPhone}</StyledText>
      </StyledInfo>
    </StyledSigner>
  );
}

export default memo(Signer);
