import i18n from 'i18next';
import ruCommonJSON from './ru/common.json';
import ruValidationJSON from './ru/validation.json';
import ruFavoriteJSON from './ru/favorite.json';
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next/initReactI18next';

export type I18NLanguages = 'ru';

export const defaultNS = 'common';

export const resources = {
  ru: {
    common: ruCommonJSON,
    validation: ruValidationJSON,
    favorite: ruFavoriteJSON,
  },
} as const;

(async () => {
  await i18n.use(initReactI18next).init({
    lng: Cookies.get('locale'),
    fallbackLng: 'ru',
    resources,
    interpolation: {
      escapeValue: false,
    },
    defaultNS,
    keySeparator: false,
  });
})();

export default i18n;
