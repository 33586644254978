import React, { memo, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PageHome } from './pages';
import { useUserContext } from '@app/providers';
import Cookies from 'js-cookie';
import { getUserById } from '@app/api';
import { parseJwt } from './helpers';
import { Loader } from './components';
import styled from 'styled-components';
import { AppRoutesUserAuth, routeConfigByType } from './config';

const StylesLoaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

function Router() {
  const { user, setUser, setCurrentProfile } = useUserContext();
  const [pending, setPending] = useState<boolean>(true);
  let location = useLocation();

  useEffect(() => {
    (async () => {
      const accessToken = Cookies.get('accessToken');
      const currentProfile = Cookies.get('currentProfile');

      if (!accessToken) {
        setPending(false);

        return;
      }

      const userResponse = await getUserById(parseJwt(accessToken).id);

      setUser({
        authenticated: !!accessToken,
        ...userResponse,
      });
      if (currentProfile) {
        setCurrentProfile(JSON.parse(currentProfile));
      }

      setPending(false);
    })();
  }, [setCurrentProfile, setUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (pending) {
    return (
      <StylesLoaderCenter>
        <Loader />
      </StylesLoaderCenter>
    );
  }

  return (
    <Routes>
      {Object.values(
        routeConfigByType[
          user.authenticated
            ? AppRoutesUserAuth.AUTH
            : AppRoutesUserAuth.NOT_AUTH
        ]
      ).map(({ element, path }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path="*" element={<PageHome />} />
    </Routes>
  );
}

export default memo(Router);
