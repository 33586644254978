import instance from './instance';
import { Notification } from '../models';
import { PageableParams } from './params';

export function getNotificationsForUser(
  params?: Partial<PageableParams> & Partial<Notification>
): Promise<Notification[]> {
  return instance()
    .get('/notifications/get-all-for-user', { params })
    .then((response) => response.data);
}

export function readNotifications(userId: string): Promise<Notification[]> {
  return instance()
    .patch('/notifications/read/' + userId)
    .then((response) => response.data);
}
