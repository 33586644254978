// import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import classNames from 'classnames';
import { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IconArrowRight, IconType } from '@app/icons';

import './styles.scss';

export interface Navigation {
  title: string;
  path: string;
  icon: IconType;
  children?: Navigation[];
}

interface SidebarProps {
  data: Navigation[];
}

const Sidebar = (props: SidebarProps) => {
  const location = useLocation();
  const { data } = props;

  return (
    <div className="b-sidebar-menu__link">
      {data.map(({ icon: Icon, ...item }, itemIndex) => {
        return (
          <NavLink
            to={item.path}
            key={itemIndex.toString()}
            className={classNames({
              'b-sidebar-menu__link--active': location.pathname === item.path,
            })}
          >
            <div className="b-sidebar-menu__link-item">
              <div>
                <Icon />
              </div>
              <p>{item.title}</p>
            </div>
            <span className="b-sidebar-menu__link-arrow">
              <IconArrowRight />
            </span>
          </NavLink>
        );
      })}
    </div>
  );
};

export default memo(Sidebar);
