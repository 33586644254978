import { IconCloseMark, IconFilePlus24, IconPdf } from '@app/icons';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './styles.scss';
import { getFileById, getFileInfo } from '@app/api';
import { DatabaseFile } from '@app/models';
import Loader from '../Loader';
import styled from 'styled-components';

interface FilePickerProps {
  title?: string;
  loadableFiles?: string[];
  value?: File[];
  onChange: (files: File[]) => void;
  accept?: string;
  editable?: boolean;
  showLoader?: boolean;
  onDelete?: (fileId: string) => Promise<boolean>;
  multiple?: boolean;
}

interface PDFFileProps {
  file: File;
  index: number;
  onDelete: (index: number) => void;
  loader?: boolean;
  onClick?: () => void;
  editable?: boolean;
}

const StyledLoadableFileLoader = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`;

function PDFFile(props: PDFFileProps) {
  const {
    file,
    index,
    onDelete,
    loader = false,
    onClick,
    editable = true,
  } = props;

  const onClickDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onDelete(index);
    },
    [onDelete, index]
  );

  return (
    <div className="b-file-picker__file" onClick={onClick}>
      <div>
        <IconPdf />
        <p>
          {index + 1} {file.name}
        </p>
      </div>
      <div>
        {editable && (
          <button onClick={onClickDelete}>
            <IconCloseMark />
          </button>
        )}
      </div>
      {loader && (
        <StyledLoadableFileLoader>
          <Loader size="small" />
        </StyledLoadableFileLoader>
      )}
    </div>
  );
}

interface ImageFileProps extends PDFFileProps {}

export const ImageFile = forwardRef<HTMLImageElement, ImageFileProps>(
  (props, ref) => {
    const {
      file,
      index,
      onDelete,
      onClick = () => {},
      loader = false,
      editable = true,
    } = props;

    const onClickDelete = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        onDelete(index);
      },
      [onDelete, index]
    );

    return (
      <div className="b-file-picker__file" key={file.name} onClick={onClick}>
        <div>
          <img src={URL.createObjectURL(file)} alt="" ref={ref} />
        </div>
        <div>
          {editable && (
            <button onClick={onClickDelete}>
              <IconCloseMark />
            </button>
          )}
        </div>
        {loader && (
          <StyledLoadableFileLoader>
            <Loader size="small" />
          </StyledLoadableFileLoader>
        )}
      </div>
    );
  }
);

interface LoadableFileProps {
  fileId: string;
  index: number;
  onDelete?: (fileId: string) => Promise<boolean>;
  editable?: boolean;
}

export function LoadableFile(props: LoadableFileProps) {
  const { fileId, index, onDelete, editable = true } = props;
  const [pending, setPending] = useState<boolean>(true);
  const [file, setFile] = useState<DatabaseFile | null>(null);
  const [fileData, setFileData] = useState(new Blob());

  const getImage = useCallback(async () => {
    try {
      if (!fileId) {
        return;
      }

      const fileData = await getFileById(fileId);
      const fileInfo = await getFileInfo(fileId);

      setFile(fileInfo);
      setFileData(fileData);

      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [fileId]);

  const onDeleteFile = useCallback(async () => {
    if (!!onDelete) {
      setPending(true);

      await onDelete(fileId);

      setPending(false);
    }
  }, [fileId, onDelete]);

  const onClickFile = useCallback(async () => {
    const downloadLink = document.createElement('a');

    const blobFile = new Blob([fileData], { type: 'application/octet-stream' });

    downloadLink.href = URL.createObjectURL(blobFile);
    downloadLink.setAttribute('download', file!.fileName);

    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  }, [file, fileData]);

  useEffect(() => {
    getImage();
  }, [getImage]);

  if (!file) {
    return null;
  }

  const fileNameArr = file.fileName.split('.');

  if (fileNameArr[fileNameArr.length - 1] === 'pdf') {
    return (
      <PDFFile
        file={{ name: file.fileName } as File}
        onDelete={onDeleteFile}
        index={index}
        loader={pending}
        onClick={onClickFile}
        editable={editable}
      />
    );
  }

  return (
    <ImageFile
      file={fileData as File}
      index={index}
      loader={pending}
      onDelete={onDeleteFile}
      onClick={onClickFile}
      editable={editable}
    />
  );
}

function FilePicker(props: FilePickerProps) {
  const {
    value = [],
    onChange,
    accept = 'image/jpg, image/jpeg, image/png, application/pdf',
    loadableFiles = [],
    editable = true,
    showLoader = false,
    onDelete,
    multiple = false,
  } = props;
  const fileInput = useRef<HTMLInputElement>(null);
  const onClickChoose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      fileInput.current?.click();
    },
    []
  );

  const onChangeFileInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputFiles = e.target.files;
      const files: File[] = [];

      if (!inputFiles) {
        return;
      }

      for (let i = 0; i < inputFiles.length; i++) {
        if (!value.find((file) => file.name === inputFiles[i].name)) {
          files.push(inputFiles[i]);
        }
      }
      onChange([...value, ...files]);
      e.target.value = '';
    },
    [value, onChange]
  );

  const onClickDeleteFile = useCallback(
    (fileIndex: number) => {
      onChange(value.filter((item, itemIndex) => fileIndex !== itemIndex));
    },
    [onChange, value]
  );

  return (
    <div className="b-file-picker">
      {showLoader && (
        <div className="b-file-picker__loader">
          <Loader />
        </div>
      )}
      <input
        type="file"
        ref={fileInput}
        multiple={multiple}
        onChange={onChangeFileInput}
        accept={accept}
      />
      <div className="b-file-picker__files">
        {editable && (
          <div className="b-file-picker__header">
            <button onClick={onClickChoose} className="b-file-picker__trigger">
              <div>
                <IconFilePlus24 />
                <span>Добавить файл</span>
              </div>
            </button>
          </div>
        )}
        <div className="b-file-picker__filelists">
          {loadableFiles.map((loadableFile, loadableFileIndex) => (
            <LoadableFile
              index={loadableFileIndex}
              fileId={loadableFile}
              key={loadableFileIndex.toString()}
              onDelete={onDelete}
              editable={editable}
            />
          ))}
          {value.map((file, fileIndex) => {
            if (file.type === 'application/pdf') {
              return (
                <PDFFile
                  key={fileIndex.toString()}
                  file={file}
                  index={fileIndex}
                  onDelete={onClickDeleteFile}
                  editable={editable}
                />
              );
            }

            return (
              <ImageFile
                key={fileIndex.toString()}
                file={file}
                index={fileIndex}
                onDelete={onClickDeleteFile}
                editable={editable}
              />
            );
          })}
        </div>
      </div>
      {editable && (
        <div className="b-file-picker__footer">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M4.723 21.1675C4.71 21.1675 4.698 21.1675 4.684 21.1665C4.369 21.1505 4.06 21.0825 3.766 20.9635C2.319 20.3755 1.621 18.7225 2.208 17.2765L9.529 4.45049C9.781 3.99449 10.163 3.61249 10.629 3.35449C11.994 2.59849 13.72 3.09549 14.475 4.45949L21.748 17.1875C21.91 17.5685 21.979 17.8785 21.996 18.1945C22.035 18.9505 21.777 19.6755 21.271 20.2365C20.765 20.7975 20.07 21.1285 19.315 21.1665L4.795 21.1675H4.723Z"
              fill="#8A92A6"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.125 10.021C11.125 9.539 11.518 9.146 12 9.146C12.482 9.146 12.875 9.539 12.875 10.021V12.849C12.875 13.332 12.482 13.724 12 13.724C11.518 13.724 11.125 13.332 11.125 12.849V10.021ZM11.125 16.2701C11.125 15.7851 11.518 15.3901 12 15.3901C12.482 15.3901 12.875 15.7801 12.875 16.2591C12.875 16.7521 12.482 17.1451 12 17.1451C11.518 17.1451 11.125 16.7521 11.125 16.2701Z"
              fill="#8A92A6"
            />
          </svg>
          <p>
            Загрузите файл в формате PDF, JPEG размер файла не должен превышать
            более 30мб
          </p>
        </div>
      )}
    </div>
  );
}

export default memo(FilePicker);
