import React, { memo, useCallback, useState } from 'react';
import { Reply } from '@app/models';
import { IconEmpty } from '@app/icons';
import { SearchTemplate } from '@app/templates';
import { PageableParams, PageableResponse } from '@app/api';
import { ModalReplyDetail } from '@app/modals';
import { Reply as ReplyComponent } from '@app/common';

interface Props {
  title?: string;
  getData: (params: PageableParams) => Promise<PageableResponse<Reply>>;
}

function RepliesSearch(props: Props) {
  const { getData, title } = props;
  const [reply, setReply] = useState<Reply | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const onCloseModal = useCallback(() => {
    setReply(null);
    setModalVisible(false);
  }, []);

  const onClickReply = useCallback((item: Reply) => {
    setReply(item);
    setModalVisible(true);
  }, []);

  const renderItem = useCallback(
    (item: Reply) => {
      return <ReplyComponent reply={item} onClick={onClickReply} />;
    },
    [onClickReply]
  );

  return (
    <>
      <SearchTemplate<Reply>
        columns={1}
        renderItem={renderItem}
        getData={getData}
        title={title}
        emptyIcon={<IconEmpty />}
        viewControl={false}
        variant="paper"
      />
      <ModalReplyDetail
        visible={modalVisible}
        onClose={onCloseModal}
        reply={reply}
      />
    </>
  );
}

export default memo(RepliesSearch);
