import React, {
  Fragment,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  FilePicker,
  Paper,
  PortfolioList,
  Tabs,
} from '@app/components';
import {
  IconEdit16,
  IconEmail16,
  IconPhone16,
  IconWhatsApp16,
} from '@app/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LayoutProfile } from '@app/layouts';
import { ProfileUserAdditionalInfo, ProfileUserInfo } from './components';
import { deleteUserFile, uploadUserFiles } from '@app/api';
import { User } from '@app/models';
import { ProfileAvatar } from '../../pages/Profile/components';
import AboutUser from '../../pages/Profile/components/AboutUser';
import { ModalReport } from '@app/modals';
import { useUserContext } from '@app/providers';
import Reviews from '../Reviews/Reviews';

interface Props {
  user: User;
  updateUser?: (user: Partial<User>) => void;
  editable?: boolean;
}

const NOT_SPECIFIED = 'Не указан';

const StyledWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledReviewWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledBlockForButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  margin-top: 15px;

  > button + button,
  button + a,
  a + button {
    margin-left: 8px;
  }
`;

function ProfileUser(props: Props) {
  const { user, updateUser, editable = false } = props;
  const { user: authUser } = useUserContext();
  const navigate = useNavigate();
  const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);

  const onClickEdit = useCallback(() => {
    navigate('/profile/edit');
  }, [navigate]);

  const onChangeFiles = useCallback(
    async (files: File[]) => {
      try {
        const response = await uploadUserFiles(user!.id, files);

        if (updateUser) {
          updateUser({
            filesIds: response.filesIds,
          });
        }
      } catch (e) {}
    },
    [user, updateUser]
  );

  const onDeleteFile = useCallback(
    async (fileId: string) => {
      try {
        if (updateUser) {
          updateUser({
            filesIds: user
              .filesIds!.split(',')
              .filter((item) => item !== fileId)
              .join(','),
          });
        }

        await deleteUserFile(user.id, fileId);

        return true;
      } catch (e) {
        return false;
      }
    },
    [updateUser, user]
  );

  const onCloseReportModal = useCallback(() => {
    setReportModalVisible(false);
  }, []);

  const onClickReport = useCallback(() => {
    setReportModalVisible(true);
  }, []);

  const onClickReply = useCallback(() => {}, []);

  const leftContent = useMemo(
    () => (
      <>
        <ProfileAvatar
          avatarFiles={
            !!user.avatarFilesIds ? user.avatarFilesIds.split(',')[0] : ''
          }
          editable={false}
        />
        <AboutUser
          id={user.id}
          ratingUser={user.rating}
          views={14}
          lastDateUpdate={9}
          phone={user.phone}
          email={user.email}
          instagram={user.instagram || NOT_SPECIFIED}
          telegram={user.telegram || NOT_SPECIFIED}
          phones={user.phones}
          status={user.status}
          whatAppNumber={user.whatsAppNumber}
          editable={editable}
        />
      </>
    ),
    [
      user.avatarFilesIds,
      user.id,
      user.rating,
      user.phone,
      user.email,
      user.instagram,
      user.telegram,
      user.phones,
      user.status,
      user.whatsAppNumber,
      editable,
    ]
  );

  const actionButtons = useMemo<ReactNode[]>(() => {
    if (!user) {
      return [];
    }

    const returnActionButtons = [
      <Button text="Пожаловаться" color="danger" onClick={onClickReport} />,
      <a
        className="section-author__footer-whatsapp"
        href={`mailto:+${user.email}`}
      >
        <IconEmail16 />
      </a>,
    ];

    if (!!user.whatsAppNumber) {
      returnActionButtons.push(
        <a
          className="section-author__footer-whatsapp"
          href={`https://api.whatsapp.com/send?phone=${user.whatsAppNumber}`}
        >
          <IconWhatsApp16 />
        </a>
      );
    }

    return [
      ...returnActionButtons,
      <a
        className="section-author__footer-whatsapp"
        href={`tel:+${user.phone}`}
      >
        <IconPhone16 />
      </a>,
      // <Button
      //   text="Отправить предложение"
      //   icon={IconCheckCircle16}
      //   onClick={onClickReply}
      // />,
    ];
  }, [onClickReply, onClickReport, user]);

  const tabContent = useMemo<ReactNode[]>(() => {
    if (!user) {
      return [];
    }

    const content = [
      <StyledGrid>
        <ProfileUserAdditionalInfo user={user} />
      </StyledGrid>,
    ];

    if (editable) {
      content.push(
        <FilePicker
          loadableFiles={!!user.filesIds ? user.filesIds.split(',') : []}
          onChange={onChangeFiles}
          editable={editable}
          onDelete={onDeleteFile}
        />
      );
    }

    return [
      ...content,
      <PortfolioList userId={user.id} editable={editable} />,
      <Reviews userId={user.id} showButton={user.id !== authUser.id} />,
    ];
  }, [authUser.id, editable, onChangeFiles, onDeleteFile, user]);

  const rightContent = useMemo(
    () => <ProfileUserInfo user={user} editable={editable} />,
    [editable, user]
  );

  const tabLabels = useMemo<string[]>(() => {
    const labels = ['Профессиональные данные'];

    if (editable) {
      labels.push('Документы и Файлы');
    }

    return [...labels, 'Опыт работы', 'Отзывы'];
  }, [editable]);

  return (
    <StyledWrapper>
      <LayoutProfile
        pending={false}
        toolbarProps={
          authUser.id === user.id && editable
            ? {
                title: 'Личные данные',
                rightControls: [
                  <Button
                    text="Редактировать"
                    icon={IconEdit16}
                    onClick={onClickEdit}
                  />,
                ],
              }
            : null
        }
        leftContent={leftContent}
        rightContent={rightContent}
      >
        <Paper>
          <Tabs labels={tabLabels}>
            {tabContent.map((elem, index) => {
              return <Fragment key={index}>{elem}</Fragment>;
            })}
          </Tabs>
        </Paper>
        {!editable && authUser.authenticated && (
          <StyledBlockForButton>
            {actionButtons.map((action, actionIndex) => (
              <Fragment key={actionIndex}>{action}</Fragment>
            ))}
          </StyledBlockForButton>
        )}
      </LayoutProfile>
      {user && (
        <ModalReport
          user={user}
          visible={reportModalVisible}
          onClose={onCloseReportModal}
        />
      )}
    </StyledWrapper>
  );
}

export default memo(ProfileUser);
