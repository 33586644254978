import React from 'react';
import {
  PageProfileCompany,
  PageProfileCompanyEdit,
  PageProfileContractors,
  PageProfilePartner,
  PageProfileReplies,
  PageProfileSpecialists,
  PageProfileSuppliers,
  PageProfileTender,
  PageProfileUser,
  PageProfileUserEdit,
  PageProfileVehicle,
  PageProfileVehicles,
  FavoritesPage,
  NotificationPage,
  PageProfilePartners,
} from '../../pages/Profile/pages';

export enum AppRoutesProfile {
  VEHICLES = 'vehicles',
  PARTNERS = 'partners',
  FAVOURITES = 'favorites',
  SPECIALISTS = 'specialists',
  TENDER_CATEGORY_REPLIES = 'tender-category-replies',
  TENDER_ID = 'tender-id',
  VEHICLES_ID = 'vehicles-id',
  PARTNERS_TYPE_ID = 'partners-type-id',
  NOTIFICATION = 'notification',
  //Только для entity
  TENDER_CONTRACTOR = 'tender-contractor',
  TENDER_SUPPLIER = 'tender-supplier',
  COMPANY_PROFILE = 'company-profile',
  COMPANY_EDIT = 'company-edit',
  //Только для individual
  PROFILE_USER = 'profile-user',
  EDIT_PROFILE = 'edit-profile',
}

export enum AppRoutesUserType {
  ENTITY = 'entity',
  ENTITY_INDIVIDUAL_USER = 'entity-individual-user',
  INDIVIDUAL = 'individual',
}

export const RoutePathProfile: Record<AppRoutesProfile, string> = {
  [AppRoutesProfile.VEHICLES]: '/vehicles',
  [AppRoutesProfile.PARTNERS]: '/partners',
  [AppRoutesProfile.FAVOURITES]: '/favorites',
  [AppRoutesProfile.SPECIALISTS]: '/specialists',
  [AppRoutesProfile.TENDER_CATEGORY_REPLIES]: '/tender/:tenderCategory/replies',
  [AppRoutesProfile.TENDER_ID]: '/tender/:id',
  [AppRoutesProfile.VEHICLES_ID]: '/vehicles/:id',
  [AppRoutesProfile.PARTNERS_TYPE_ID]: '/partners/:userType/:id',
  [AppRoutesProfile.NOTIFICATION]: '/notification',
  //Только для entity
  [AppRoutesProfile.TENDER_CONTRACTOR]: '/tender/contractor',
  [AppRoutesProfile.TENDER_SUPPLIER]: '/tender/supplier',
  [AppRoutesProfile.COMPANY_PROFILE]: '/company',
  [AppRoutesProfile.COMPANY_EDIT]: '/company/:bin/edit',
  //Только для individual
  [AppRoutesProfile.PROFILE_USER]: '',
  [AppRoutesProfile.EDIT_PROFILE]: '/edit',
};

export const routeConfigForEntityIndividualUser = {
  [AppRoutesProfile.PROFILE_USER]: {
    path: RoutePathProfile[AppRoutesProfile.PROFILE_USER],
    element: <PageProfileUser />,
  },
  [AppRoutesProfile.EDIT_PROFILE]: {
    path: RoutePathProfile[AppRoutesProfile.EDIT_PROFILE],
    element: <PageProfileUserEdit />,
  },
};

export const routeConfigForIndividual = {
  [AppRoutesProfile.VEHICLES]: {
    path: RoutePathProfile[AppRoutesProfile.VEHICLES],
    element: <PageProfileVehicles />,
  },
  [AppRoutesProfile.FAVOURITES]: {
    path: RoutePathProfile[AppRoutesProfile.FAVOURITES],
    element: <FavoritesPage />,
  },
  [AppRoutesProfile.SPECIALISTS]: {
    path: RoutePathProfile[AppRoutesProfile.SPECIALISTS],
    element: <PageProfileSpecialists />,
  },
  [AppRoutesProfile.TENDER_CATEGORY_REPLIES]: {
    path: RoutePathProfile[AppRoutesProfile.TENDER_CATEGORY_REPLIES],
    element: <PageProfileReplies />,
  },
  [AppRoutesProfile.TENDER_ID]: {
    path: RoutePathProfile[AppRoutesProfile.TENDER_ID],
    element: <PageProfileTender />,
  },
  [AppRoutesProfile.VEHICLES_ID]: {
    path: RoutePathProfile[AppRoutesProfile.VEHICLES_ID],
    element: <PageProfileVehicle />,
  },
  [AppRoutesProfile.PARTNERS]: {
    path: RoutePathProfile[AppRoutesProfile.PARTNERS],
    element: <PageProfilePartners />,
  },
  [AppRoutesProfile.PARTNERS_TYPE_ID]: {
    path: RoutePathProfile[AppRoutesProfile.PARTNERS_TYPE_ID],
    element: <PageProfilePartner />,
  },
  [AppRoutesProfile.NOTIFICATION]: {
    path: RoutePathProfile[AppRoutesProfile.NOTIFICATION],
    element: <NotificationPage />,
  },
};

export const routeConfigForEntity = {
  [AppRoutesProfile.TENDER_CONTRACTOR]: {
    path: RoutePathProfile[AppRoutesProfile.TENDER_CONTRACTOR],
    element: <PageProfileContractors />,
  },
  [AppRoutesProfile.TENDER_SUPPLIER]: {
    path: RoutePathProfile[AppRoutesProfile.TENDER_SUPPLIER],
    element: <PageProfileSuppliers />,
  },
  [AppRoutesProfile.COMPANY_PROFILE]: {
    path: RoutePathProfile[AppRoutesProfile.COMPANY_PROFILE],
    element: <PageProfileCompany />,
  },
  [AppRoutesProfile.COMPANY_EDIT]: {
    path: RoutePathProfile[AppRoutesProfile.COMPANY_EDIT],
    element: <PageProfileCompanyEdit />,
  },
};

export const routeConfigByTypeProfile = {
  [AppRoutesUserType.ENTITY]: {
    ...routeConfigForEntity,
    ...routeConfigForIndividual,
  },
  [AppRoutesUserType.INDIVIDUAL]: {
    ...routeConfigForIndividual,
    ...routeConfigForEntityIndividualUser,
  },
  [AppRoutesUserType.ENTITY_INDIVIDUAL_USER]: {
    ...routeConfigForEntityIndividualUser,
  },
};
