import { RouteProps } from 'react-router-dom';
import React from 'react';
import { UsersSearch, VehiclesSearch } from '@app/common';
import { ContractorsSearch, SuppliersSearch } from 'pages/Home/components';

export enum AppRoutesHome {
  CONTRACTORS = 'contractors',
  SUPPLIERS = 'suppliers',
  VEHICLES = 'vehicles',
  SPECIALISTS = 'specialists',
}

export const RoutePathHome: Record<AppRoutesHome, string> = {
  [AppRoutesHome.CONTRACTORS]: '/',
  [AppRoutesHome.SUPPLIERS]: '/suppliers',
  [AppRoutesHome.VEHICLES]: '/vehicles',
  [AppRoutesHome.SPECIALISTS]: '/specialists',
};

export const routeConfigHome: Record<AppRoutesHome, RouteProps> = {
  [AppRoutesHome.CONTRACTORS]: {
    path: RoutePathHome[AppRoutesHome.CONTRACTORS],
    element: <ContractorsSearch />,
  },
  [AppRoutesHome.SPECIALISTS]: {
    path: RoutePathHome[AppRoutesHome.SPECIALISTS],
    element: (
      <UsersSearch
        defaultParams={{ active: true }}
        refreshEventName="updateHomeSearch"
      />
    ),
  },
  [AppRoutesHome.VEHICLES]: {
    path: RoutePathHome[AppRoutesHome.VEHICLES],
    element: (
      <VehiclesSearch
        defaultParams={{ active: true }}
        refreshEventName="updateHomeSearch"
      />
    ),
  },
  [AppRoutesHome.SUPPLIERS]: {
    path: RoutePathHome[AppRoutesHome.SUPPLIERS],
    element: <SuppliersSearch />,
  },
};
