import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Router from './Router';
import './index.scss';
import {
  NotificationFromDBProvider,
  NotificationProvider,
  UserProvider,
} from '@app/providers';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import i18n from './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function Bootstrap(props: PropsWithChildren) {
  const { children } = props;

  useEffect(() => {
    // @ts-ignore
    document.body.style.zoom = '80%';
  }, []);

  if (!i18n.isInitialized) {
    return null;
  }

  return <>{children}</>;
}

const theme = {
  color: {
    white: '#FFF',
    black: '#000',
    dark: '#0A0A0A',
    primary: '#3A57E8',
    gray: '#757575',
    grayLight: '#e0e0e0',
    green: '#25AA51',
    red: '#BA1B1B',
  },
};

root.render(
  <Bootstrap>
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <NotificationFromDBProvider>
          <BrowserRouter>
            <UserProvider>
              <Router />
            </UserProvider>
          </BrowserRouter>
        </NotificationFromDBProvider>
      </NotificationProvider>
    </ThemeProvider>
  </Bootstrap>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
