import { memo } from 'react';
import { IconType } from '@app/icons';
// import './styles.scss';
import styled from 'styled-components';

export interface Props {
  icon: IconType;
  text: string;
  horizontal?: boolean;
}

interface IHorizontal {
  horizontal?: boolean;
}

const StyledInfo = styled.div`
  display: flex;
  height: 20px;

  + .smr-card__price {
    margin-top: 8px;
  }
`;
const StyledIcon = styled.div`
  svg {
    circle {
      stroke: #757575;
      path {
        stroke: #757575;
      }
    }
    path {
      stroke: #757575;
    }
  }
`;

const StyledText = styled.p<IHorizontal>`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #757575;
  width: calc(100% - 20px);
  margin: 1px 0 0 4px;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: ${(props) => (props.horizontal ? '576px' : '100%')};
`;

function CardInfo(props: Props) {
  const { text, icon: Icon, horizontal } = props;

  return (
    <StyledInfo>
      <StyledIcon className="info__icon">
        <Icon />
      </StyledIcon>
      <StyledText className="info__text" horizontal={horizontal}>
        {text}
      </StyledText>
    </StyledInfo>
  );
}

export default memo(CardInfo);
