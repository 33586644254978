import React, { memo, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Footer, Header } from '../../components';
import {
  IconMobileMain,
  IconMobileFavorite,
  IconMobileAdd,
  IconMobileNotification,
  IconMobileMenu,
} from '@app/icons';
import './styles.scss';

interface LayoutDefaultProps {
  className?: string;
}

function LayoutDefault(props: PropsWithChildren<LayoutDefaultProps>) {
  const { className = '', children } = props;

  return (
    <div className="smr-default-layout">
      <Header />
      <div className={`smr-default-layout__content ${className}`.trim()}>
        {children}
      </div>
      <Footer />
      <Menu>
        <MenuList>
          <MenuItem>
            <MenuIcon>
              <IconMobileMain />
            </MenuIcon>
            <MenuText>Главная</MenuText>
          </MenuItem>
          <MenuItem>
            <MenuIcon>
              <IconMobileFavorite />
            </MenuIcon>
            <MenuText>Избранное</MenuText>
          </MenuItem>
          <MenuItem>
            <MenuAdd>
              <MenuIcon>
                <IconMobileAdd />
              </MenuIcon>
            </MenuAdd>
          </MenuItem>
          <MenuItem>
            <MenuIcon>
              <IconMobileNotification />
            </MenuIcon>
            <MenuText>Уведомления</MenuText>
          </MenuItem>
          <MenuItem>
            <MenuItem>
              <MenuIcon>
                <IconMobileMenu />
              </MenuIcon>
              <MenuText>Меню</MenuText>
            </MenuItem>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}

const Menu = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: white;
  bottom: 0;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 640px) {
    display: block;
  }
`;

const MenuList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: 500;
  color: #9e9e9e;
  justify-content: space-around;
  list-style: none;
  margin-top: 8px;
`;

const MenuItem = styled.li`
  cursor: pointer;
  &:hover {
    color: #3a57e8;

    & > div > svg > path {
      stroke: #3a57e8;
    }
  }
`;
const MenuIcon = styled.div`
  display: flex;
  justify-content: center;
`;
const MenuText = styled.div``;
const MenuAdd = styled.div`
  background-color: #3a57e8;
  border-radius: 50%;
  padding: 17px;
  position: relative;
  top: -30%;
  border: 5px solid white;
`;

export default memo(LayoutDefault);
