import React, { ComponentType, memo, useCallback } from 'react';
import './styles.scss';

interface IconButtonProps {
  icon: ComponentType;
  onClick?: () => void;
  count: number;
}

function IconButton(props: IconButtonProps) {
  const { onClick, icon: Icon, count } = props;

  const onClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (onClick) {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <button className="b-icon-button" onClick={onClickButton}>
      <span className="b-icon-button__icon">
        <Icon />
        {count !== 0 && (
          <span className="b-icon-button-notification">{count}</span>
        )}
      </span>
    </button>
  );
}

export default memo(IconButton);
