import React, { memo, useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  OkedSelectTrigger,
  SelectOption,
  TextField,
} from '@app/components';
import styles from './styles.module.scss';
import styled from 'styled-components';
import * as yup from 'yup';
import { useForm } from '../hooks';
import { useTranslation } from 'react-i18next';
import { Oked, Portfolio } from '@app/models';

const GridStyled = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  margin-bottom: 16px;
`;

const FormGridStyled = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-end;
`;

const FormGridThreeColumnsStyled = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-end;
`;

const StyledCheckbox = styled.div`
  margin-bottom: 12px;
  justify-self: center;
`;

interface PortfolioFormProps {
  onSubmit: (data: Partial<Portfolio>, files?: File[]) => void;
  onCancel: () => void;
  pending: boolean;
}

interface PortfolioData {
  name: string;
  description: string;
  position: string;
  startDate: Date | null;
  endDate: Date | null;
  okedCode: string;
  okedName: string;
  companyPhone: string;
}

const getToday = () => {
  return new Date(new Date().toISOString().split('T')[0]);
};

function PortfolioForm(props: PortfolioFormProps) {
  const { onSubmit, pending, onCancel } = props;
  const { t } = useTranslation();
  const { values, onChange, errors, setErrors, validate } =
    useForm<PortfolioData>({
      name: '',
      description: '',
      position: '',
      startDate: null,
      endDate: null,
      companyPhone: '',
      okedCode: '',
      okedName: '',
    });

  const [checked, setChecked] = useState(false);

  const validateDateFields = async () => {
    let hasErrors = false;
    let settableErrors = {
      ...errors,
      startDate: null as string | null,
      endDate: null as string | null,
    };
    if (!values.endDate) {
      settableErrors = {
        ...settableErrors,
        endDate: t('required', { ns: 'validation' })!,
      };
      hasErrors = true;
    }
    if (!values.startDate) {
      settableErrors = {
        ...settableErrors,
        startDate: t('required', { ns: 'validation' })!,
      };
      hasErrors = true;
    }

    setErrors(settableErrors);

    return hasErrors;
  };

  const onSubmitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const hasErrors = await Promise.all([
        validate(
          yup.object().shape({
            name: yup.string().required(t('required', { ns: 'validation' })!),
            description: yup
              .string()
              .required(t('required', { ns: 'validation' })!),
            position: yup
              .string()
              .required(t('required', { ns: 'validation' })!),
            startDate: yup
              .string()
              .required(t('required', { ns: 'validation' })!),
            endDate: yup
              .string()
              .required(t('required', { ns: 'validation' })!),
            okedCode: yup
              .string()
              .required(t('required', { ns: 'validation' })!),
            companyPhone: yup
              .string()
              .required(t('required', { ns: 'validation' })!),
          })
        ),
        validateDateFields(),
      ]);

      if (hasErrors[0] || hasErrors[1]) {
        return;
      }
      const { okedName, ...otherValues } = values;
      onSubmit({
        ...otherValues,
        startDate: values.startDate?.toJSON(),
        endDate: values.endDate?.toJSON(),
      });
    },
    [values, validate, t, validateDateFields, onSubmit]
  );

  const onChangeOked = useCallback(
    (data: SelectOption<Oked>) => {
      onChange(data.value.nameRu, 'okedName');
      onChange(data.value.code, 'okedCode');
    },
    [onChange]
  );

  const onChangeCheckbox = useCallback(
    (value: boolean) => {
      setChecked(value);
      if (value) onChange(getToday(), 'endDate');
      else onChange(null, 'endDate');
    },
    [onChange]
  );

  console.log(values.companyPhone);

  return (
    <form className={styles.filePortfolio} onSubmit={onSubmitForm}>
      <GridStyled>
        <FormGridStyled>
          <TextField
            label="Наименование компании(заказчика)"
            placeholder="Введите наименование"
            name="name"
            value={values.name}
            onChange={onChange}
            helperText={errors.name}
            error={!!errors.name}
            editable={!pending}
            required
          />
        </FormGridStyled>
        <FormGridStyled>
          <OkedSelectTrigger
            onChange={onChangeOked}
            label="Сфера деятельности"
            name="oked"
            value={values.okedName}
            placeholder="Выберите сферу деятельности"
            helperText={errors.okedCode}
            error={!!errors.okedCode}
            editable={!pending}
            required
          />
          <TextField
            label="Должность"
            placeholder="Введите должность"
            name="position"
            value={values.position}
            onChange={onChange}
            helperText={errors.position}
            error={!!errors.position}
            editable={!pending}
            required
          />
        </FormGridStyled>
        <FormGridStyled>
          <FormGridThreeColumnsStyled>
            <DatePicker
              value={values.startDate}
              name="startDate"
              onChange={onChange}
              label="Начало работы"
              disabled={pending}
              helperText={errors.startDate}
              error={!!errors.startDate}
              maxDate={values.endDate || getToday()}
              required
            />
            <StyledCheckbox>
              <Checkbox
                label="По текущее время"
                checked={checked}
                onChange={onChangeCheckbox}
                disabled={pending}
              />
            </StyledCheckbox>
            <DatePicker
              value={values.endDate}
              name="endDate"
              onChange={onChange}
              label="Завершение работы"
              disabled={pending || checked}
              helperText={errors.endDate}
              error={!!errors.endDate}
              maxDate={getToday()}
              minDate={values.startDate || undefined}
              required
            />
          </FormGridThreeColumnsStyled>
        </FormGridStyled>
        <FormGridStyled>
          <TextField
            label="Контакты компании"
            onChange={onChange}
            name="companyPhone"
            value={values.companyPhone || ''}
            mask="(###) ### ## ##"
            placeholder="(777) 777 77 77"
            editable={!pending}
            required
          />
        </FormGridStyled>
        <TextField
          label="Описание работы"
          placeholder="Введите описание"
          name="description"
          value={values.description}
          onChange={onChange}
          helperText={errors.description}
          error={!!errors.description}
          editable={!pending}
          required
          multiline
        />
      </GridStyled>
      <div className={styles.flexBtnContainer}>
        <div className={styles.submitBtn}>
          <Button
            text="Добавить"
            type="submit"
            disabled={pending}
            loading={pending}
          />
        </div>
        <div className={styles.cancelButton}>
          <Button
            text="Отменить"
            type="button"
            onClick={onCancel}
            disabled={pending}
            loading={pending}
            color="default"
          />
        </div>
      </div>
    </form>
  );
}

export default memo(PortfolioForm);
