import { differenceInDays, format, formatDistance } from 'date-fns';
import { memo } from 'react';
import {
  Company,
  CompanyTypeNames,
  CompanyTypes,
  Doc,
  Portfolio,
  TenderAdStatus,
  TenderAdStatusNames,
  User,
} from '@app/models';
import ru from 'date-fns/locale/ru';

export const NOT_SPECIFIED = 'Не указан';

export type ValueOf<T> = T[keyof T];

export type KeyOf<T> = keyof T;

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function stringToDate(date: string) {
  const dateParts = date.split('.');

  return new Date(
    Number(dateParts[2]),
    Number(dateParts[1]),
    Number(dateParts[0])
  );
}

export function setMask(value: string, mask: string): string {
  let i = 0;
  let lastReplacedIndex = -1;

  const filledMask = mask.replace(/#/g, (_, j) => {
    if (i >= value.length) {
      return '#';
    }
    lastReplacedIndex = j;
    return value[i++];
  });

  return filledMask.substring(0, lastReplacedIndex + 1);
}

export function dateFormat(
  date: string | null | undefined = null,
  formatType: string = 'dd.MM.yyyy'
) {
  try {
    if (!date) {
      return null;
    }
    return format(new Date(date), formatType);
  } catch {
    console.log('parsing or invalid date');
    return '';
  }
}

export function moneyFormat(value: string | null | number | undefined) {
  const moneyValue = value || '';
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  const parts = moneyValue.toString().split('.');
  const numberPart = parts[0];
  let decimalPart;
  if (parts.length > 0) {
    decimalPart = parts[1];
  }
  return (
    numberPart.replace(thousands, ' ') + (decimalPart ? '.' + decimalPart : '')
  );
}

export function onlyDigit(value: string | null | number | undefined) {
  const digitValue = value || '';
  return digitValue?.toString().replace(/[^\d]/g, '');
}

export const genericMemo: <T>(component: T) => T = memo;

export const convertToDate = (dateString: string) => {
  let d = dateString.split('.');

  return new Date(d[2] + '.' + d[1] + '.' + d[0]);
};

export const urlUpdateParams = (params: {
  [key: string]: string | number | undefined;
}) => {
  const url = new URL(`${window.location.origin}${window.location.pathname}`);

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (typeof value !== 'undefined' && value !== null) {
      url.searchParams.set(
        key,
        typeof value === 'string' ? value : value.toString()
      );
    }
  });

  window.history.replaceState(null, 'React App', url);
};

export const getUrlParam = (key: string): string | null => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  return urlSearchParams.get(key);
};

export function shotName(
  user: Pick<User, 'lastName' | 'firstName' | 'middleName'>
): string {
  let name = user.lastName;

  if (!!user.firstName) {
    name += ` ${user.firstName.charAt(0)}`;
  }

  if (!!user.middleName) {
    name += `.${user.middleName.charAt(0)}`;
  }

  return name;
}

export function fullName(
  user: Pick<User, 'lastName' | 'firstName' | 'middleName'>
) {
  let name = user.lastName;

  if (!!user.firstName) {
    name += ` ${user.firstName}`;
  }

  if (!!user.middleName) {
    name += ` ${user.middleName}`;
  }

  return name;
}

export function parseFullName(
  fullName: string
): Pick<User, 'firstName' | 'lastName' | 'middleName'> {
  const fullNameArray = fullName.split(' ');

  return {
    firstName: fullNameArray[1] || '',
    lastName: fullNameArray[0] || '',
    middleName: fullNameArray[2] || '',
  };
}

export function getCompanyTypeByName(name: CompanyTypeNames): CompanyTypes {
  switch (name) {
    case CompanyTypeNames.TDO:
      return CompanyTypes.TDO;
    case CompanyTypeNames.PT:
      return CompanyTypes.PT;
    case CompanyTypeNames.KT:
      return CompanyTypes.KT;
    case CompanyTypeNames.PK:
      return CompanyTypes.PK;
    case CompanyTypeNames.AO:
      return CompanyTypes.AO;
    default:
      return CompanyTypes.TOO;
  }
}

export function getCompanyTypeName(type: CompanyTypes): string {
  switch (type) {
    case CompanyTypes.TOO:
      return 'ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ';
    case CompanyTypes.TDO:
      return 'ТОВАРИЩЕСТВА С ДОПОЛНИТЕЛЬНОЙ ОТВЕТСТВЕННОСТЬЮ';
    case CompanyTypes.PT:
      return 'ПОЛНОЕ ТОВАРИЩЕСТВО';
    case CompanyTypes.KT:
      return 'КОММАНДИТНОЕ ТОВАРИЩЕСТВО';
    case CompanyTypes.PK:
      return 'ПРОИЗВОДСТВЕННЫЙ КООПЕРАТИВ';
    case CompanyTypes.AO:
      return 'АКЦИОНЕРНОЕ ОБЩЕСТВО';
    default:
      return '';
  }
}

export function getCompanyTypeShortName(type: CompanyTypes): string {
  switch (type) {
    case CompanyTypes.TOO:
      return 'ТОО';
    case CompanyTypes.TDO:
      return 'ТДО';
    case CompanyTypes.PT:
      return 'ПТ';
    case CompanyTypes.KT:
      return 'КТ';
    case CompanyTypes.PK:
      return 'ПК';
    case CompanyTypes.AO:
      return 'АО';
    default:
      return '';
  }
}

export function capitalize(string: string): string {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
  } catch (e) {
    return '';
  }
}

export const generateUniqueId = (): string => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export function getFormattedDate(date: string): string {
  try {
    return format(new Date(date), 'dd.MM.yyyy');
  } catch (e) {
    return '';
  }
}

export function getFileExtension<T extends { name: string }>(file: T): string {
  const fileNameParts = file.name.split('.');

  return fileNameParts[fileNameParts.length - 1];
}

export function formatEndDate(date: string) {
  return formatDistance(new Date(), new Date(date), {
    locale: ru,
  });
}

export function currencyFormat(value: string | number) {
  const format = new Intl.NumberFormat('ru-RU').format(+value);

  return `${format} ₸`;
}

export function mapRecordToSelectOption(data: any, data2: any) {
  return {
    label: data2[data],
    value: data,
  };
}

export const requestOptions = Object.keys(TenderAdStatusNames).map(
  (statusKey) => ({
    label: TenderAdStatusNames[statusKey as unknown as TenderAdStatus],
    value: statusKey as unknown as TenderAdStatus,
  })
);

/**
 * It converts a date to a string, then converts that string back to a date, but in UTC
 * @param {Date | null} [date] - The date to convert.
 * @returns A string in ISO format.
 */
export function convertDateWithoutChangingTimezone(date?: Date | null) {
  if (date === null || date === undefined) {
    return null;
  }

  const dateStr = date.toString();
  const result = new Date(
    Date.UTC(
      Number(dateStr.slice(11, 15)), // year
      'JanFebMarAprMayJunJulAugSepOctNovDec'.indexOf(dateStr.slice(4, 7)) / 3, // month (0-11)
      Number(dateStr.slice(8, 10)), // day
      Number(dateStr.slice(16, 18)), // hour
      Number(dateStr.slice(19, 21)), // minute
      Number(dateStr.slice(22, 24)) // second
    )
  );

  return result.toISOString();
}

export function calculatePortfolios(portfolios: Portfolio[]) {
  if (!portfolios || portfolios.length <= 0) {
    return '-';
  }

  const days = portfolios
    .map((portfolio) =>
      differenceInDays(
        new Date(portfolio.endDate),
        new Date(portfolio.startDate)
      )
    )
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  if (days <= 365) {
    const months = days / 30;

    return months > 1
      ? rightDateLabel(+(days / 30).toFixed(1), 'month')
      : rightDateLabel(+days, 'day');
  }
  const portfolioYears = (days / 365).toFixed(1);
  return rightDateLabel(+portfolioYears, 'year');
}

export function rightDateLabel(num: number, type: 'day' | 'month' | 'year') {
  const lastNum = Math.floor(num) % 10;
  const label = {
    day: ['дней', 'день', 'дня'],
    month: ['месяцев', 'месяц', 'месяца'],
    year: ['лет', 'год', 'года'],
  };

  if (lastNum % 10 >= 5 || lastNum % 10 === 0)
    return `${num} ${label[type][0]}`;
  if (lastNum % 10 === 1) return `${num} ${label[type][1]}`;
  return `${num} ${label[type][2]}`;
}

export function getPartnerIndividual(doc: Doc, userId: string): User | null {
  return doc.owner.id === userId ? doc.recipient : doc.owner;
}

export function getPartnerEntity(
  doc: Doc,
  company: Company | null
): Company | null {
  if (!doc.recipientCompanyId && !doc.companyId) {
    return null;
  }

  if (!company) {
    return doc.company || doc.recipientCompany;
  }

  return doc.companyId === company.id ? doc.recipientCompany : doc.company;
}

export function customSort<T>(arr: T[], field: keyof T) {
  if (typeof arr[0][field] === 'number')
    return arr.sort((x, y) => {
      if (x[field] < y[field]) {
        return -1;
      }
      if (x[field] > y[field]) {
        return 1;
      }
      return 0;
    });
  if (typeof arr[0][field] === 'boolean')
    return arr.sort((x) => {
      if (x[field]) {
        return -1;
      }
      if (!x[field]) {
        return 1;
      }
      return 0;
    });
  return arr;
}

export function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader();

  reader.readAsDataURL(blob);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve((reader.result as string).split(',')[1]);
    };
  });
}
