import instance from './instance';
import { Kato } from '../models';

export interface GetKatoListParams
  extends Partial<Pick<Kato, 'parentNumber'>> {}

export function getKatoList(params: GetKatoListParams): Promise<Kato[]> {
  return instance(false)
    .get('/kato/getAll', {
      params,
    })
    .then((response) => response.data);
}

export function getParentKatoList(): Promise<Kato[]> {
  return instance(false)
    .get('/kato/getParent')
    .then((response) => response.data);
}

export function getKatoByCode(katoCode: string): Promise<Kato> {
  return instance()
    .get(`/kato/getByCode/${katoCode}`)
    .then((response) => response.data);
}
