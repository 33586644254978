import React, { memo } from 'react';
import { Container } from '@app/components';
import { LayoutDefault } from '@app/layouts';

interface NotFoundPageProps {}

function NotFoundPage(props: NotFoundPageProps) {
  return (
    <LayoutDefault className="p-home">
      <Container>
        <div>This is 404 page</div>
      </Container>
    </LayoutDefault>
  );
}

export default memo(NotFoundPage);
