import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { Tender, TenderCategory, TenderTypes, Vehicle } from '@app/models';
import { TenderCard, VehicleCard } from '@app/common';
import { getTenders, getVehicles, PageableParams } from '@app/api';
import { RenderFilterProps, SearchTemplate } from '@app/templates';
import { IconEmpty } from '@app/icons';
import fallbackImage from '../../assets/images/vehicle_fallback.png';
import { VehiclesFilter, VehiclesFilterData } from '@app/forms';

interface Props {
  onClick?: (id: string) => void;
  columns?: number;
  title?: string;
  controls?: ReactNode[];
  defaultParams?: Partial<Vehicle | Tender>;
  refreshEventName?: string;
}

function VehiclesSearch(props: Props) {
  const {
    onClick,
    columns,
    title,
    controls,
    defaultParams = {},
    refreshEventName,
  } = props;
  const [tenderType, setTenderType] = useState<TenderTypes>(
    TenderTypes.REQUEST
  );

  const emptyTitle = useMemo(
    () =>
      tenderType === TenderTypes.REQUEST
        ? 'Вы еще не опубликовали заявки в категории «Спецтехника»'
        : 'Вы еще не опубликовали предложения в категории «Спецтехника»',
    [tenderType]
  );

  const renderTenderItem = useCallback(
    (item: Tender | Vehicle, horizontal?: boolean) => {
      if (tenderType === TenderTypes.REQUEST) {
        return (
          <TenderCard
            horizontal={horizontal}
            tender={item as Tender}
            onClick={onClick}
            fallbackImage={fallbackImage}
          />
        );
      }

      return (
        <VehicleCard
          horizontal={horizontal}
          vehicle={item as Vehicle}
          onClick={onClick}
          fallbackImage={fallbackImage}
        />
      );
    },
    [onClick, tenderType]
  );

  const getData = useCallback(
    (params: PageableParams, filter?: VehiclesFilterData<Tender | Vehicle>) => {
      const { tenderType, ...restFilter } = filter!;

      setTenderType(tenderType);

      if (Number(tenderType) === TenderTypes.OFFER) {
        return getVehicles({
          ...params,
          ...(defaultParams as Partial<Vehicle>),
          ...(restFilter as Partial<Vehicle>),
        });
      }

      return getTenders({
        ...params,
        ...(defaultParams as Partial<Tender>),
        ...(restFilter as Partial<Tender>),
        category: TenderCategory.VEHICLE,
      });
    },
    [defaultParams]
  );

  const renderFilter = useCallback(
    (filterProps: RenderFilterProps<VehiclesFilterData<Tender | Vehicle>>) => {
      return <VehiclesFilter {...filterProps} />;
    },
    []
  );

  return (
    <div>
      <SearchTemplate<Tender | Vehicle, VehiclesFilterData<Tender | Vehicle>>
        columns={columns}
        renderFilter={renderFilter}
        renderItem={renderTenderItem}
        getData={getData}
        title={title}
        controls={controls}
        initialFilter={{
          tenderType: TenderTypes.REQUEST,
        }}
        emptyIcon={<IconEmpty />}
        emptyTitle={emptyTitle}
        refreshEventName={refreshEventName}
      />
    </div>
  );
}

export default memo(VehiclesSearch);
