import { IconRatingStar } from '@app/icons';
import { FC, Fragment, useState } from 'react';
import styles from './start.module.scss';

interface IRating {
  rating: number;
  reverse?: boolean;
  marginSize?: number;
}

const StarRating: FC<IRating> = ({
  rating = 0,
  reverse = false,
  marginSize,
}) => {
  const [ratingArr, setRatingArr] = useState([1, 2, 3, 4, 5]);

  return (
    <div className={reverse ? styles.containerReverse : styles.container}>
      <div className={styles.rating} style={{ marginLeft: `${marginSize}px` }}>
        {rating ? rating : 0} /5
      </div>
      <ul className={styles.starList} style={{ marginLeft: `${marginSize}px` }}>
        {ratingArr.map((star, index) => (
          <li
            key={index}
            className={(index += 1) <= rating ? styles.active : styles.star}
          >
            <IconRatingStar />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StarRating;
