import { IconArrowProfile } from '@app/icons';
import { memo } from 'react';
import styles from './Pagination.module.scss';

const Pagination = memo(() => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.count}>
          <span className={styles.countRow}>Кол-во строк:</span>
          <div className={styles.rowCount}>
            100
            <IconArrowProfile />
          </div>
        </div>
        <div className={styles.pagesCount}>
          <span className={styles.pages}>Страница: 1/0</span>
          <div className={styles.arrowLists}>
            <button className={styles.arrowLeft}>
              <IconArrowProfile />
            </button>
            <button className={styles.arrowRight}>
              <IconArrowProfile />
            </button>
          </div>
        </div>
      </div>
    </>
  );
});
export default Pagination;
