import { useState, useEffect } from 'react';

const SECOND: number = 1_000;
const MINUTE: number = SECOND * 60;
const HOUR: number = MINUTE * 60;
const DAY: number = HOUR * 24;

type UserTimer = () => string;
const formatter = new Intl.NumberFormat('ru', {
  style: 'unit',
  unit: 'day',
  unitDisplay: 'long',
});
export const useTimer = (deadline: number | string): UserTimer => {
  const [timespan, setTimespan] = useState<number>(
    Number(new Date(deadline)) - Number(Date.now())
  );
  useEffect(() => {
    if (timespan <= 0) {
      return;
    } else {
      const intervalId = setInterval(() => {
        setTimespan((_timespan) => _timespan - SECOND);
      }, SECOND);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [SECOND, timespan]);

  useEffect(() => {
    setTimespan(+new Date(deadline) - Date.now());
  }, [deadline]);

  return (): string => {
    let days: number = Math.floor(timespan / DAY);
    let hours: number = Math.floor((timespan / HOUR) % 24);
    let minutes: number = Math.floor((timespan / MINUTE) % 60);
    let seconds: number = Math.floor((timespan / SECOND) % 60);
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      return '';
    } else {
      if (days !== 0) {
        return `${formatter.format(days)}`;
      } else {
        return `${hours <= 9 ? `0${hours}` : hours}:${
          minutes <= 9 ? `0${minutes}` : minutes
        }:${seconds <= 9 ? `0${seconds}` : seconds}`;
      }
    }
  };
};
