import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalProps, Paper, Tabs } from '@app/components';
import styled from 'styled-components';
import { Doc, DocStatus } from '@app/models';
import { fullName } from '@app/helpers';
import {
  ContractDoc,
  ContractSigners,
  ContractTenderSearch,
} from './components';
import { IconPlusCircle24 } from '@app/icons';
import { Doc as DocComponent } from '@app/common';
import { theme } from 'styled-tools';
import { ModalAddDoc, ModalConfirm } from '../index';
import { getContractDocs } from '@app/api';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '@app/providers';

interface Props extends Partial<ModalProps> {
  contract: Doc | null;
  updateContract: (contract: Partial<Doc>) => void;
}

const StyledContent = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledDocs = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledTitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${theme('color.dark')};
`;

function ModalContract(props: Props) {
  const { contract, visible, onClose, updateContract } = props;
  const [docAddModalVisible, setDocAddModalVisible] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const [docs, setDocs] = useState<Doc[]>([]);
  const navigate = useNavigate();
  const [modalConfirmVisible, setModalConfirmVisible] =
    useState<boolean>(false);

  const onCreateDoc = useCallback((doc: Doc) => {
    setDocs((prevDocs) => [doc, ...prevDocs]);
  }, []);

  const onClickAddDoc = useCallback(() => {
    setDocAddModalVisible(true);
  }, []);

  const closeAddDocModal = useCallback(() => {
    setDocAddModalVisible(false);
  }, []);

  const updateDoc = useCallback((doc: Partial<Doc>) => {
    setDocs((prevDocs) =>
      prevDocs.map((prevDoc) => ({
        ...prevDoc,
        status: doc.id === prevDoc.id ? doc.status! : prevDoc.status,
      }))
    );
  }, []);

  const getData = useCallback(async () => {
    try {
      setPending(true);

      const docsResponse = await getContractDocs(contract!.id);

      setDocs(docsResponse);
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [contract]);

  const tabLabels = useMemo(() => ['Документы', 'Связанные объявления'], []);

  const modalTitle = useMemo(() => {
    if (!contract) {
      return '';
    }

    return `${contract.name}, ${
      !!contract.companyId ? contract.company!.name : fullName(contract.owner)
    }`;
  }, [contract]);

  const onClickModalConfirm = useCallback(() => {
    navigate('/profile/edit');
  }, [navigate]);

  const onCloseModalConfirm = useCallback(() => {
    setModalConfirmVisible(false);
  }, []);

  const onClickSignButton = useCallback(() => {
    setModalConfirmVisible(true);
  }, []);

  useEffect(() => {
    if (!!contract) {
      getData();
    } else {
      setDocs([]);
    }
  }, [contract, getData]);

  if (!visible || !contract) {
    return null;
  }

  return (
    <Modal visible={visible} title={modalTitle} onClose={onClose} size="xlarge">
      <StyledContent>
        <Paper title="Описание договора">
          <DocComponent doc={contract} hideStatus={true} variant="secondary" />
        </Paper>
        <ContractSigners
          contract={contract}
          updateContract={updateContract}
          checkIin={onClickSignButton}
        />
        <Paper>
          <Tabs labels={tabLabels} variant="secondary">
            <StyledDocs>
              <StyledTitle>
                {docs.length > 0 ? 'Документы' : 'Нет созданных документов'}
              </StyledTitle>
              {docs.map((doc, docIndex) => (
                <ContractDoc
                  doc={doc}
                  key={doc.id}
                  defaultChecked={docIndex === 0}
                  updateDoc={updateDoc}
                  checkIin={onClickSignButton}
                />
              ))}
            </StyledDocs>
            <ContractTenderSearch
              params={{
                contractId: contract.id,
              }}
            />
          </Tabs>
        </Paper>
        {contract.status === DocStatus.SIGNED && (
          <StyledFooter>
            <Button
              text="Добавить документ"
              icon={IconPlusCircle24}
              onClick={onClickAddDoc}
            />
          </StyledFooter>
        )}
      </StyledContent>
      <ModalAddDoc
        contract={contract}
        visible={docAddModalVisible}
        onClose={closeAddDocModal}
        onCreate={onCreateDoc}
      />
      <ModalConfirm
        onAccept={onClickModalConfirm}
        title={'Заполните ИИН в профиле'}
        textButtonCancel={'Закрыть'}
        textButtonSuccess={'Добавить ИИН в профиль'}
        visible={modalConfirmVisible}
        onClose={onCloseModalConfirm}
        skipSuccessStep={true}
        subText={
          'Для заключения договоров с партнерами необходимо заполнить ИИН в профиле'
        }
      />
    </Modal>
  );
}

export default memo(ModalContract);
