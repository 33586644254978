import { Report } from '@app/models';
import instance from './instance';

export interface CreateReportData {
  authorId: string;
  tenderId?: string;
  companyId?: string;
  userId?: string;
  message: string;
}

export function getReports(): Promise<Report[]> {
  return instance()
    .get('/reports/getAll')
    .then((response) => response.data);
}

export function postReport(data: CreateReportData): Promise<Report> {
  return instance()
    .post('/reports/create', data)
    .then((response) => response.data);
}
