import styles from './Notification.module.scss';
import Pagination from 'pages/Profile/components/Pagination';
import NotificationItemContent from './components/NotificationItemContent';
import { Empty, Loader } from '@app/components';
import React, { useCallback, useEffect, useState } from 'react';
import { getNotificationsForUser, getReplies, PageableParams } from '@app/api';
import { Notification, NotificationTypeReply, Reply } from '@app/models';
import styled from 'styled-components';
import { ModalReplyDetail } from '@app/modals';
import { useNotificationFromDBContext } from '@app/providers';

const StyledLoader = styled.div`
  text-align: center;
  margin: 16px 0;
`;

const NotificationPage = () => {
  const { setNotifications: setNotificationsContext } =
    useNotificationFromDBContext();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [replyModalVisible, setReplyModalVisible] = useState<boolean>(false);
  const [targetReply, setTargetReply] = useState<string | null>(null);
  const [reply, setReply] = useState<Reply | null>(null);

  const onCloseReplyModal = useCallback(() => {
    setReplyModalVisible(false);
  }, []);

  const onClickReply = useCallback(
    (id: string) => {
      setTargetReply(id);
      setReplyModalVisible(true);
    },
    [setTargetReply, setReplyModalVisible]
  );

  const onClickNotification = useCallback(
    (notification: Notification) => () => {
      if (NotificationTypeReply.includes(notification.type)) {
        onClickReply(JSON.parse(notification.data)?.replyId);
      }
    },
    [onClickReply]
  );

  const getNotification = useCallback(async () => {
    try {
      setIsPending(true);
      const response = await getNotificationsForUser();
      setNotifications(response);
      setNotificationsContext(response.filter((elem) => !elem.isRead));
    } catch (e) {
    } finally {
      setIsPending(false);
    }
  }, [setNotificationsContext, setNotifications]);

  const getRepliesData = useCallback(async () => {
    if (!targetReply) return;

    const defaultParams: Partial<Reply> = {};
    defaultParams.id = targetReply;
    const response = await getReplies({
      ...({} as PageableParams),
      ...defaultParams,
    });
    setReply(response.data[0]);
  }, [targetReply]);

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    getRepliesData();
  }, [targetReply]);

  return (
    <div className={styles.container}>
      <div className={styles.head}>Уведомления</div>
      <div className={styles.main}>
        <ul className={styles.notificationLists}>
          {isPending ? (
            <StyledLoader>
              <Loader />
            </StyledLoader>
          ) : notifications.length === 0 ? (
            <Empty title={'У вас пока нет никаких уведомлений'} />
          ) : (
            notifications?.map((notification, index) => {
              return (
                <NotificationItemContent
                  notification={notification}
                  key={index}
                  onClick={onClickNotification(notification)}
                  getData={getNotification}
                />
              );
            }) ||
            (notifications.length === 0 && (
              <Empty title={'У вас пока нет никаких уведомлений'} />
            ))
          )}
        </ul>
      </div>
      <div className={styles.footer}>
        <Pagination />
      </div>
      {targetReply && reply && (
        <ModalReplyDetail
          visible={replyModalVisible}
          onClose={onCloseReplyModal}
          reply={reply}
        />
      )}
    </div>
  );
};

export default NotificationPage;
