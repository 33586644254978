import React, { memo, useCallback } from 'react';
import { Doc as DocModel, DocStatus, DocStatusName } from '@app/models';
import { Info } from '@app/components';
import { currencyFormat, dateFormat, fullName } from '@app/helpers';
import styled from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';

interface Props {
  doc: DocModel;
  onClick?: (doc: DocModel) => void;
  hideStatus?: boolean;
  hideName?: boolean;
  variant?: 'default' | 'secondary';
}

const StyledDoc = styled.div<Pick<Props, 'variant'>>`
  padding: ${ifProp({ variant: 'secondary' }, '0', '16px')};
  box-sizing: border-box;
  gap: 24px;
  background: ${ifProp({ variant: 'secondary' }, 'transparent', '#f5f5f5')};
  border-radius: 10px;
  display: flex;
`;

const StyledDocInfo = styled.div`
  gap: 16px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 150px repeat(5, 200px);
  align-items: center;
  width: 100%;

  > * + * {
    margin-left: 20px;
  }
`;

const Status = styled.div<{
  status: DocStatus;
}>`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background: ${switchProp('status', {
      10: theme('color.primary'),
      20: theme('color.gray'),
      30: theme('color.gray'),
      40: theme('color.green'),
      50: theme('color.red'),
      60: theme('color.dark'),
    })};
    display: block;
    border-radius: 50%;
  }
`;

const StatusText = styled.p<{
  status: DocStatus;
}>`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${switchProp('status', {
    10: theme('color.primary'),
    20: theme('color.gray'),
    30: theme('color.gray'),
    40: theme('color.green'),
    50: theme('color.red'),
    60: theme('color.dark'),
  })};
  margin: 0 0 0 4px;
`;

function Doc(props: Props) {
  const {
    doc,
    onClick,
    hideStatus = false,
    hideName = false,
    variant = 'default',
  } = props;

  const onClickDoc = useCallback(() => {
    if (!!onClick) {
      onClick(doc);
    }
  }, [onClick, doc]);

  return (
    <StyledDoc key={doc.id} variant={variant}>
      <StyledDocInfo onClick={onClickDoc}>
        {!hideStatus && (
          <Status status={doc.status}>
            <StatusText status={doc.status}>
              {DocStatusName[doc.status]}
            </StatusText>
          </Status>
        )}
        {!hideName && (
          <Info
            label="Предмет договора"
            value={doc.name}
            small={true}
            displayedLength={22}
          />
        )}
        <Info
          label="Срок договора"
          value={dateFormat(doc.endDate)}
          small={true}
        />
        <Info
          label="Сумма договора"
          value={currencyFormat(doc.price)}
          small={true}
        />
        <Info
          label="Инициатор"
          displayedLength={22}
          value={!!doc.companyId ? doc.company.name : fullName(doc.owner)}
          small={true}
        />
        <Info
          label="Партнер"
          displayedLength={22}
          value={
            !!doc.recipientCompanyId
              ? doc.recipientCompany.name
              : fullName(doc.recipient)
          }
          small={true}
        />
      </StyledDocInfo>
    </StyledDoc>
  );
}

export default memo(Doc);
