import { WithCreatedUpdated } from './Default';
import { Kato } from './Kato';
import { User } from './User';
import { Company } from './Company';

export enum VehicleStatus {
  FREE = 10,
  BUSY = 20,
}

export const VehicleStatusNames: Record<VehicleStatus, string> = {
  [VehicleStatus.FREE]: 'Свободен',
  [VehicleStatus.BUSY]: 'Занят',
};

export interface Vehicle extends WithCreatedUpdated {
  id: string;
  name: string;
  description: string;
  price: string;
  filesIds: string;
  kato: Kato;
  owner: User;
  dateOfIssue: string;
  brand: string;
  type: string;
  imagesIds: string;
  katoCode: string;
  status: VehicleStatus;
  ownerId: string | null;
  companyId: string | null;
  company: Company | null;
  isFavorite: boolean;
  active: boolean;
  rating: number;
}
