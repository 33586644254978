import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Reply, ReplyStatus } from '@app/models';
import { ModalReplyDetail } from '@app/modals';
import { Reply as ReplyComponent } from '@app/common';
import { customSort } from '@app/helpers';

interface Props {
  replies: Reply[];
}

const StyledReplies = styled.div`
  display: grid;
  grid-gap: 8px;
`;

function Replies(props: Props) {
  const { replies: propsReplies } = props;
  const [replies, setReplies] = useState<Reply[]>(propsReplies);
  const [reply, setReply] = useState<Reply | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const onCloseModal = useCallback((item?: Reply) => {
    if (item) {
      if (item.status === ReplyStatus.ACCEPT) {
        setReplies((prevReplies) => {
          const arr = prevReplies.map((prevReply) => ({
            ...prevReply,
            status:
              item.id === prevReply.id
                ? ReplyStatus.ACCEPT
                : ReplyStatus.DECLINE,
          }));
          return customSort<Reply>(arr, 'status');
        });
      }

      if (item.status === ReplyStatus.DECLINE) {
        setReplies((prevReplies) => {
          const arr = prevReplies.map((prevReply) => ({
            ...prevReply,
            status:
              item.id === prevReply.id ? ReplyStatus.DECLINE : prevReply.status,
          }));
          return customSort<Reply>(arr, 'status');
        });
      }

      setReplies((prevReplies) => {
        const arr = prevReplies.map((prevReply) => ({
          ...prevReply,
          pinned: item.id === prevReply.id ? item.pinned : prevReply.pinned,
        }));
        return customSort<Reply>(arr, 'pinned');
      });
    }

    setReply(null);
    setModalVisible(false);
  }, []);

  const onClickReply = useCallback((item: Reply) => {
    setReply(item);
    setModalVisible(true);
  }, []);

  if (replies.length === 0) {
    return null;
  }

  return (
    <StyledReplies>
      {replies.map((reply, replyIndex) => (
        <ReplyComponent reply={reply} key={reply.id} onClick={onClickReply} />
      ))}
      <ModalReplyDetail
        visible={modalVisible}
        onClose={onCloseModal}
        reply={reply}
      />
    </StyledReplies>
  );
}

export default memo(Replies);
