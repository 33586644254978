import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Tender,
  TenderCategory,
  TenderTypes,
  User,
  UserType,
} from '@app/models';
import { getTenders, getUsers, PageableParams } from '@app/api';
import { RenderFilterProps, SearchTemplate } from '@app/templates';
import UserCard from 'components/UserCard';
import { ModalProfile } from '@app/modals';
import { UsersFilter, UsersFilterData } from '@app/forms';
import { TenderCard } from '../index';
import fallbackImage from '../../assets/images/vehicle_fallback.png';
import { useLocation } from 'react-router-dom';

interface Props {
  onClick?: (id: string) => void;
  refreshEventName?: string;
  defaultParams?: Partial<User | Tender>;
}

function UsersSearch(props: Props) {
  const { refreshEventName, defaultParams = {}, onClick } = props;
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [tenderType, setTenderType] = useState<TenderTypes>(
    TenderTypes.REQUEST
  );
  const location = useLocation();

  const emptyTitle = useMemo(
    () =>
      tenderType === TenderTypes.REQUEST
        ? 'Вы еще не опубликовали заявки в категории «Специалисты»'
        : 'Список «Специалистов» пуст',
    [tenderType]
  );

  const onClickUser = useCallback(
    (clickedUser: User) => () => {
      setSelectedUser(clickedUser);
    },
    []
  );

  const renderItem = useCallback(
    (item: User | Tender, horizontal?: boolean) => {
      if (tenderType === TenderTypes.REQUEST) {
        return (
          <TenderCard
            horizontal={true}
            hideThumbnail={true}
            tender={item as Tender}
            onClick={onClick}
            fallbackImage={fallbackImage}
          />
        );
      }

      return (
        <UserCard
          {...(item as User)}
          horizontal={true}
          onClick={onClickUser(item as User)}
        />
      );
    },
    [tenderType, onClick, onClickUser]
  );

  const getData = useCallback(
    (params: PageableParams, filter?: UsersFilterData<Tender | User>) => {
      const { tenderType, ...restFilter } = filter!;

      setTenderType(tenderType);

      if (Number(tenderType) === TenderTypes.OFFER) {
        return getUsers({
          ...params,
          ...(defaultParams as Partial<User>),
          ...(restFilter as Partial<User>),
          type: UserType.INDIVIDUAL,
        });
      }

      return getTenders({
        ...params,
        ...(defaultParams as Partial<Tender>),
        ...(restFilter as Partial<Tender>),
        category: TenderCategory.WORKER,
      });
    },
    [defaultParams]
  );

  const onCloseModal = useCallback(() => {
    setSelectedUser(null);
  }, []);

  const renderFilter = useCallback(
    (filterProps: RenderFilterProps<UsersFilterData<Tender | User>>) => {
      return <UsersFilter {...filterProps} />;
    },
    []
  );

  return (
    <div>
      <SearchTemplate<Tender | User, UsersFilterData<Tender | User>>
        renderItem={renderItem}
        getData={getData}
        renderFilter={renderFilter}
        emptyTitle={emptyTitle}
        horizontal={true}
        refreshEventName={refreshEventName}
        viewControl={false}
        initialFilter={{
          tenderType: TenderTypes.REQUEST,
        }}
      />
      <ModalProfile
        visible={!!selectedUser}
        user={selectedUser}
        onClose={onCloseModal}
      />
    </div>
  );
}

export default memo(UsersSearch);
