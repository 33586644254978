import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button, Paper, TextField } from '@app/components';
import styled from 'styled-components';
import { Company, User, UserType } from '@app/models';
import { getCompanyByBin, getUserByIin } from '@app/api';
import { fullName } from '@app/helpers';
import { useUserContext } from '@app/providers';

interface Props {
  onSubmit: (company: Company | null, user: User | null) => void;
}

const StyledStep1 = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 16px;
`;

const StyledFooter = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 0 24px;
  box-sizing: border-box;
`;

function Step1(props: Props) {
  const { onSubmit } = props;
  const { user: authUser, company: authCompany } = useUserContext();
  const [pending, setPending] = useState<boolean>(false);
  const [bin, setBin] = useState<string>('');
  const [company, setCompany] = useState<Company | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [searchError, setSearchError] = useState<boolean>(false);

  const onChangeBin = useCallback((text: string) => {
    setCompany(null);
    setUser(null);
    setBin(text);
  }, []);

  const onClickFind = useCallback(async () => {
    try {
      setPending(true);
      setSearchError(false);

      const companyResponse = await getCompanyByBin(bin);
      const userResponse = await getUserByIin(bin);
      let isCurrentCompany = false;
      let isCurrentUser = false;

      if (
        !!companyResponse &&
        authUser.type === UserType.ENTITY &&
        companyResponse.id === authCompany!.id
      ) {
        isCurrentCompany = true;
      }

      if (
        !!userResponse &&
        userResponse.type === UserType.INDIVIDUAL &&
        userResponse.id === authUser!.id
      ) {
        isCurrentUser = true;
      }

      setCompany(!isCurrentCompany ? companyResponse : null);
      setUser(!isCurrentUser ? userResponse : null);
      setPending(false);
      setSearchError(
        (!companyResponse && !userResponse) || isCurrentCompany || isCurrentUser
      );
    } catch (e) {
      setPending(false);
      setSearchError(false);
    }
  }, [authCompany, authUser, bin]);

  const onClickSubmit = useCallback(() => {
    onSubmit(company, user);
  }, [company, user, onSubmit]);

  const partnerName = useMemo<string>(() => {
    let returnName = '';

    if (!!company) {
      returnName = company.name;
    }

    if (!!user) {
      returnName = fullName(user);
    }

    return returnName;
  }, [company, user]);

  return (
    <StyledStep1>
      <Paper>
        <StyledGrid>
          <TextField
            label="Партнер"
            placeholder=""
            value={partnerName}
            editable={false}
          />
          <TextField
            label="БИН/ИИН"
            placeholder="Укажите БИН/ИИН"
            value={bin}
            error={searchError}
            helperText={
              searchError
                ? 'Вы не можете добавить себя в качестве партнера'
                : ''
            }
            onChange={onChangeBin}
          />
        </StyledGrid>
      </Paper>
      <StyledFooter>
        <Button
          text="Найти"
          onClick={onClickFind}
          disabled={pending || bin.length !== 12}
          loading={pending}
          variant="outlined"
        />
        {(!!company || !!user) && (
          <Button text="Продолжить" onClick={onClickSubmit} />
        )}
      </StyledFooter>
    </StyledStep1>
  );
}

export default memo(Step1);
