import React, { memo, PropsWithChildren } from 'react';
import './styles.scss';
import Button from '../Button';

interface Props extends PropsWithChildren {
  title?: string;
  submitText?: string;
  className?: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  disabled?: boolean;
  loading?: boolean;
}

function Form(props: Props) {
  const {
    title,
    submitText = 'Сохранить',
    className = '',
    onSubmit,
    children,
    disabled = false,
    loading = false,
  } = props;

  return (
    <form onSubmit={onSubmit} className={`smr-form ${className}`.trim()}>
      <div className="smr-form__content">
        <p className="smr-form__title">{title}</p>
        {children}
      </div>
      {!!onSubmit && (
        <Button
          text={submitText}
          disabled={disabled}
          className="smr-form__submit"
          type="submit"
          loading={loading}
        />
      )}
    </form>
  );
}

export default memo(Form);
