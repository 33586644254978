import instance from './instance';
import { Oked } from '../models';

export interface GetOkedParams extends Partial<Pick<Oked, 'parentNumber'>> {}

export function getOkedList(params: GetOkedParams): Promise<Oked[]> {
  return instance()
    .get('/oked/getAll', {
      params,
    })
    .then((response) => response.data);
}

export function getParentOkedList(): Promise<Oked[]> {
  return instance()
    .get('/oked/getParent')
    .then((response) => response.data);
}
