import React, { memo } from 'react';
import { User } from '@app/models';
import { Info } from '@app/components';
import { useUserContext } from '@app/providers';
import { setMask, shotName } from '@app/helpers';

interface Props {
  author: User;
}

function IndividualUserInfo(props: Props) {
  const { author } = props;
  const { user } = useUserContext();
  return (
    <div className="section-author__info">
      <Info label="Автор" value={shotName(author)} />
      {user.authenticated && (
        <>
          <Info
            label="Номер телефона"
            value={setMask(author.phone, '+7 (###) ### ## ##')}
          />
          <Info label="Почтовый адрес" value={author.email} />
        </>
      )}
    </div>
  );
}

export default memo(IndividualUserInfo);
