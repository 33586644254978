import React, { useCallback, useRef, useState } from 'react';
import { IconCloseEye, IconEye } from '@app/icons';
import Button from '../../../Button';

interface Props {
  onClickHideActive: (isActive: boolean) => void;
  isActive?: boolean;
}

function CardHideActiveButton(props: Props) {
  const { onClickHideActive, isActive: propsIsActive = false } = props;
  const [isActive, setIsActive] = useState<boolean>(propsIsActive);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const onClickButton = useCallback(() => {
    setIsActive(!isActive);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      onClickHideActive(!isActive);
    }, 1000);
  }, [isActive, onClickHideActive]);

  return (
    <Button
      text=""
      icon={isActive ? IconCloseEye : IconEye}
      onClick={onClickButton}
      variant={isActive ? 'contained' : 'outlined'}
    />
  );
}

export default CardHideActiveButton;
