import React, { memo } from 'react';
import { Info } from '@app/components';
import { useTranslation } from 'react-i18next';
import { User, UserFamilyStatusNames, UserGenderName } from '@app/models';

interface Props {
  user: User;
  editable?: boolean;
}

function ProfileUserInfo(props: Props) {
  const { user, editable = false } = props;
  const { t } = useTranslation();

  return (
    <>
      <Info
        label={t('fullName')}
        value={`${user.lastName} ${user.firstName} ${user.middleName}`.trim()}
      />
      {editable && (
        <Info label={t('idn')} value={user.iin || t('notSpecified')} />
      )}
      <Info
        label={t('gender')}
        value={
          user.gender !== null ? UserGenderName[user.gender] : t('notSpecified')
        }
      />
      <Info
        label={t('familyStatus')}
        value={
          user.familyStatus !== null
            ? UserFamilyStatusNames[user.familyStatus]
            : t('notSpecified')
        }
      />
      <Info
        label={t('region')}
        value={user.kato ? user.kato.nameRu : t('notSpecified')}
      />
      <Info
        label={t('address')}
        value={user.address ? user.address : t('notSpecified')}
      />
      {editable && <Info label={t('login')} value={user.email} />}
    </>
  );
}

export default memo(ProfileUserInfo);
