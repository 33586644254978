import React, { memo, useCallback, useEffect, useState } from 'react';
import Select, { SelectOption } from '../Select';
import { Vehicle } from '@app/models';
import { getAllBrands } from '@app/api';

interface BrandSelectProps {
  onChange: (value: string) => void;
  index?: number;
  item?: ISelectOption[];
  onSelectedLists?: (item: any[]) => void;
}

interface ISelectOption extends SelectOption<Vehicle['brand']> {}

const defaultOption = {
  label: 'Все' as const,
  value: 'all' as const,
};

function BrandSelect(props: BrandSelectProps) {
  const { onChange } = props;
  const [option, setOption] = useState<ISelectOption | null>(null);
  const [options, setOptions] = useState<ISelectOption[]>([]);
  const onChangeSelect = useCallback(
    (selectedOption: ISelectOption | null) => {
      setOption(selectedOption);

      const isSelectedAll = selectedOption?.value === defaultOption.value;
      const option = selectedOption?.value ?? '';

      onChange(isSelectedAll ? '' : option);
    },
    [onChange]
  );

  const getData = useCallback(async () => {
    try {
      setOptions([]);
      const brandList = await getAllBrands();
      const brandOptions = brandList.map((item) => ({
        label: item,
        value: item,
      }));

      setOptions([defaultOption, ...brandOptions]);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  return (
    <>
      <Select<Vehicle['brand']>
        onChange={onChangeSelect}
        value={option}
        label="Выберите модель"
        placeholder="Выберите"
        options={options}
      />
    </>
  );
}

export default memo(BrandSelect);
