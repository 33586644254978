import { setMask } from '@app/helpers';
import { IconClear, IconCloseEye, IconEye } from '@app/icons';
import {
  FC,
  Fragment,
  KeyboardEventHandler,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

const TextField = styled.input`
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
  height: 52px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 16px;
  padding-right: 40px;
  padding-bottom: 8px;
  border: 1px solid transparent;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0a0a0a;
  &:focus {
    outline: none;
    border: 1px solid #c3dff7;
  }
`;

const Label = styled.label`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  position: relative;
`;

const Title = styled.p`
  color: #757575;
  position: absolute;
  left: 16px;
  top: 4px;
  padding: 0;
  margin: 0;
`;

const PasswordIcon = styled.span`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111111;
  svg {
    z-index: 111111;
    cursor: pointer;
    path {
      stroke: #c3dff7;
    }
  }
`;

const ClearIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111111;
  svg {
    z-index: 111111;
    cursor: pointer;
  }
`;

const ErrorTitle = styled.p`
  width: 100%;
  margin: 0px;
  padding: 0px;
  z-index: 111111;
  color: red;
`;

interface IInputField {
  className?: string;
  title: string;
  value: string;
  placeholder?: string;
  onChange?: (value: string, name?: string) => void;
  helperText?: string;
  error?: boolean;
  mask?: string;
  secureTextEntry?: boolean;
  name?: string;
  editable?: boolean;
  onClick?: () => void;
  endIcons?: ReactNode[];
  onSendMessage?: () => void;
}

function unSetMask(value: string): string {
  return value.replace(/\D/g, '');
}

const InputField: FC<IInputField> = memo(
  ({
    secureTextEntry = false,
    title = '',
    className = '',
    value,
    onChange,
    placeholder,
    helperText = '',
    error = false,
    mask,
    name,
    editable = true,
    endIcons,
    onClick,
    onSendMessage,
  }) => {
    const [showHiddenText, setShowHiddenText] = useState<boolean>(false);
    const inputElement = useRef<HTMLInputElement>(null);

    const valueText = useMemo<string>(() => {
      return mask ? setMask(value || '', mask.trim()) : value || '';
    }, [mask, value]);

    const onClickClear = useCallback(() => {
      if (!onChange) {
        return;
      }

      onChange('', name);
    }, [onChange, name]);

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key === 'Enter') {
        if (!!onSendMessage) {
          onSendMessage();
        }
      }
    };

    const onChangeInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!editable || !onChange) {
          return;
        }
        const { value: inputValue } = e.target;

        if (!mask) {
          onChange(inputValue, name as string);

          return;
        }

        if (inputValue.length <= mask.trim().length) {
          onChange(unSetMask(inputValue), name as string);
        }
      },
      [editable, mask, name, onChange]
    );

    const onClickInput = useCallback(
      (e: React.MouseEvent<HTMLInputElement>) => {
        if (!!onClick) {
          e.preventDefault();
          e.stopPropagation();

          if (!!inputElement.current) {
            inputElement.current.blur();
          }

          onClick();
        }
      },
      [onClick]
    );

    const onChangeType = useCallback(() => {
      setShowHiddenText((prevShowHidden) => !prevShowHidden);
    }, []);

    return (
      <>
        <Label className={`signin-label ${className}`}>
          <Title className={`signin-label__title ${className}`}>{title}</Title>

          <TextField
            type={secureTextEntry && !showHiddenText ? 'password' : 'text'}
            className={`signin-label__input ${className}`}
            onChange={onChangeInput}
            value={valueText}
            disabled={!editable}
            placeholder={placeholder}
            onClick={onClickInput}
            onKeyDown={onKeyDown}
          />
          {secureTextEntry && (
            <>
              {showHiddenText ? (
                <PasswordIcon
                  onClick={onChangeType}
                  className={`signin-label__input-close-eye ${className}`}
                >
                  <IconCloseEye />
                </PasswordIcon>
              ) : (
                <PasswordIcon
                  onClick={onChangeType}
                  className={`signin-label__input-open-eye ${className}`}
                >
                  <IconEye />
                </PasswordIcon>
              )}
            </>
          )}
          {!secureTextEntry && !!valueText && (
            <ClearIcon
              onClick={onClickClear}
              className={`signin-label__input-clear ${className}`}
            >
              <IconClear />
            </ClearIcon>
          )}
          {endIcons &&
            endIcons.map((endIcon, endIconIndex) => (
              <Fragment key={endIconIndex.toString()}>{endIcon}</Fragment>
            ))}
        </Label>
        {!!helperText && (
          <ErrorTitle className={`signin-label__input-error ${className}`}>
            {helperText}
          </ErrorTitle>
        )}
      </>
    );
  }
);

export default InputField;
