import React, { memo, useCallback, useEffect, useState } from 'react';
import { Company } from '@app/models';
import { getCompanyByBin } from '@app/api';
import { ProfileCompany } from '@app/common';
import { Loader } from '@app/components';
import { useUserContext } from '@app/providers';

interface Props {}

function PageProfileCompany(props: Props) {
  const { currentProfile } = useUserContext();
  const [error, setError] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(true);
  const [company, setCompany] = useState<Company | null>(null);

  const onUpdateCompany = useCallback((updatedData: Partial<Company>) => {
    setCompany((prevCompany) => ({
      ...(prevCompany ? prevCompany : {}),
      ...(updatedData as Company),
    }));
  }, []);

  const getData = useCallback(async () => {
    try {
      if (currentProfile.currentType === 'user') return;
      const response = await getCompanyByBin(currentProfile.bin as string);

      setCompany(response);
      setError(false);
      setPending(false);
    } catch (e) {
      setPending(false);
      setError(true);
    }
  }, [currentProfile]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (pending) {
    return <Loader />;
  }

  if (!company) {
    return null;
  }

  return (
    <ProfileCompany
      company={company}
      updateCompany={onUpdateCompany}
      editable={true}
    />
  );
}

export default memo(PageProfileCompany);
