import React, { memo, useEffect } from 'react';
import { Paper } from '@app/components';
import useNcaLayer, { DN } from '../../libs/NcaLayer/hooks';
import { IconFilePlus24 } from '@app/icons';
import './styles.scss';

interface Props {
  onChange: (dn: DN) => void;
}

function NCALayer(props: Props) {
  const { onChange } = props;
  const { connected, chooseCertificate, isExpired, dn } = useNcaLayer();

  useEffect(() => {
    if (!!dn.email) {
      onChange(dn);
    }
  }, [onChange, dn]);

  return (
    <Paper>
      <div className="smr-nca-layer">
        {isExpired && (
          <p className="smr-nca-layer__text">
            Срок действия сертификата ЭЦП истек. Вам необходимо получить новый
            сертификат ЖЦП
          </p>
        )}
        {!connected ? (
          <p className="smr-nca-layer__text">NcaLayer не подключен</p>
        ) : (
          <button className="smr-nca-layer__button" onClick={chooseCertificate}>
            <IconFilePlus24 />
            <span>Выбрать сертификат ЭЦП</span>
          </button>
        )}
      </div>
    </Paper>
  );
}

export default memo(NCALayer);
