import React, { memo, useEffect, useState } from 'react';
import './styles.scss';
import { IconTimer } from '@app/icons';
import { useTimer } from '../../../../libs';
import { dateFormat } from '@app/helpers';
import { VehicleStatus, VehicleStatusNames } from '@app/models';

interface Props {
  timer?: string | number;
  free?: VehicleStatus;
  inLine?: boolean;
}

function CardStatus(props: Props) {
  const { timer = '', free, inLine = false } = props;
  const time = useTimer(timer);

  const [state, setState] = useState({
    label: `Завершен: ${dateFormat(new Date(timer).toJSON())}`,
    className: 'busy',
  });

  useEffect(() => {
    if (!!free) {
      setState({
        label: VehicleStatusNames[free],
        className: free === VehicleStatus.FREE ? 'free' : 'busy',
      });
    } else if (time().length < 1) {
      setState({
        label: `Завершен: ${dateFormat(new Date(timer).toJSON())}`,
        className: 'busy',
      });
    }
  }, [free]);

  return (
    <>
      {!free && time().length > 0 ? (
        <span
          className={`smr-card__timer-grid free ${inLine ? '' : 'forGrid'}`}
        >
          <IconTimer />
          Ост: {time()}
        </span>
      ) : (
        <span
          className={`smr-card__timer-grid ${state.className} ${
            inLine ? '' : 'forGrid'
          }`}
        >
          {state.label}
        </span>
      )}
    </>
  );
}

export default memo(CardStatus);
