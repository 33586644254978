import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { genericMemo } from '@app/helpers';
import './styles.scss';

export interface SelectOption<T = string> {
  label: string;
  value: T;
}

export interface SelectProps<T = unknown> {
  label: string;
  value: SelectOption<T> | null;
  placeholder?: string;
  onChange: (value: SelectOption<T> | null, name?: string) => void;
  helperText?: string;
  error?: boolean;
  options: SelectOption<T>[];
  name?: string;
  valueKey?: keyof T;
  required?: boolean;
  defaultLabel?: string;
}

function Select<T = unknown>(props: SelectProps<T>) {
  const {
    label,
    value: propsValue,
    onChange,
    placeholder,
    helperText = '',
    error = false,
    options,
    name,
    valueKey = '',
    required = false,
    defaultLabel,
  } = props;

  const getOptionValue = useCallback(
    (option: SelectOption<T>) => {
      if (!!valueKey) {
        return option.value[valueKey as keyof T] as string;
      }

      return option.value as string;
    },
    [valueKey]
  );

  const onChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectValue = e.target.value;

      if (!selectValue) {
        onChange(null, name);
      } else {
        if (!valueKey) {
          onChange(
            options.find(
              (option) => (option.value as any).toString() === selectValue
            ) || null,
            name
          );
        } else {
          onChange(
            options.find((option) => option.value[valueKey] === selectValue) ||
              null,
            name
          );
        }
      }
    },
    [name, valueKey, onChange, options]
  );

  const value = useMemo(() => {
    if (!propsValue) {
      return '';
    }

    return getOptionValue(propsValue);
  }, [getOptionValue, propsValue]);

  return (
    <div
      className={classNames('smr-select', {
        'smr-select--error': error,
      })}
    >
      <label className="smr-select__label">
        {label}
        {required ? ' *' : ''}
      </label>
      <select
        name={name}
        onChange={onChangeSelect}
        className="smr-select__select"
        value={value}
      >
        <option value="" disabled={!defaultLabel}>
          {defaultLabel || placeholder}
        </option>
        {options.map((option, optionIndex) => (
          <option key={optionIndex.toString()} value={getOptionValue(option)}>
            {option.label}
          </option>
        ))}
      </select>
      {!!helperText && <p className="smr-select__helper-text">{helperText}</p>}
    </div>
  );
}

export default genericMemo(Select);
