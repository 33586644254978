import React, { memo, useCallback } from 'react';
import './styles.scss';
import classNames from 'classnames';

interface Props {
  label: string;
  checked: boolean;
  variant?: 'checkbox' | 'radio';
  onChange: (value: boolean) => void;
  disabled?: boolean;
  typeSelect?: boolean;
}

function Checkbox(props: Props) {
  const {
    label,
    checked,
    variant = 'checkbox',
    onChange,
    disabled = false,
    typeSelect = false,
  } = props;

  const onChangeInput = useCallback(() => {}, []);

  const onClickLabel = useCallback(
    (e: React.MouseEvent<HTMLLabelElement>) => {
      e.preventDefault();
      // e.stopPropagation();

      if (disabled) {
        return;
      }

      if (variant === 'radio' && checked) {
        return;
      }

      onChange(!checked);
    },
    [checked, disabled, onChange, variant]
  );

  return (
    <label
      className={classNames('smr-checkbox', {
        'smr-checkbox--radio': variant === 'radio',
        'smr-checkbox--checkbox': variant === 'checkbox',
        'smr-checkbox--disabled': disabled,
        'smr-checkbox--oked': typeSelect,
      })}
      onClick={onClickLabel}
    >
      <input
        type="checkbox"
        checked={checked}
        className="smr-checkbox__input"
        onChange={onChangeInput}
      />
      <span className="smr-checkbox__placeholder" />
      <p className="smr-checkbox__label">{label}</p>
    </label>
  );
}

export default memo(Checkbox);
