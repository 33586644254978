import React, { memo, useEffect, useState } from 'react';
import { Container, Loader } from '@app/components';
import { UserType } from '@app/models';
import { LayoutDefault } from '@app/layouts';
import { UserForm, UserFormData } from './components';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import { InviteDN } from './components/User/User';
import { getInviteDto, getKatoByCode } from '@app/api';
import { parseFullName } from '@app/helpers';
import styled from 'styled-components';

function InviteSignUpPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invite = queryParams.get('invite');
  const [dn, setDn] = useState<InviteDN | null>(null);

  // TODO: грязный код, вынести в отдельный хук
  useEffect(() => {
    if (invite === null) return;

    const inviteDto = async () => {
      const response = await getInviteDto(invite);

      const { fullName, ...rest } = response;

      const userNameData = parseFullName(fullName ?? '');

      let kato;
      if (rest.katoCode) {
        kato = await getKatoByCode(rest.katoCode);
      }

      setDn({ ...userNameData, ...rest, kato } as InviteDN);
    };

    inviteDto();
  }, [invite]);

  // Пока оставляем так, но нужно сделать эту функцию опциональным в UserForm
  const onSubmitUserForm = (data: UserFormData) => {
    return void 0;
  };

  return (
    <LayoutDefault className="p-sign-up">
      <Container className="p-sign-up__container">
        {invite && dn === null ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <UserForm
            userType={UserType.ENTITY}
            dn={dn}
            defaultExpanded
            onSubmit={onSubmitUserForm}
            isInvite
          />
        )}
      </Container>
    </LayoutDefault>
  );
}

const LoaderWrapper = styled.div`
  width: 100%;
  margin: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default memo(InviteSignUpPage);
