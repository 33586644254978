import React, { memo, useCallback, useEffect, useState } from 'react';
import Select, { SelectOption } from '../Select';
import { Oked } from '@app/models';
import { OkedModal } from '../../modals';
import TextField, { TextFieldProps } from '../TextField';
import './styles.scss';
import { getOkedList, getParentOkedList } from '@app/api';

interface OkedSelectProps {
  parentNumber?: number;
  onChange: (value: SelectOption<Oked>) => void;
}

function OkedSelect(props: OkedSelectProps) {
  const { parentNumber = null, onChange } = props;
  const [pending, setPending] = useState<boolean>(true);
  const [option, setOption] = useState<SelectOption<Oked> | null>(null);
  const [options, setOptions] = useState<SelectOption<Oked>[]>([]);

  const onChangeSelect = useCallback(
    (selectedOption: SelectOption<Oked> | null) => {
      setOption(selectedOption);
      onChange(selectedOption as SelectOption<Oked>);
    },
    [onChange]
  );

  const getData = useCallback(async () => {
    try {
      const okedList = !parentNumber
        ? await getParentOkedList()
        : await getOkedList({
            parentNumber,
          });

      setOptions(
        okedList.map((item) => ({
          label: item.nameRu,
          value: item,
        }))
      );
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [parentNumber]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  if (!pending && options.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridGap: '16px',
      }}
    >
      <Select<Oked>
        onChange={onChangeSelect}
        value={option}
        valueKey="id"
        label="Выберите раздел"
        placeholder="Выберите"
        options={options}
      />
      {option && (
        <OkedSelect parentNumber={option.value.number} onChange={onChange} />
      )}
    </div>
  );
}

interface OkedSelectTriggerProps
  extends Omit<
    Exclude<TextFieldProps, 'mask' | 'secureTextEntry' | 'editable'>,
    'onChange'
  > {
  onChange: (value: SelectOption<Oked>, name: string) => void;
}

export function OkedSelectTrigger(props: OkedSelectTriggerProps) {
  const { label, placeholder, value, error, helperText, onChange, name } =
    props;
  const [okedModalVisible, setOkedModalVisible] = useState<boolean>(false);

  const onClickShowOkedModal = useCallback(() => {
    setOkedModalVisible(true);
  }, []);

  const onCloseOkedModal = useCallback(() => {
    setOkedModalVisible(false);
  }, []);

  const onChangeOked = useCallback(
    (option?: SelectOption<Oked>) => {
      if (!!onChange) {
        onChange(option as SelectOption<Oked>, name as string);
      }
    },
    [name, onChange]
  );

  return (
    <>
      <TextField
        label={label}
        value={value}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        onClick={onClickShowOkedModal}
        name="fieldOfActivity"
      />
      <OkedModal
        visible={okedModalVisible}
        onChange={onChangeOked}
        onClose={onCloseOkedModal}
      />
    </>
  );
}

export default memo(OkedSelect);
