import React, { memo, useCallback, useEffect, useState } from 'react';
import Select, { SelectOption } from '../Select';
import { User } from '@app/models';
import { getSpecializations } from '@app/api';

interface Props {
  onChange: (value: string) => void;
  index?: number;
  item?: ISelectOption[];
  onSelectedLists?: (item: any[]) => void;
}

type Specialization<T> = T extends { specialization: infer S }
  ? S extends string
    ? S
    : never
  : never;

type UserSpecialization = Specialization<User>;

interface ISelectOption extends SelectOption<UserSpecialization> {}

const defaultOption = {
  label: 'Все' as const,
  value: 'all' as const,
};

function SpecializationSelect(props: Props) {
  const { onChange } = props;
  const [option, setOption] = useState<ISelectOption | null>(null);
  const [options, setOptions] = useState<ISelectOption[]>([]);
  const onChangeSelect = useCallback(
    (selectedOption: ISelectOption | null) => {
      setOption(selectedOption);

      const isSelectedAll = selectedOption?.value === defaultOption.value;
      const option = selectedOption?.value ?? '';

      onChange(isSelectedAll ? '' : option);
    },
    [onChange]
  );

  const getData = useCallback(async () => {
    try {
      setOptions([]);
      const list = (await getSpecializations()) ?? [];

      const options = list.map((item) => ({
        label: item ?? '',
        value: item ?? '',
      }));

      setOptions([defaultOption, ...options]);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  return (
    <>
      <Select<UserSpecialization>
        onChange={onChangeSelect}
        value={option}
        label="Выберите специализацию"
        placeholder="Выберите"
        options={options}
      />
    </>
  );
}

export default memo(SpecializationSelect);
