import { useCallback, useState } from 'react';
import { IPrivacy } from '../components/ListItems/ListItems';

interface IRetur<T> {
  newArray: T[];
  onToggleHandler: (index: number) => void;
}

const useToggle = <T extends IPrivacy>(arr: T[]): IRetur<T> => {
  const [newArray, setFaq] = useState<T[]>(arr);

  const onToggleHandler = useCallback(
    (index: number): void => {
      const newFaq = arr.map((item, i, arr) => {
        item.opened = false;
        if (i === index) {
          arr[index].opened = true;
        }
        return item;
      });
      setFaq(newFaq);
    },
    [arr]
  );

  return {
    newArray,
    onToggleHandler,
  };
};

export default useToggle;
