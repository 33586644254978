import { memo, useCallback, useMemo, useState } from 'react';
import { IconCloseArrow, IconSended } from '@app/icons';
import TextField from 'components/TextField';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { useForm } from '@app/forms';
import {
  Checkbox,
  KatoSelectTrigger,
  Modal,
  ModalProps,
  SelectOption,
} from '@app/components';
import { Kato } from '@app/models';
import { useUserContext } from 'providers/UserProvider';
import styles from './AddEmployee.module.scss';
import { postInvite } from '@app/api';

interface Props extends Partial<ModalProps> {}

interface IEmployee {
  iin: string;
  fullName: string;
  specialization: string;
  email: string;
}

function ModalAddEmployee(props: Props) {
  const { visible, onClose } = props;
  const [messageSended, onSendMessage] = useState(false);
  const user = useUserContext();
  const [isPending, setIsPending] = useState(false);
  const [kato, setKato] = useState<Kato | undefined>();

  const companyId = useMemo(() => user.company?.id, [user]);

  const onChangeKato = useCallback((option: SelectOption<Kato>) => {
    setKato(option.value);
  }, []);

  const onClearKato = useCallback(() => {
    setKato(undefined);
  }, []);

  // TODO: add validate and errors for form
  const { onChange, values } = useForm<IEmployee>({
    iin: '',
    fullName: '',
    specialization: '',
    email: '',
  });

  const onSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // TODO: add throw error
    if (companyId === undefined) return;

    try {
      await postInvite({ companyId, ...values, katoCode: kato?.code });
      setIsPending(true);
      setTimeout(() => {
        setIsPending(false);
      }, 1500);
      onSendMessage(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = useCallback(() => {
    onSendMessage(false);

    if (!!onClose) {
      onClose();
    }
  }, [onClose]);

  if (!visible) {
    return null;
  }

  return (
    <Modal visible={visible} onClose={onCloseModal}>
      <div className={styles.container}>
        <div className={messageSended ? styles.sended : styles.content}>
          {messageSended ? (
            <>
              {isPending ? (
                <span className={styles.loader}>
                  <Loader />
                </span>
              ) : (
                <>
                  <span className={styles.sendIcon}>
                    <IconSended />
                  </span>
                  <div className={styles.sendText}>Инвайт отправлен!</div>
                  <div className={styles.sendButton}>
                    <Button text="Понятно" onClick={onCloseModal} />
                  </div>
                </>
              )}
            </>
          ) : (
            <div className={styles.contentContainer}>
              <div className={styles.head}>
                <span className={styles.title}>Добавить сотрудника</span>
                <button className={styles.close} onClick={onCloseModal}>
                  <IconCloseArrow />
                </button>
              </div>
              <div className={styles.content}>
                <div className={styles.form}>
                  <div className={styles.title}>Данные</div>
                  <div className={styles.filter}>
                    <div>
                      <TextField
                        label="ИИН"
                        onChange={onChange}
                        name="iin"
                        placeholder="Введите ИИН"
                        mask="############"
                        value={values.iin}
                      />
                    </div>
                    <div>
                      <TextField
                        label="Ф.И.О."
                        placeholder="Введите Ф.И.О."
                        name="fullName"
                        value={values.fullName}
                        onChange={onChange}
                      />
                    </div>
                    <div>
                      <TextField
                        label="Сфера деятельности (должность)"
                        name="specialization"
                        placeholder="Введите должность"
                        value={values.specialization}
                        onChange={onChange}
                      />
                    </div>
                    <div>
                      <KatoSelectTrigger
                        label="регион"
                        value={!!kato ? kato.nameRu : ''}
                        placeholder="Выберите регион"
                        onChange={onChangeKato}
                        onClickClear={onClearKato}
                      />
                    </div>
                  </div>
                  <div>
                    <TextField
                      label="Email"
                      value={values.email}
                      name="email"
                      onChange={onChange}
                      placeholder="Введите электронный адрес (почту)"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className={styles.form}>
                <div className={styles.title}>Права</div>
                <div className={styles.roles}>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      variant="checkbox"
                      label="Создание/редактирование объявлений"
                      checked={false}
                      onChange={() => void 0}
                    />
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      variant="checkbox"
                      label="Принять/отклонить отклики"
                      checked={false}
                      onChange={() => void 0}
                    />
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      variant="checkbox"
                      label="Просмотр объявлений и откликов"
                      checked={false}
                      onChange={() => void 0}
                    />
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      variant="checkbox"
                      label="Просмотр документов"
                      checked={false}
                      onChange={() => void 0}
                    />
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      variant="checkbox"
                      label="Откликаться на объявления от имени компании"
                      checked={false}
                      onChange={() => void 0}
                    />
                  </div>
                  <div>
                    <Checkbox
                      variant="checkbox"
                      label="Добавлять новых сотрудников"
                      checked={false}
                      onChange={() => void 0}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.input}>
                <form onSubmit={onSubmitForm}>
                  <div className={styles.buttonContainer}>
                    <Button
                      text="Добавить"
                      className={styles.button}
                      type="submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalAddEmployee);
