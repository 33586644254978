import React, { memo, ReactNode, useCallback } from 'react';
import { Tender, TenderCategory } from '@app/models';
import { RenderFilterProps, SearchTemplate } from '@app/templates';
import { getTenders, PageableParams } from '@app/api';
import { TenderCard } from '@app/common';
import cmpFallbackImage from '../../assets/images/cmp_fallback.svg';
import materialFallbackImage from '../../assets/images/material.png';
import { TendersFilter } from '@app/forms';

interface Props {
  params?: Partial<Tender>;
  tenderCategory: TenderCategory;
  columns?: number;
  onClick?: (id: string) => void;
  title?: string;
  controls?: ReactNode[];
  refreshEventName?: string;
  emptyTitle?: string;
}

function TenderSearch(props: Props) {
  const {
    params: defaultParams,
    refreshEventName,
    columns = 3,
    tenderCategory,
    onClick,
    title,
    controls,
    emptyTitle = 'Вы еще не откликались на преложения специалистов',
  } = props;

  const renderItem = useCallback(
    (tender: Tender, horizontal?: boolean) => {
      const isSmr = tender.category === TenderCategory.CONTRACTOR;

      return (
        <TenderCard
          tender={tender}
          horizontal={horizontal}
          fallbackImage={isSmr ? cmpFallbackImage : materialFallbackImage}
          onClick={onClick}
        />
      );
    },
    [onClick]
  );

  const getData = useCallback(
    (params: PageableParams, filter?: Partial<Tender>) => {
      return getTenders({
        ...params,
        category: tenderCategory,
        ...defaultParams,
        ...filter,
      });
    },
    [defaultParams, tenderCategory]
  );
  const renderFilter = useCallback(
    (filterProps: RenderFilterProps<Partial<Tender>>) => {
      return <TendersFilter {...filterProps} />;
    },
    []
  );

  return (
    <SearchTemplate<Tender, Partial<Tender>>
      renderItem={renderItem}
      getData={getData}
      renderFilter={renderFilter}
      columns={columns}
      initialFilter={{}}
      variant="default"
      refreshEventName={refreshEventName}
      title={title}
      controls={controls}
      emptyTitle={emptyTitle}
    />
  );
}

export default memo(TenderSearch);
