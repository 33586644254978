import React, { memo } from 'react';
import styled from 'styled-components';
import { prop, theme } from 'styled-tools';

interface Props {
  offset?: number;
}

const StyledDivider = styled.div<{
  offset: number;
}>`
  height: 1px;
  width: 100%;
  display: block;
  background-color: ${theme('color.grayLight')};
  margin: ${prop('offset')} 0;
`;

function Divider(props: Props) {
  const { offset = 0 } = props;

  return <StyledDivider offset={offset} />;
}

export default memo(Divider);
