import React, { memo, useCallback } from 'react';
import {
  Reply as ReplyModel,
  ReplyStatus,
  ReplyStatusStatusName,
} from '@app/models';
import { Info } from '@app/components';
import { currencyFormat, dateFormat, fullName } from '@app/helpers';
import { IconFlag } from '@app/icons';
import styled from 'styled-components';
import { switchProp } from 'styled-tools';

interface Props {
  reply: ReplyModel;
  onClick: (reply: ReplyModel) => void;
}

const StyledReply = styled.div`
  padding: 16px;
  box-sizing: border-box;
  gap: 16px;
  background: #f5f5f5;
  border-radius: 10px;
  display: flex;

  > * + * {
    margin-left: 20px;
  }
`;

const StyledReplyInfo = styled.div`
  gap: 16px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 150px repeat(4, 200px);
  align-items: center;
  width: 100%;

  > * + * {
    margin-left: 20px;
  }
`;

const Status = styled.div<{
  status: ReplyStatus;
}>`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background: ${switchProp('status', {
      10: '#3A57E8',
      20: '#25AA51',
      30: '#BA1B1B',
    })};
    display: block;
    border-radius: 50%;
  }
`;

const StatusText = styled.p<{
  status: ReplyStatus;
}>`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${switchProp('status', {
    10: '#3A57E8',
    20: '#25AA51',
    30: '#BA1B1B',
  })};
  margin: 0 0 0 4px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  svg {
    margin: 0;
    width: 26px;
    height: 26px;
    path {
      stroke: #3a57e8;
    }
  }
`;

function Reply(props: Props) {
  const { reply, onClick } = props;

  const onClickReply = useCallback(() => {
    onClick(reply);
  }, [onClick, reply]);

  return (
    <StyledReply key={reply.id}>
      <StyledReplyInfo onClick={onClickReply}>
        <Status status={reply.status}>
          <StatusText status={reply.status}>
            {ReplyStatusStatusName[reply.status]}
          </StatusText>
        </Status>
        <Info
          label="Автор"
          value={reply?.companyId ? reply.company?.name : fullName(reply.owner)}
          small={true}
        />
        <Info
          label="Дата подачи"
          value={dateFormat(reply.createdAt)}
          small={true}
          displayedLength={22}
        />
        <Info label="Цена" value={currencyFormat(reply.price)} small={true} />
        <Info
          label="Статус"
          value={ReplyStatusStatusName[reply.status]}
          small={true}
          displayedLength={22}
        />
      </StyledReplyInfo>
      {reply.pinned && (
        <IconWrapper>
          <IconFlag />
        </IconWrapper>
      )}
    </StyledReply>
  );
}

export default memo(Reply);
