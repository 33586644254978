import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { getFileById } from '@app/api';

interface Props {
  placeholder: string;
  imageIds: string[];
  className?: string;
}

const StyledLoadableImage = styled.img<{ isSvg: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function LoadableImage(props: Props) {
  const { imageIds, placeholder, className = '' } = props;
  const imageRef = useRef<HTMLImageElement>(null);

  const mainImageId = useMemo<string | null>(
    () => imageIds[0] || null,
    [imageIds]
  );

  const getImage = useCallback(async () => {
    try {
      if (!mainImageId || !imageRef.current) {
        return;
      }

      const image = await getFileById(mainImageId);

      imageRef.current.src = URL.createObjectURL(image);
    } catch (e) {}
  }, [mainImageId]);

  useEffect(() => {
    getImage();
  }, [getImage]);

  return (
    <StyledLoadableImage
      className={className}
      src={placeholder}
      ref={imageRef}
      isSvg={Boolean(imageRef?.current?.src.slice(-4))}
    />
  );
}

export default memo(LoadableImage);
