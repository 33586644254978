import React, { memo } from 'react';
import './styles.scss';
import NavLink from './NavLink';

export interface NavigationItem {
  title: string;
  to: string;
  icon?: React.ReactNode;
}

interface NavigationProps {
  items: NavigationItem[];
}

function Navigation(props: NavigationProps) {
  const { items } = props;
  return (
    <nav className="b-nav">
      <ul className="b-nav__list">
        {items.map((item, itemIndex) => (
          <NavLink
            key={itemIndex.toString()}
            icon={item.icon}
            title={item.title}
            to={item.to}
          />
        ))}
      </ul>
    </nav>
  );
}

export default memo(Navigation);
