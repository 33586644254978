import React, { FC, Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import CardPrice from '../CardPrice';
import { VehicleStatus } from '@app/models';
import { CardStatus } from '../index';

const StyledFooterLeft = styled.div`
  flex: 0 0 50%;
  width: 189px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 8px;
`;

const StyledFooterTop = styled.span`
  height: 28px;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 4px;
`;

const StyledFooterInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #757575;
  margin-bottom: 4px;
`;

const StyledFooterInfoInn = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #0a0a0a;
`;

const StyledFooterContent = styled.div`
  flex-grow: 0;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const StyledFooterBtn = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  > button + button,
  button + a,
  a + button {
    margin-left: 8px;
  }
`;

const StyledFooterViews = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #757575;
  margin-bottom: 4px;
`;

interface ICardInlineLeft {
  price: string;
  tenderType: number | undefined;
  actions?: ReactNode[];
  endDate?: string;
  free?: VehicleStatus;
  publishDate?: string;
}

const CardInlineLeft: FC<ICardInlineLeft> = ({
  price,
  tenderType,
  actions,
  endDate,
  free,
  publishDate,
}) => {
  return (
    <StyledFooterContent className="smr-card__footer-content">
      <StyledFooterLeft className="smr-card__footer-left">
        <StyledFooterTop className="smr-card__footer-top">
          {(endDate || free) && (
            <CardStatus timer={endDate} free={free} inLine={true} />
          )}
        </StyledFooterTop>
        <StyledFooterInfo className="smr-card__footer-info">
          <StyledFooterInfoInn className="smr-card__footer-inn">
            {/*№10101010, */}
          </StyledFooterInfoInn>
          {publishDate || ''}
        </StyledFooterInfo>
        <StyledFooterViews className="smr-card__footer-views">
          {/*Просмотры: 263*/}
        </StyledFooterViews>
        <CardPrice text={price} tenderType={tenderType} />
      </StyledFooterLeft>
      <StyledFooterBtn>
        {actions?.map((action, actionIndex) => (
          <Fragment key={actionIndex}>{action}</Fragment>
        ))}
      </StyledFooterBtn>
    </StyledFooterContent>
  );
};

export default CardInlineLeft;
