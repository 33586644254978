import React, { memo } from 'react';
import { Info } from '@app/components';
import { User, UserScheduleNames } from '@app/models';
import { useTranslation } from 'react-i18next';

interface Props {
  user: User;
}

function ProfileUserAdditionalInfo(props: Props) {
  const { user } = props;
  const { t } = useTranslation();

  return (
    <>
      <Info
        label={t('fieldOfActivity')}
        value={user.specialization ? user.specialization : t('notSpecified')}
      />
      <Info label="Занятость" value={t('fullTime')} />
      <Info
        label={t('schedule')}
        value={
          !!user.schedule ? UserScheduleNames[user.schedule] : t('notSpecified')
        }
      />
      <Info
        label={t('readyForBusinessTrips')}
        value={
          user.readyForBusinessTrips !== null
            ? user.readyForBusinessTrips
              ? t('yes')
              : t('no')
            : t('notSpecified')
        }
      />
      <Info
        label={t('additionalInformation')}
        value={
          !!user.additionalInformation
            ? user.additionalInformation
            : t('notSpecified')
        }
      />
    </>
  );
}

export default memo(ProfileUserAdditionalInfo);
