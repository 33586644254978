import React, { memo } from 'react';
import styled from 'styled-components';
import './styles.scss';
interface IHorizontal {
  horizontal?: boolean;
}
const StyledTitle = styled.p<IHorizontal>`
  font-family: $fontFamily;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $colorText;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.horizontal ? 1 : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: ${(props) => (props.horizontal ? '576px' : '100%')};
`;

interface Props {
  text: string;
  horizontal?: boolean;
}

function CardTitle(props: Props) {
  const { text, horizontal } = props;

  return (
    <StyledTitle className="smr-card__title" horizontal={horizontal}>
      {text}
    </StyledTitle>
  );
}

export default memo(CardTitle);
