import React from 'react';
import styled from 'styled-components';
import { ProfileAvatar } from '../../../../pages/Profile/components';

const StyledCardCompany = styled.div`
  margin-top: 8px;
  display: flex;
`;

const StyledThum = styled.div`
  height: 40px;
  width: 40px;
  margin-right: 12px;
`;
const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledContent = styled.div``;

const StyledName = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #0a0a0a;
  margin: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledOwner = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #757575;
  margin: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export interface Props {
  image?: string;
  name: string;
  owner: string;
  type?: any;
}

function CardAuthor(props: Props) {
  const { name, owner, image, type } = props;

  return (
    <StyledCardCompany className="smr-card__company company">
      <StyledThum className="company__thumb">
        <ProfileAvatar size={40} avatarFiles={image} />
      </StyledThum>
      <StyledContent className="company__content">
        <StyledName className="company__name">{name}</StyledName>
        <StyledOwner className="company__owner">{owner}</StyledOwner>
      </StyledContent>
    </StyledCardCompany>
  );
}

export default CardAuthor;
