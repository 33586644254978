import { DatabaseFile } from 'models/DatabaseFile';
import instance from './instance';

export function getFileById(fileId: string): Promise<Blob> {
  return instance()
    .get(`/files/getById/${fileId}`, {
      responseType: 'blob',
    })
    .then((response) => response.data);
}

export function getFileInfo(fileId: string): Promise<DatabaseFile> {
  return instance()
    .get(`/files/getFileInfo/${fileId}`)
    .then((response) => response.data);
}

export function getFileHash(fileId: string): Promise<string> {
  return instance()
    .get(`/files/getHash/${fileId}`)
    .then((response) => response.data);
}
