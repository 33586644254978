import React, { memo, ReactNode, useCallback, useMemo } from 'react';
import { Tender } from '@app/models';
import {
  Card,
  CardFavoriteButton,
  CardHideActiveButton,
  CardReplyCount,
} from '@app/components';
import {
  getCompanyTypeShortName,
  getFormattedDate,
  shotName,
} from '@app/helpers';
import { IconCalendar, IconMapMarker, IconWrench } from '@app/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from '@app/providers';
import {
  activateTender,
  addToFavorites,
  deleteFromFavorites,
  hideTender,
} from '@app/api';

interface Props {
  tender: Tender;
  horizontal?: boolean;
  onClick?: (id: string) => void;
  fallbackImage?: string;
  onChangeFavorite?: (id: string, isFavorite: boolean) => void;
  hideThumbnail?: boolean;
}

function TenderCard(props: Props) {
  const {
    tender,
    horizontal,
    onClick,
    fallbackImage,
    onChangeFavorite,
    hideThumbnail,
  } = props;
  const { owner, oked, kato, company, active, id, isFavorite } = tender;
  const { user, showSignInModal } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const isProfile = location.pathname.includes('profile');

  const onClickTender = useCallback(
    (id: string) => () => {
      if (!!onClick) {
        // onClick(id);
        navigate(`/profile/tender/${id}`); // todo: плохое решение, но я не понял как заставить onClick(id) работать
        return;
      }

      navigate(`/tender/${id}`);
    },
    [navigate, onClick]
  );

  const onClickAddFavorite = useCallback(
    async (isFavorite: boolean) => {
      if (!user.authenticated) {
        showSignInModal();

        return;
      }

      isFavorite
        ? await addToFavorites({
            tenderId: tender.id,
          })
        : await deleteFromFavorites({
            tenderId: tender.id,
          });

      if (!!onChangeFavorite) {
        onChangeFavorite(tender.id, isFavorite);
      }
    },

    [onChangeFavorite, showSignInModal, tender.id, user.authenticated]
  );

  const onClickHideActive = useCallback(
    (isActive: boolean) => {
      if (!user.authenticated) {
        return;
      }

      isActive ? activateTender(tender.id) : hideTender(tender.id);
    },

    [tender.id, user.authenticated]
  );

  const actionButtons = useMemo<ReactNode[]>(() => {
    if (!tender) {
      return [];
    }

    const returnActionButtons = [
      <CardFavoriteButton
        onClickFavorite={onClickAddFavorite}
        isFavorite={isFavorite}
      />,
      <CardReplyCount count={tender.replies.length} />,
    ];
    if (isProfile)
      returnActionButtons.push(
        <CardHideActiveButton
          onClickHideActive={onClickHideActive}
          isActive={active}
        />
      );

    return [...returnActionButtons];
  }, [
    active,
    isFavorite,
    isProfile,
    onClickAddFavorite,
    onClickHideActive,
    tender,
  ]);

  const imageIds = useMemo(() => {
    return tender.imagesIds ? tender.imagesIds.split(',') : [];
  }, [tender.imagesIds]);

  const authorInfo = useMemo(() => {
    const author = !!company
      ? {
          name: `${getCompanyTypeShortName(company.type)} ${company.name}`,
          image: !!company.avatarFilesIds
            ? company.avatarFilesIds.split(',')[0]
            : '',
        }
      : {
          name: 'Физическое лицо',
          image: !!owner.avatarFilesIds
            ? owner.avatarFilesIds.split(',')[0]
            : '',
        };

    return {
      ...author,
      owner: shotName(owner),
      type: owner.type,
    };
  }, [company, owner]);

  const infos = useMemo(
    () => [
      { icon: IconWrench, text: oked ? oked.nameRu : 'Не указан' },
      {
        icon: IconMapMarker,
        text: kato.nameRu,
      },
      {
        icon: IconCalendar,
        text: `Срок поставки: ${getFormattedDate(tender.deliveryDate)}`,
      },
      {
        icon: IconCalendar,
        text: `Дата публикации: ${getFormattedDate(tender.createdAt)}`,
      },
    ],
    [kato.nameRu, oked, tender.createdAt, tender.deliveryDate]
  );

  return (
    <Card
      imagesIds={imageIds}
      fallbackImage={fallbackImage}
      onClick={onClickTender(tender.id)}
      horizontal={horizontal}
      title={tender.name}
      description={tender.description}
      price={tender.price}
      rating={tender.rating}
      authorInfo={authorInfo}
      infos={infos}
      endDate={tender.endDate}
      tenderType={tender.type}
      actions={actionButtons}
      hideThumbnail={hideThumbnail}
      publishDate={getFormattedDate(tender.createdAt)}
    />
  );
}

export default memo(TenderCard);
