import axios from 'axios';
import Cookies from 'js-cookie';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: `${REACT_APP_API_URL}/api/v1`,
  withCredentials: true,
});

function instance(authorization = true, url?: string) {
  if (authorization) {
    axiosInstance.interceptors.request.use(
      async (config) => {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${Cookies.get('accessToken')}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        if (error.response.status === 401) {
          // TODO update token
          Cookies.remove('accessToken');

          // window.location.href = '/';
        }

        return Promise.reject(error);
      }
    );
  }

  return axiosInstance;
}

export default instance;
