import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Notification } from '@app/models';

interface NotificationFromDBContextValue {
  notifications: Notification[];
  setNotifications: (notifications: Notification[]) => void;
}

const NotificationFromDBContext = createContext<NotificationFromDBContextValue>(
  {} as NotificationFromDBContextValue
);

function NotificationFromDBProvider(props: PropsWithChildren) {
  const { children } = props;
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const setNotificationsData = useCallback((data: Notification[]) => {
    setNotifications(data);
  }, []);

  return (
    <NotificationFromDBContext.Provider
      value={{
        notifications,
        setNotifications: setNotificationsData,
      }}
    >
      <>{children}</>
    </NotificationFromDBContext.Provider>
  );
}

export function useNotificationFromDBContext(): NotificationFromDBContextValue {
  return useContext(NotificationFromDBContext);
}

export { NotificationFromDBProvider, NotificationFromDBContext };
