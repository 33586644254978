import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button } from '@app/components';
import { IconPlusCircle24 } from '@app/icons';
import { ModalAddPartner, ModalConfirm, ModalContract } from '@app/modals';
import { Doc, UserType } from '@app/models';
import { useUserContext } from '@app/providers';
import { PartnersSearch } from '@app/common';
import { useNavigate } from 'react-router-dom';

function PageProfilePartners() {
  const { user, company } = useUserContext();
  const [contract, setContract] = useState<Doc | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalContractVisible, setModalContractVisible] =
    useState<boolean>(false);
  const [modalConfirmVisible, setModalConfirmVisible] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const onCloseModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onCloseContractModal = useCallback(() => {
    setContract(null);
    setModalContractVisible(false);
  }, []);

  const onCloseModalConfirm = useCallback(() => {
    setModalConfirmVisible(false);
  }, []);

  const onClickModalConfirm = useCallback(() => {
    navigate('/profile/edit');
  }, [navigate]);

  const onAddPartner = useCallback((doc: Doc) => {
    setModalVisible(false);
    setContract(doc);
    setModalContractVisible(true);
  }, []);

  const onClickAddPartner = useCallback(() => {
    if (user.type === UserType.INDIVIDUAL && !user.iin) {
      setModalConfirmVisible(true);

      return;
    }

    setModalVisible(true);
  }, [user]);

  const updateContract = useCallback((data: Partial<Doc>) => {
    setContract((prevContract) => ({
      ...(prevContract as Doc),
      ...data,
    }));
  }, []);

  const controls = useMemo(
    () => [
      <Button
        text="Заключить договор с новым партнером"
        onClick={onClickAddPartner}
        icon={IconPlusCircle24}
      />,
    ],
    [onClickAddPartner]
  );

  return (
    <>
      <PartnersSearch title="Партнеры" controls={controls} />
      <ModalAddPartner
        visible={modalVisible}
        onClose={onCloseModal}
        onSuccess={onAddPartner}
        userType={user.type}
        companyId={user.type === UserType.ENTITY ? company!.id : null}
      />
      <ModalContract
        visible={modalContractVisible}
        updateContract={updateContract}
        contract={contract}
        onClose={onCloseContractModal}
      />
      <ModalConfirm
        onAccept={onClickModalConfirm}
        title={'Заполните ИИН в профиле'}
        textButtonCancel={'Закрыть'}
        textButtonSuccess={'Добавить ИИН в профиль'}
        visible={modalConfirmVisible}
        onClose={onCloseModalConfirm}
        skipSuccessStep={true}
        subText={
          'Для заключения договоров с партнерами необходимо заполнить ИИН в профиле'
        }
      />
    </>
  );
}

export default memo(PageProfilePartners);
