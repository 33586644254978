import { IconRatingStar } from '@app/icons';
import { Fragment, useState } from 'react';
import styles from './styles.module.scss';
import userIconDefaul from './user.png';

interface IComment {
  rating: number;
  likes: boolean;
  comments: IComents[];
  author: string;
  date: string;
  theme: string | null;
  organization: string | null;
  imgSrc: string;
}

interface IComents {
  value: string;
}

const Comment: React.FC<IComment> = ({
  rating = 0,
  likes = false,
  comments = [],
  author = '',
  date = '',
  theme = '',
  organization = '',
  imgSrc = '',
}) => {
  const [ratingArrUser, setRatingArrUser] = useState([1, 2, 3, 4, 5]);
  const [ratingUser, setRatingUser] = useState(rating);
  const [showComments, setShowComments] = useState(false);
  const [like, setLike] = useState(likes);

  const onShowComment = () => {
    setShowComments((prevState) => !prevState);
  };
  const onLikeTogle = () => {
    setLike((prevState) => !prevState);
  };

  return (
    <>
      <div className={styles.reviews}>
        <span className="p-profile__paper-tabs-portfolio-reviews-review">
          Отзыв
        </span>
        <div className="p-profile__paper-tabs-portfolio-reviews-review-rating">
          <span>{ratingUser}/5</span>
          <ul>
            {ratingArrUser.map((star, index) => {
              index += 1;
              return (
                <Fragment key={index}>
                  <li
                    className={
                      index <= ratingUser
                        ? 'p-profile__paper-tabs-portfolio-reviews-review--active'
                        : ''
                    }
                  >
                    <IconRatingStar />
                  </li>
                </Fragment>
              );
            })}
          </ul>
        </div>
        <div>
          <ul className="p-profile__paper-tabs-portfolio-reviews-lists">
            {comments.map((coment, i) => {
              return (
                <Fragment key={i}>
                  <li>{coment.value}</li>
                </Fragment>
              );
            })}
          </ul>
          <div className="p-profile__paper-tabs-portfolio-reviews-user">
            <div className="p-profile__paper-tabs-portfolio-reviews-info">
              <img
                src={imgSrc.length === 0 ? userIconDefaul : imgSrc}
                alt={author}
              />
              <div className="p-profile__paper-tabs-portfolio-reviews-info-lists">
                <div className="p-profile__paper-tabs-portfolio-reviews-user-info">
                  <span>Автор</span>
                  <b>{author}</b>
                </div>
                <div className="p-profile__paper-tabs-portfolio-reviews-info-lists-right">
                  <div className="p-profile__paper-tabs-portfolio-reviews-user-info">
                    <span>Дата подачи</span>
                    <b>{date}</b>
                  </div>
                  {!!theme && (
                    <div className="p-profile__paper-tabs-portfolio-reviews-user-info">
                      <span>Предмет договора</span>
                      <b>{theme}</b>
                    </div>
                  )}
                  {!!organization && (
                    <div className="p-profile__paper-tabs-portfolio-reviews-user-info">
                      <span>Организация</span>
                      <b>{organization}</b>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Comment;
