import instance from './instance';
import { Vehicle } from '@app/models';
import { PageableResponse } from './responses';
import { PageableParams } from './params';

export interface CreateVehicleData
  extends Pick<
    Vehicle,
    | 'name'
    | 'description'
    | 'price'
    | 'brand'
    | 'dateOfIssue'
    | 'type'
    | 'katoCode'
  > {
  files: File[];
  companyId?: string | null;
}

export function getVehicles(
  params: PageableParams & Partial<Vehicle>
): Promise<PageableResponse<Vehicle>> {
  return instance()
    .get('/vehicles/getAll', {
      params,
    })
    .then((response) => response.data);
}

export function getVehicleById(id: string): Promise<Vehicle> {
  return instance()
    .get(`/vehicles/getById/${id}`)
    .then((response) => response.data);
}

export function createVehicle(data: CreateVehicleData): Promise<Vehicle> {
  const formData = new FormData();

  Object.keys(data).forEach((dataKey) => {
    if (dataKey === 'files') {
      data[dataKey].forEach((file) => {
        formData.append(dataKey, file as Blob);
      });
    } else {
      if (data[dataKey as keyof CreateVehicleData] !== null) {
        formData.append(
          dataKey,
          data[dataKey as keyof CreateVehicleData] as string | Blob
        );
      }
    }
  });

  return instance()
    .post('/vehicles/create', formData)
    .then((response) => response.data);
}

export function getAllBrands(): Promise<Vehicle['brand'][]> {
  return instance()
    .get('/vehicles/getAllBrands')
    .then((response) => response.data);
}

export function getAllTypes(): Promise<Vehicle['type'][]> {
  return instance()
    .get('/vehicles/getAllTypes')
    .then((response) => response.data);
}

export function deleteVehicle(vehicleId: string): Promise<Vehicle> {
  return instance()
    .put(`/vehicles/delete/${vehicleId}`)
    .then((response) => response.data);
}

export function activateVehicle(vehicleId: string): Promise<Vehicle> {
  return instance()
    .put(`/vehicles/activate/${vehicleId}`)
    .then((response) => response.data);
}

export function hideVehicle(vehicleId: string): Promise<Vehicle> {
  return instance()
    .put(`/vehicles/hide/${vehicleId}`)
    .then((response) => response.data);
}
