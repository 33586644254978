import React, { memo, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { IconType } from '@app/icons';
import { theme } from 'styled-tools';

interface Props {
  label: string;
  value: string | ReactNode;
  small?: boolean;
  icon?: IconType;
  ellipsis?: boolean;
  displayedLength?: number;
}

const StyledInfo = styled.div`
  display: flex;
`;

const StyledIcon = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  color: ${theme('color.primary')};
  margin-right: 16px;
`;

const StyledContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledLabel = styled.p<Pick<Props, 'small'>>`
  font-weight: 400;
  font-size: ${(props) => (props.small ? '14px' : '16px')};
  line-height: ${(props) => (props.small ? '16px' : '24px')};
  letter-spacing: 0.1px;
  color: #757575;
  margin: 0 0 4px 0;
`;

const StyledValue = styled.p<Pick<Props, 'small' | 'ellipsis'>>`
  font-weight: 400;
  font-size: ${(props) => (props.small ? '16px' : '20px')};
  line-height: ${(props) => (props.small ? '19px' : '28px')};
  color: #0a0a0a;
  margin: 0;
  max-width: 1064px;
  word-wrap: break-word;
  /* display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; */
`;

function Info({
  label,
  value,
  displayedLength,
  small = false,
  ellipsis = false,
  icon: Icon,
}: Props) {
  const displayedValue = useMemo(() => {
    if (typeof value === 'string' && displayedLength) {
      const displayedValue = value.slice(0, displayedLength) + '...';
      return displayedValue;
    }
    return value;
  }, [displayedLength, value]);

  return (
    <StyledInfo>
      {!!Icon && (
        <StyledIcon>
          <Icon />
        </StyledIcon>
      )}
      <StyledContainer>
        <StyledLabel small={small}>{label}</StyledLabel>
        {typeof value === 'string' ? (
          <StyledValue small={small} title={value}>
            {displayedValue}
          </StyledValue>
        ) : (
          displayedValue
        )}
      </StyledContainer>
    </StyledInfo>
  );
}

export default memo(Info);
