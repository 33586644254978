import React, { memo, PropsWithChildren } from 'react';
import './styles.scss';
import classNames from 'classnames';

interface PanelProps {
  title?: string;
  className?: string;
  transparent?: boolean;
}

function Panel(props: PropsWithChildren<PanelProps>) {
  const { title, className = '', transparent = true, children } = props;

  return (
    <div
      className={classNames(`b-panel ${className}`.trim(), {
        'b-panel--transparent': transparent,
      })}
    >
      {!!title && (
        <div className="b-panel__header">
          <span className="b-panel__title">{title}</span>
        </div>
      )}
      {!!children && <div className="b-panel__content">{children}</div>}
    </div>
  );
}

export default memo(Panel);
