import React, { memo } from 'react';
import { TenderSearch } from '@app/common';
import { TenderCategory } from '@app/models';

interface Props {}

function ContractorsSearch(props: Props) {
  return (
    <TenderSearch
      params={{
        active: true,
      }}
      tenderCategory={TenderCategory.CONTRACTOR}
      refreshEventName="updateHomeSearch"
      emptyTitle="Вы еще не публиковали заявки в категории «СМР»"
    />
  );
}

export default memo(ContractorsSearch);
