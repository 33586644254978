import React, { memo, useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  KatoSelectTrigger,
  Select,
  SelectOption,
  SpecializationSelect,
} from '@app/components';
import { IconSearch } from '@app/icons';
import './styles.scss';
import { RenderFilterProps } from '@app/templates';
import {
  Kato,
  Tender,
  TenderTypes,
  User,
  UserStatus,
  UserStatusNames,
} from '@app/models';
import styled from 'styled-components';
import { useForm } from '../hooks';
import TendersFilter from '../TendersFilter/TendersFilter';
import { VehiclesFilterData } from '../VehiclesFilter';

interface FormData {
  kato: SelectOption<Kato> | null;
  status: SelectOption<UserStatus> | null;
  specialization: string;
}

export type UsersFilterData<T> = {
  tenderType: TenderTypes;
} & Partial<T>;

interface Props extends RenderFilterProps<UsersFilterData<Tender | User>> {}

const StyledVehiclesFilter = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledVehiclesFilterHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`;

const StyledVehiclesFilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

function UsersFilter(props: Props) {
  const { onSubmit, pending } = props;
  const [tenderType, setTenderType] = useState<TenderTypes>(
    TenderTypes.REQUEST
  );
  const { values, onChange, setValues } = useForm<FormData>({
    kato: null,
    status: null,
    specialization: '',
  });

  const onChangeTenderType = useCallback(
    (item: TenderTypes) => () => {
      setTenderType(item);

      onSubmit({
        tenderType: item,
      });
    },
    [onSubmit]
  );

  const onChangeSpecialization = useCallback(
    (value: string) => {
      onChange(value, 'specialization');
    },
    [onChange]
  );

  const onSubmitTendersFilter = useCallback(
    (tenderFilterData: Partial<Tender>) => {
      onSubmit({
        tenderType,
        ...tenderFilterData,
      });
    },
    [onSubmit, tenderType]
  );

  const onClickSearch = useCallback(() => {
    const params: VehiclesFilterData<User> = {
      tenderType,
    };

    if (!!values.kato) {
      params.katoCode = values.kato.value.code;
    }

    if (!!values.specialization) {
      params.specialization = values.specialization;
    }

    if (!!values.status) {
      params.status = values.status.value;
    }

    onSubmit(params);
  }, [onSubmit, tenderType, values.kato, values.specialization, values.status]);

  const onClickClear = useCallback(() => {
    setValues({
      kato: null,
      status: null,
      specialization: '',
    });

    onSubmit({
      tenderType,
    });
  }, [onSubmit, setValues, tenderType]);

  return (
    <StyledVehiclesFilter>
      <StyledVehiclesFilterHeader>
        <div>
          <Checkbox
            checked={tenderType === TenderTypes.REQUEST}
            variant="radio"
            label="Вакансии"
            onChange={onChangeTenderType(TenderTypes.REQUEST)}
          />
        </div>
        <div style={{ marginLeft: 16 }}>
          <Checkbox
            checked={tenderType === TenderTypes.OFFER}
            variant="radio"
            label="Список специалистов"
            onChange={onChangeTenderType(TenderTypes.OFFER)}
          />
        </div>
      </StyledVehiclesFilterHeader>
      {tenderType === TenderTypes.REQUEST && (
        <TendersFilter pending={pending} onSubmit={onSubmitTendersFilter} />
      )}
      {tenderType === TenderTypes.OFFER && (
        <>
          <StyledGrid>
            <SpecializationSelect onChange={onChangeSpecialization} />
            <KatoSelectTrigger
              label="регион"
              value={!!values.kato ? values.kato.value.nameRu : ''}
              placeholder="Выберите регион"
              onChange={onChange}
              name="kato"
            />
            <Select<UserStatus>
              options={[UserStatus.FREE, UserStatus.BUSY].map((item) => ({
                label: UserStatusNames[item],
                value: item,
              }))}
              defaultLabel="Все"
              label="статус"
              value={values.status}
              placeholder="Выберите статус"
              onChange={onChange}
              name="status"
            />
          </StyledGrid>
          <StyledVehiclesFilterFooter>
            <Button text="Сбросить все" onClick={onClickClear} variant="text" />
            <Button
              icon={IconSearch}
              onClick={onClickSearch}
              text="Найти"
              loading={pending}
            />
          </StyledVehiclesFilterFooter>
        </>
      )}
    </StyledVehiclesFilter>
  );
}

export default memo(UsersFilter);
